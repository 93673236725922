.meeb__has-toolbar {
    margin-top: 50px;
}

.meeb__admin-toolbar {
    position: fixed;
    height: 50px;
    top: 0;
    background: #2f4050;
    width: 100%;
    font-family: "open sans",Helvetica Neue,Helvetica,Arial,sans-serif;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.75);
    z-index: 10000;
}

.meeb__admin-toolbar__container {
    margin: 0 20px;
    width: 100%;
    height: 100%;
    position: relative;
}

.meeb__admin-toolbar a {
    color: #a7b1c2;
}

.meeb__admin-toolbar__logo {
    float: left;
    margin-top: 5px;
    margin-right: 10px;
}

.meeb__admin-toolbar__nav {
    float: left;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.meeb__admin-toolbar__nav > .meeb__admin-toolbar__nav__item {
    position: relative;
    display: flex;
}

.meeb__admin-toolbar__nav__item > a {
    padding: 14px 10px;
    align-items: center;
    font-weight: bold;
}

.meeb__admin-toolbar__nav__item > a:hover {
    background: #293846;
    color: #fff;
}

@media (min-width: 768px) {
    .meeb__admin-toolbar__nav > .meeb__admin-toolbar__nav__item {
        float: left;
    }
}

.meeb__admin-toolbar__right {
    float: right;
}

.meeb__admin-toolbar__nav--right {
    margin-right: 40px;
}