/*=== MIXINS ===*/
/*=== VARIABLES ===*/
/*=== MEDIA QUERY ===*/
a,
.sectionTitleDouble h2 span,
.paginationCenter .pagination li a:hover,
.paginationCenter .pagination li.active a,
.paginationCenter .pagination li.active a:hover,
.paginationCenter .pagination li.active a:focus,
.accordionsTransparent .panel-heading.active,
.thumbnail.deals .caption .captionTitle:hover,
.thumbnail.deals .caption .detailsInfo h5,
.thumbnail.blogPostItem .caption .detailsInfo h4,
.thumbnail.citiesContent .caption .media .media-body a:hover,
.thumbnail.citiesContent .caption .media .media-body h4,
.blogTitle:hover,
.blogInfo li a:hover,
.footerContent .list-unstyled li a:hover,
.copyRight .list-inline li a:hover,
.copyRightText p a:hover,
.destinations .media-body .list-unstyled li i,
.destinations .media-body .list-unstyled li a:hover,
.destinations .media-body .list-unstyled li a:hover i,
.isotopeSelector .overlay .fancybox-pop .overlayInfo h5 span,
.isotopeSelector:hover h4,
.navbar-nav > li.open a,
.navbar-nav > li.open a:hover,
.navbar-nav > li.open a:focus,
.navbar-nav > li > a:hover,
.navbar-nav > li.active a,
.navbar-nav > li.active a:hover,
.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover,
.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a:hover,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.footerContent .list-unstyled li a:hover,
.copyRight .list-inline li a:hover,
.copyRightText p a:hover,
.promotionWrapper .promotionTable .promotionTableInner .promotionInfo .rating li,
.media-heading a:hover,
.bookingDetails,
.bookingDetails .buttonTransparent:hover,
.bookingDetails .paginationCenter .pagination li:first-child a:hover,
.paginationCenter .pagination li:first-child .bookingDetails a:hover,
.bookingDetails .paginationCenter .pagination li:last-child a:hover,
.paginationCenter .pagination li:last-child .bookingDetails a:hover,
.bookingDetails .isotopeFilters ul.filter > li a:hover,
.isotopeFilters ul.filter > li .bookingDetails a:hover,
.singlePackage .packageInfo .dl-horizontal dt,
.singlePackage .packageInfo .dl-horizontal dd span,
.singlePackage .packageInfo p span,
.blogSidebar .panel .panel-body .list-group .list-group-item:hover,
.blogSidebar .panel .panel-body .list-group .list-group-item:hover .badge,
.tabArea .tab-content .media .media-body .media-heading:hover,
.blogSinglePost .well .list-inline li a:hover,
.commentsArea .media .media-body .btn-link,
.commentsArea .media .media-body .btn-link:hover,
.aboutComments .commentsTable .commentsTableInner .commentsInfo h5,
.bookingForm .form .checkbox label a,
.bookingForm .commentsForm .checkbox label a,
.bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .checkbox label a,
.modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .checkbox label a,
.infoArea h2 span,
.priceTotal h2 span,
.priceTable .panel .priceCircle h3,
.priceTable .panel:hover .bodyImageBg .priceCircle h3,
.countryTabs .nav-tabs > li.pull-right .list-inline li a:hover,
.foodDrinkSection .foodDrinkContent .foodDrinkHeader h2 span,
.sectionTitleHomeCity2 h2 span,
.planToureCitySection .planToureContent .icon i,
.travelGuideSection .travelGuideImg span,
.travelGuideSection .sectionTitleHomeCity h4 span,
.travelTipsSection .travelTipsContent .meta-content a i,
.planToureCitySection .planToureContent .content a:hover,
.searchDestinationSection .headingTitle h2,
.searchDestinationSection .searchDestination .nav-tabs li.active a,
.ourPackagesSection .ourPackage .ourPackageContent p span,
.travelTipsThumbanail .thumbnail .caption .readMoreBtn:hover,
.footer-light .footerContent .list-item li a:hover,
.singleCartSidebar .totalCost .totalCostRight,
.information-aria .singleContent .media .media-right .days,
.rooms-types-aria .roomCategory .roomRate span,
.welcomeSection h2 span,
.recentActivitySection .listingsTable .rowItem h4,
.recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a i,
.recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a:hover,
.recentOfferSection .recentOfferAria .offerContent .singleContent .media .media-body p span,
.bookingTypeSection .bookingType .bookingList li a.active,
.profileSection .profileContent .editIconRight i,
.profileSection .profileContent .profileSocialIcon a i,
.settingSection .settingContent p,
.signupLoging .modal-footer .dontHaveAccount p a,
.rooms-types-aria .roomCategory .roomAdvantage li a:hover,
.thumbnail.deals .caption .captionTitle h4:hover,
.travelTipsSection .travelTipsContent .readMoreBtn:hover {
  color: #005fae;
}

.navbar-nav > li > a:hover,
.navbar-nav > li.active a,
.navbar-nav > li.active a:hover,
.navbar-nav > li.open > a,
.navbar-nav > li.open > a:hover,
.navbar-nav > li.open > a:focus,
.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover,
.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a:hover,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.navbar-fixed-top.lightHeader .navbar-nav > li > a:hover,
.searchHotel .sbHolder .sbOptions li a:hover,
.travelGuideSection .travelGuideContent .buttonCustomPrimary:hover,
.subscribSection .amazingSelectbox .searchHotelBtn .buttonCustomPrimary,
.searchFilter .sbOptions a:hover,
.sort-by-section .sort-select-box .sbHolder .sbOptions li a:hover {
  color: #005fae !important;
}

.searchTour .sbOptions a:hover,
.bookingDrop .sbOptions a:hover {
  color: #005fae !important;
}

.buttonCustomPrimary,
.accordionsTransparent .solidBgTitle .panel-heading,
.accordionsTransparent .accordionSolidTitle .panel-heading.active,
.accordionsTransparent .accordionSolidBar .panel-heading.active,
.datepicker-dropdown thead tr:last-child th,
.datepicker table tr td.day:hover,
.datepicker table tr td.focused,
.thumbnail.deals .discountInfo .discountOffer,
.priceRange .price-slider-inner .ui-widget-content .ui-slider-range,
.navbar-nav > li > a:after,
.navbar-nav > li.dropdown.searchBox a .searchIcon,
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .buttonTransparent:hover,
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:first-child a:hover,
.paginationCenter .pagination li:first-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover,
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:last-child a:hover,
.paginationCenter .pagination li:last-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover,
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .isotopeFilters ul.filter > li a:hover,
.isotopeFilters ul.filter > li .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover,
.navbar-nav > li > a:after,
.navbar-nav > li.dropdown.searchBox a .searchIcon,
.countryTabs .nav-tabs > li > a:after,
.blogSidebar .panel .panel-body .input-group .input-group-addon,
.contactInfo .media .media-left,
.teamMember .maskingArea .list-inline li a:hover,
.progress-wizard > .progress-wizard-step > .progress > .progress-bar,
.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot,
.comingContent .list-inline li a:hover,
.priceTable .panel:hover .priceCircle,
.tabsPart .nav-tabs li a:hover,
.bookingDetails,
.navbar .navbar-btn .btn-nav,
#rev_slider_wrapper1 #rev_slider_container .slide-layer-4,
#rev_slider_wrapper1 #rev_slider_container .slide-layer-4:hover,
.travelTipsSection .buttonCustomPrimary:hover,
.searchDestinationSection .searchDestination .nav-tabs li.active a::after,
.searchDestinationSection .searchDestination .tab-content .tab-pane .searchbtn .btn-default,
.sort-by-section .icon-right .icon.active a,
.hotelListSidebar .panel-body .check-box-list .checkbox-custom:checked + .checkbox-custom-label:before,
.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a .notifyNumber,
.recentActivitySection .listingsTable .rowItem .dateTd .date,
.settingSection .settingContent .buttonTransparent,
.signupLoging .modal-body .btn-default,
.searchDestinationSection .searchDestination .tab-content .tab-pane .searchbtn .btn-default {
  background-color: #005fae;
}

.buttonTransparent:hover,
.paginationCenter .pagination li:first-child a:hover,
.paginationCenter .pagination li:last-child a:hover,
.isotopeFilters ul.filter > li a:hover,
.commentsForm .buttonCustomPrimary:hover,
.buttonArea.galleryBtnArea .buttonTransparent:hover,
.buttonArea.galleryBtnArea .paginationCenter .pagination li:first-child a:hover,
.paginationCenter .pagination li:first-child .buttonArea.galleryBtnArea a:hover,
.buttonArea.galleryBtnArea .paginationCenter .pagination li:last-child a:hover,
.paginationCenter .pagination li:last-child .buttonArea.galleryBtnArea a:hover,
.buttonArea.galleryBtnArea .isotopeFilters ul.filter > li a:hover,
.isotopeFilters ul.filter > li .buttonArea.galleryBtnArea a:hover,
.priceRange .price-slider-inner .ui-widget-content .ui-slider-handle,
.navbar-nav > li.dropdown.searchBox .dropdown-menu li .input-group-addon,
.footerContent .input-group .input-group-addon:hover,
.footerContent .list-inline li a:hover,
.isotopeFilters ul.filter > li.active a,
.navbar-nav > li.dropdown.searchBox .dropdown-menu li .input-group-addon,
.footerContent .input-group .input-group-addon:hover,
.footerContent .list-inline li a:hover,
.notFoundContent .input-group .input-group-addon,
.comingContent .input-group .input-group-addon,
.tabsPart .nav-tabs li.active a,
#rev_slider_wrapper2 #rev_slider_container .slide-layer-3:hover,
#rev_slider_wrapper2 #rev_slider_container .slide-layer-4:hover,
.packageSAleSection .buttonSale .buttonTransparent:hover,
.profileSection .profileContent .profileSocialIcon a:hover i,
.settingSection .settingContent .changeSetting .form-check .checkbox-custom:checked + .checkbox-custom-label:before {
  background-color: #005fae;
  border-color: #005fae;
}

.modal.bookingModal .modal-dialog .modal-content,
.packagesFilter,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.countryTabs .nav-tabs > li.active > a,
.countryTabs .nav-tabs > li.active > a:focus,
.countryTabs .nav-tabs > li.active > a:hover,
.progress-wizard > .progress-wizard-step > .progress-wizard-dot,
.bookingForm .form .checkbox input[type=checkbox],
.bookingForm .commentsForm .checkbox input[type=checkbox],
.bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .checkbox input[type=checkbox],
.modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .checkbox input[type=checkbox],
.travelGuideSection .travelGuideContent .buttonCustomPrimary:hover,
.hotelListSidebar .panel-heading-title,
.signupLoging .modal-header {
  border-color: #005fae;
}

.navbar-fixed-top.lightHeader .navbar-nav > li > a:hover,
.teamMember .maskingArea .list-inline li a:hover {
  border-color: #005fae;
}

.countUpSection .icon {
  background-color: rgba(0, 95, 174, 0.9);
}

.recentOfferSection .needhelpAria .needHelpContent .btn-default {
  background-color: #005fae !important;
}
