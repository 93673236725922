
/* Global */

.portfolio-section {
    padding-bottom:40px;
}


/* category filter */

.filter-section {
	padding-top:40px;
}


.filter-container {
    text-align:center;
    padding:20px;
}


ul.filter > li > a {
    color:#1abc9c;
    font-size:20px;
    line-height:40px;
    padding: 10px;
}

ul.filter > li > a:hover,ul.filter > li > a:focus {
    text-decoration:none;
    color: #929497;
    outline:none;
    background-color: transparent;
}

ul.filter > li.active a {
    color:#111;
    background-color: #0df;
}

ul.filter > li span {
    color:#ddd;
    font-size:20px;
    display:inline-block;
    margin:0 5px;
}

.filter-section .filter-container .filter > li {
    padding:0;
    margin:0;
}

.filter-section h1 {
    text-align:center;
}


/* the color of the transparent overlay border */

.portfolio-section.port-col figure .overlay-background {
    background-color:#000;
    background-color:rgba(0,0,0,0.4);
}


/* portfolio images */

.portfolio-section figure {
    float:left;
    width:100%;
    position:relative;
}

.portfolio-section figure img {
    width:100%;
    height:auto;
}


/* project title under image */

.portfolio-section .article-title {
    text-align:center;
    float:left;
    width:100%;
    font-size:18px;
    line-height:24px;
    padding:10px 0 30px 0;
}

.portfolio-section .article-title a {
    color:#aaa;
}


/* transparent overlay */

.portfolio-section figure:hover .overlay {
    opacity:1;
    visibility:visible;
}

.portfolio-section figure:hover .overlay-background {
    opacity:.8;
    visibility:visible;
}

.portfolio-section figure .overlay-background {
    z-index:1;
    -webkit-transition:all .2s ease-in-out;
    -moz-transition:all .2s ease-in-out;
    -o-transition:all .2s ease-in-out;
    transition:all .2s ease-in-out;
    position:absolute;
    top:0;
    left:0;
    opacity:0;
    visibility:hidden;
    width:100%;
    height:100%;
}

.portfolio-section figure .overlay-background .inner {
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    margin:11px;
    background-color:#c8e9c8;
    background-color:rgba(194,231,194,0.9);
}

.portfolio-section figure .overlay {
    z-index:2;
    transition:all .2s ease-in-out;
    position:absolute;
    top:0;
    left:0;
    opacity:0;
    visibility:hidden;
    width:100%;
    height:100%;
}

.portfolio-section figure .inner-overlay {
    display:table;
    width:100%;
    height:100%;
}

.portfolio-section figure .inner-overlay-content {
    display:table-cell;
    vertical-align:middle;
    width:100%;
    padding:20px 40px;
}

.portfolio-section figure .inner-overlay-content.with-icons {
    text-align:center;
}


/* icons inside overlay */

.portfolio-section figure .inner-overlay-content.with-icons a {
    width:40px;
    height:40px;
    line-height:42px;
    display:inline-block;
    margin:0 10px;
    text-decoration:none;
    font-size:20px;
	background:rgba(0,0,0,0.9);
    color:#fff;
    border-radius:0;
}


/* skew image on hover */ 

.isotopeSelector img {
    -webkit-transition:all 0.8s ease-in-out;
    -moz-transition:all 0.8s ease-in-out;
    -ms-transition:all 0.8s ease-in-out;
    -o-transition:all 0.8s ease-in-out;
    transition:all 0.8s ease-in-out;
}

.isotopeSelector:hover img {
    -moz-transform:scale(1.5) rotate(2deg);
    -webkit-transform:scale(1.5) rotate(2deg);
    -o-transform:scale(1.5) rotate(2deg);
    -ms-transform:scale(1.5) rotate(2deg);
    transform:scale(1.5) rotate(2deg);
    -webkit-transition:all 0.8s ease-in-out;
    -moz-transition:all 0.8s ease-in-out;
    -ms-transition:all 0.8s ease-in-out;
    -o-transition:all 0.8s ease-in-out;
    transition:all 0.8s ease-in-out;
}

.isotopeSelector figure {
    overflow:hidden;
}

.isotopeSelector figure {
    box-shadow:0 0 2px rgba(0,0,0,0.2);
}








/*
|--------------------------------------------------------------------------
|  Demo Specific Code
|--------------------------------------------------------------------------
|
|
|
*/



.demo-colored-wrap {
	background:#ecf0f1;
}