/* ====== index =====
1. COMMON
2. UI KIT
	2.1 BUTTON
	2.2 SECTION TITLE
	2.3 FORM
	2.4 PASINATION
	2.5 ACCORDION
	2.6 TABS
	2.7 DATEPICKER
	2.8 THUMBNAIL
	2.9 MODAL
	2.10 PANEL
	2.11 PRICE RANGE SLIDER
3. HEADER
	3.1 HEADER TRANSPARENT
	3.2 LIGHT HEADER
	3.3 DARK HEADER
4. FOOTER
5. SECTION
	5.1 MAIN CONTENT SECTION
	5.2 DARK SECTION
	5.3 WHITE SECTION
	5.4 SECTION TITLE DARK
	5.4 PAGE TITLE SECTION
	5.5 PACKAGES SECTION
6. HOME
	6.1 BANNER
	6.2 PROMOTION
	6.3 COUNT UP
7. PACKAGES
	7.1 PACKAGES LIST
	7.2 SINGLE PACKAGE FULL WIDTH
8. BLOG
	8.1 BLOG POST LIST ITEM
	8.2 BLOG POST SIDEBAR
	8.3 BLOG SINGLE POST
9. 404 NOT FOUND PAGE
	9.1 404 PAGE BACKGROUND
10 CONTACT US
	10.1 CONTACT INFO
	10.2 MAP AREA SECTION
11 ABOUT US
	11.1 ABOUT US TITLE
	11.2 ABOUT COMMENTS
12 BOOKING
	12.1 BOOKING PROGRESS BAR
	12.2 BOOKING FORM
	12.3 BOOKING DETAILS
	12.4 BOOKING CONFIRMED
13 COMING SOON
14 PRICING TABLE
15 TABS
	15.1 TABS PART
16 ADMIN
  16.1 DASHBOARD MENU
  16.2 WELCOME SECTION
  16.3 ALERT SECTION
  16.4 DASHBOARD COLOR
  16.5 DASHBOARD BLOCK SECTION
  16.6 DASHBOARD RECENT ACTIVITY SECTION
  16.7 DASHBOARD RECENT OFFER SECTION
  16.8 BOOKING SECTION
  16.9 PROFILE SECTION
  16.10 ABOUT YOU SECTION
  16.11 ACCOUNT SETTING SECTION
  16.12 SIGNUP & LOGIN PAGE

===================== */
/*=== MIXINS ===*/
/*=== VARIABLES ===*/
/*=== MEDIA QUERY ===*/
* {
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #5f5f5f;
  font-weight: 400;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

p {
  font-size: 14px;
  color: #5f5f5f;
  font-weight: 400;
  line-height: 21px;
}

h1, h2, h3, h4 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: #262a2e;
  margin-top: 0;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  margin-bottom: 25px;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.no-margin {
  margin-left: 0px;
  margin-right: 0px;
}

.mb-30 {
  margin-bottom: 30px;
}

/*=== 1.COMMON ===*/
.container {
  width: 100%;
}

@media (min-width: 320px) {
  .container {
    width: 320px;
  }
}

@media (min-width: 480px) {
  .container {
    width: 450px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

body.bodyColor.wrapper {
  margin: 0 auto;
}

@media (min-width: 320px) {
  body.bodyColor.wrapper {
    max-width: 320px;
  }
}

@media (min-width: 480px) {
  body.bodyColor.wrapper {
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  body.bodyColor.wrapper {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  body.bodyColor.wrapper {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  body.bodyColor.wrapper {
    max-width: 1170px;
  }
}

@media (min-width: 320px) {
  body.bodyColor .header {
    max-width: 320px;
  }
}

@media (min-width: 480px) {
  body.bodyColor .header {
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  body.bodyColor .header {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  body.bodyColor .header {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  body.bodyColor .header {
    max-width: 1170px;
  }
}

body.bodyColor .navbar-fixed-top {
  right: auto;
  left: auto;
}

.btn {
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  padding: 9px 0;
}

@media (min-width: 480px) {
  .btn {
    font-size: 15px;
    padding: 13px 0;
  }
}

@media (min-width: 768px) {
  .btn {
    font-size: 12px;
    padding: 8px 0;
  }
}

@media (min-width: 992px) {
  .btn {
    font-size: 14px;
    padding: 12px 0;
  }
}

@media (min-width: 1200px) {
  .btn {
    font-size: 15px;
    padding: 13px 0;
  }
}

a {
  color: #005fae;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0.8);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  outline: 0;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

.main-wrapper {
  position: relative;
}

.form-control:focus {
  box-shadow: none;
}

/*=== 2. UI KIT ===*/
/*=== 2.1 BUTTON ===*/
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.buttonCustomPrimary {
  height: 45px;
  line-height: 45px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #005fae;
  text-transform: uppercase;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 15px;
  padding: 0;
}

.buttonTransparent, .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:last-child a, .isotopeFilters ul.filter > li a {
  height: 35px;
  line-height: 35px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-color: #a7a7a7;
  padding: 0 20px;
  color: #5f5f5f;
  text-transform: uppercase;
  outline: 0;
}

.buttonTransparent:hover, .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:last-child a:hover, .isotopeFilters ul.filter > li a:hover {
  background-color: #005fae;
  border-color: #005fae;
  color: #ffffff;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  outline: 0;
}

.buttonTransparentfocus {
  outline: 0;
}

/*=== 2.2 SECTION TITLE ===*/
.sectionTitle {
  text-align: center;
}

@media (max-width: 767px) {
  .sectionTitle {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .sectionTitle {
    margin-bottom: 48px;
  }
}

.sectionTitle h2 {
  position: relative;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .sectionTitle h2 {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .sectionTitle h2 {
    font-size: 26px;
  }
}

.sectionTitle h2 span {
  padding-left: 38px;
  padding-right: 38px;
  z-index: 5;
  position: relative;
  background-color: #ffffff;
}

.sectionTitle h2 span.lightBg {
  background-color: #bebebe;
}

.sectionTitle h2:before {
  background-color: #e5e5e5;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  z-index: 1;
}

.sectionTitle p {
  color: #5f5f5f;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .sectionTitle p {
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .sectionTitle p {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .sectionTitleDouble {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .sectionTitleDouble {
    background-image: url("../img/home/devider.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 8px 45px;
  }
}

.sectionTitleDouble p, .sectionTitleDouble h2 {
  color: #ffffff;
  text-transform: uppercase;
  line-height: 26px;
  margin-bottom: 0;
  font-weight: 400;
}

@media (min-width: 992px) {
  .sectionTitleDouble p, .sectionTitleDouble h2 {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .sectionTitleDouble p, .sectionTitleDouble h2 {
    font-size: 20px;
  }
}

.sectionTitleDouble h2 {
  font-weight: 700;
}

@media (min-width: 992px) {
  .sectionTitleDouble h2 {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .sectionTitleDouble h2 {
    font-size: 26px;
  }
}

.sectionTitleDouble h2 span {
  color: #005fae;
}

.sectionTitle2 h2 {
  font-size: 26px;
  color: #222222;
  line-height: 26px;
  margin: 25px 0;
  font-weight: 400;
}

.sectionTitle2 p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 30px;
}

.sub-title h4 {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #222222;
}

/*=== 2.3 FORM ===*/
.form .form-group .form-control, .commentsForm .form-group .form-control, .modal.bookingModal .modal-dialog .modal-content .modal-body .form-group .form-control {
  height: 45px;
  background-color: #bebebe;
  box-shadow: none;
  border-color: #aeaeae;
  color: #5f5f5f;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
}

.form .form-group .form-control:focus, .commentsForm .form-group .form-control:focus, .modal.bookingModal .modal-dialog .modal-content .modal-body .form-group .form-control:focus {
  outline: 0;
}

.form .form-group textarea.form-control, .commentsForm .form-group textarea.form-control, .modal.bookingModal .modal-dialog .modal-content .modal-body .form-group textarea.form-control {
  height: 150px;
}

.form .buttonCustomPrimary, .commentsForm .buttonCustomPrimary, .modal.bookingModal .modal-dialog .modal-content .modal-body .buttonCustomPrimary {
  padding: 0 20px;
}

.commentsForm .form-control {
  border: none;
}

.commentsForm .buttonCustomPrimary {
  background-color: transparent;
  color: #2e2e2e !important;
  border-color: #a7a7a7;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.commentsForm .buttonCustomPrimary:hover {
  background-color: #005fae;
  border-color: #005fae;
  color: #ffffff !important;
}

.commentsForm h3 {
  font-size: 20px;
  color: #222222;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 30px;
}

.contactForm .form-control {
  border: none;
}

.contactForm .buttonCustomPrimary {
  float: right;
}

/*=== 2.4 PASINATION ===*/
.paginationCenter {
  width: 100%;
  text-align: center;
  padding-top: 78px;
}

.paginationCenter .pagination {
  margin: 0;
}

.paginationCenter .pagination li {
  display: inline-block;
}

.paginationCenter .pagination li a {
  color: black;
  border: none;
  height: 35px;
  line-height: 35px;
  padding: 0 12px;
  background-color: #bebebe;
}

.paginationCenter .pagination li a:hover {
  color: #005fae;
  background-color: transparent;
}

.paginationCenter .pagination li.active a {
  color: #005fae;
  background-color: transparent;
}

.paginationCenter .pagination li.active a:hover, .paginationCenter .pagination li.active a:focus {
  color: #005fae;
  background-color: transparent;
}

.paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:last-child a {
  color: black;
  border: 1px solid #a7a7a7;
}

.paginationCenter .pagination li:first-child a span i {
  margin-right: 5px;
}

.paginationCenter .pagination li:last-child a span i {
  margin-left: 5px;
}

.paginationCenter.paginationTransparent {
  padding-top: 0;
}

.paginationCenter.paginationTransparent .pagination li a {
  background-color: #ffffff;
}

/*=== 2.5 ACCORDION ===*/
.accordionWrappar {
  padding: 15px;
  border: 1px solid #b7b7b7;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .accordionWrappar {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .accordionWrappar {
    margin-bottom: 0;
  }
}

.accordionsTransparent .panel-group {
  margin-bottom: 15px;
}

.accordionsTransparent .panel-group .panel {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.accordionsTransparent .panel-group .panel-heading {
  padding: 15px;
  text-transform: uppercase;
  font-size: 15px;
  color: #5f5f5f;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #b7b7b7;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.accordionsTransparent .panel-group .panel-heading i {
  line-height: 22px;
}

.accordionsTransparent .panel-group .panel-heading.hideBorder {
  border: none;
}

.accordionsTransparent .panel-group .panel-heading:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.accordionsTransparent .panel-group .panel-body .media img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.accordionsTransparent .panel-default > a.panel-heading {
  display: block;
}

.accordionsTransparent .panel-default > a.panel-heading:focus {
  text-decoration: none;
}

.accordionsTransparent .panel-group .panel + .panel {
  margin-top: 0;
}

.accordionsTransparent .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none !important;
}

.accordionsTransparent .panel {
  box-shadow: none;
}

.accordionsTransparent .panel-heading.active {
  color: #005fae;
  border: none;
}

.accordionsTransparent .panel-collapse.active {
  border-bottom: 1px solid #b7b7b7;
}

.accordionsTransparent .solidBgTitle .panel {
  margin-bottom: 20px;
}

.accordionsTransparent .solidBgTitle .panel-heading {
  background-color: #005fae;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.accordionsTransparent .solidBgTitle .panel-body {
  background-color: #bebebe;
  border-radius: 0 0 4px 4px;
}

.accordionsTransparent .solidBgTitle .panel-collapse {
  border-radius: 0 0 4px 4px;
}

.accordionsTransparent .accordionSolidTitle .panel, .accordionsTransparent .accordionSolidBar .panel {
  margin-bottom: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: transparent;
}

.accordionsTransparent .accordionSolidTitle .panel-heading, .accordionsTransparent .accordionSolidBar .panel-heading {
  background-color: #ffffff;
  color: #222222;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.accordionsTransparent .accordionSolidTitle .panel-heading.active, .accordionsTransparent .accordionSolidBar .panel-heading.active {
  color: #ffffff;
  background-color: #005fae;
}

.accordionsTransparent .accordionSolidTitle .panel-collapse.active, .accordionsTransparent .accordionSolidBar .panel-collapse.active {
  background-color: transparent;
  border: none;
}

/*=== 2.6 TABS ===*/
/*=== 2.7 DATEPICKER ===*/
.ed-datepicker {
  height: 45px;
  margin-top: 4px;
  border: none;
  border-bottom: 1px solid rgba(167, 167, 167, 0.3);
  padding: 0;
  border-radius: 0;
}

.ed-datepicker.filterDate {
  border-color: #a7a7a7;
  margin-bottom: 20px;
}

.ed-datepicker .form-control {
  height: 45px;
  background: transparent;
  border: none;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
  padding: 4px 12px 4px 0;
  box-shadow: none !important;
}

.ed-datepicker.filterDate .form-control {
  color: #5f5f5f;
}

.ed-datepicker .form-control::-webkit-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
}

.ed-datepicker .form-control::-moz-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
}

.ed-datepicker .form-control:-ms-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
}

.ed-datepicker .form-control:-moz-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
}

.ed-datepicker.filterDate .form-control::-webkit-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #5f5f5f;
}

.ed-datepicker.filterDate .form-control::-moz-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #5f5f5f;
}

.ed-datepicker.filterDate .form-control:-ms-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #5f5f5f;
}

.ed-datepicker.filterDate .form-control:-moz-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #5f5f5f;
}

.ed-datepicker .input-group-addon {
  height: 45px;
  background: transparent;
  border: none;
  padding-right: 0;
}

.datepicker-dropdown {
  width: auto;
  padding: 0;
}

.filterDate .datepicker-dropdown {
  top: 87% !important;
}

.datepicker-dropdown thead {
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}

.datepicker-dropdown thead tr:last-child th {
  background-color: #005fae;
  border-radius: 0;
  font-size: 14px;
  color: #ffffff;
}

.datepicker table tr td.day {
  color: #5f5f5f;
  font-weight: 400;
  border-radius: 0 !important;
  background: transparent;
  margin: 0 3px 10px;
  padding: 5px;
  width: 43px;
  height: 43px;
  text-align: center;
  border: 1px solid #aeaeae;
}

.datepicker table tr td.day.new,
.datepicker table tr td.day.old {
  color: #cccfd3;
  background: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #005fae;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -ms-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  cursor: pointer;
  color: #ffffff;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active {
  border-color: #262a2e;
  background: #262a2e;
  -webkit-transition: background 0.3s, border-color 0.3s;
  -moz-transition: background 0.3s, border-color 0.3s;
  -ms-transition: background 0.3s, border-color 0.3s;
  -o-transition: background 0.3s, border-color 0.3s;
  transition: background 0.3s, border-color 0.3s;
}

.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active.focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active:active:hover {
  border-color: #262a2e;
  background: #262a2e;
  -webkit-transition: background 0.3s, border-color 0.3s;
  -moz-transition: background 0.3s, border-color 0.3s;
  -ms-transition: background 0.3s, border-color 0.3s;
  -o-transition: background 0.3s, border-color 0.3s;
  transition: background 0.3s, border-color 0.3s;
}

.datepicker .datepicker-switch {
  color: black;
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: transparent;
  border: none;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
  color: #262a2e;
}

.datepicker-dropdown::after,
.datepicker-dropdown::before {
  display: none;
}

.datepicker table tr td, .datepicker table tr th {
  height: 50px;
}

/*=== 2.8 THUMBNAIL ===*/
.thumbnail {
  border: none;
  padding: 0;
}

.thumbnail.deals {
  overflow: hidden;
  position: relative;
  float: left;
  box-shadow: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.thumbnail.deals img {
  width: 100%;
}

.thumbnail.deals .discountInfo {
  position: absolute;
  left: 0;
  top: 0;
  height: 263px;
  width: 100%;
}

@media (max-width: 767px) {
  .thumbnail.deals .discountInfo {
    padding: 5px 0 0 5px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals .discountInfo {
    padding: 5px 0 0 5px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountInfo {
    padding: 20px 0 0 20px;
  }
}

.thumbnail.deals .discountInfo .discountOffer {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #005fae;
}

@media (max-width: 767px) {
  .thumbnail.deals .discountInfo .discountOffer {
    margin-bottom: 40%;
    width: 60px;
    padding: 15px 0;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals .discountInfo .discountOffer {
    margin-bottom: 30px;
    width: 60px;
    padding: 15px 0;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountInfo .discountOffer {
    margin-bottom: 60px;
    width: 76px;
    padding: 19px 0;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals .discountInfo .discountOffer {
    margin-bottom: 104px;
    width: 76px;
    padding: 19px 0;
  }
}

.thumbnail.deals .discountInfo .discountOffer h4 {
  color: #ffffff;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .thumbnail.deals .discountInfo .discountOffer h4 {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals .discountInfo .discountOffer h4 {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountInfo .discountOffer h4 {
    font-size: 18px;
  }
}

.thumbnail.deals .discountInfo .discountOffer h4 span {
  display: block;
}

.thumbnail.deals .discountInfo .duration {
  margin-left: 0;
  position: absolute;
  left: 20px;
  bottom: 65px;
}

@media (min-width: 480px) {
  .thumbnail.deals .discountInfo .duration {
    bottom: -30px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals .discountInfo .duration {
    bottom: 110px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountInfo .duration {
    bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals .discountInfo .duration {
    bottom: 20px;
  }
}

.thumbnail.deals .discountInfo .duration li {
  color: #ffffff;
  font-weight: 700;
  border-right: 1px solid #ffffff;
  line-height: 21px;
  float: left;
}

@media (min-width: 768px) {
  .thumbnail.deals .discountInfo .duration li {
    font-size: 11px;
    padding: 0 10px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountInfo .duration li {
    font-size: 15px;
    padding: 0 12px;
  }
}

.thumbnail.deals .discountInfo .duration li:first-child {
  padding-left: 0;
}

.thumbnail.deals .discountInfo .duration li:last-child {
  border-right: 0;
}

.thumbnail.deals .discountInfo .dates {
  position: absolute;
  top: 180px;
  left: 20px;
}

@media (min-width: 480px) {
  .thumbnail.deals .discountInfo .dates {
    top: 260px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals .discountInfo .dates {
    top: 150px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountInfo .dates {
    top: 200px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals .discountInfo .dates {
    top: 155px;
  }
}

.thumbnail.deals .discountInfo .dates li {
  color: #fff;
  text-transform: capitalize;
}

.thumbnail.deals .discountCol-3 .rating {
  bottom: 80px;
}

@media (min-width: 480px) {
  .thumbnail.deals .discountCol-3 .rating {
    bottom: 0px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals .discountCol-3 .rating {
    bottom: 40px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountCol-3 .rating {
    bottom: -20px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals .discountCol-3 .rating {
    bottom: 40px;
  }
}

.thumbnail.deals .discountCol-3 .duration {
  bottom: 60px;
}

@media (min-width: 480px) {
  .thumbnail.deals .discountCol-3 .duration {
    bottom: -20px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals .discountCol-3 .duration {
    bottom: 20px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .discountCol-3 .duration {
    bottom: -40px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals .discountCol-3 .duration {
    bottom: 20px;
  }
}

.thumbnail.deals .caption {
  padding: 30px 20px;
}

.thumbnail.deals .caption h4 {
  margin-bottom: 10px;
}

.thumbnail.deals .caption .captionTitle {
  font-size: 18px;
  min-height: 38px;
  color: #171612;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 0 0 !important;
  display: block;
  font-family: Montserrat, sans-serif;
}

.thumbnail.deals .caption .captionTitle:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  color: #005fae;
}

.thumbnail.deals .caption .captionTitle:hover:focus {
  text-decoration: none !important;
}

.thumbnail.deals .caption .captionTitle h4 {
  font-size: 18px;
}

.thumbnail.deals .caption .captionTitle h4:hover {
  color: #005fae;
}

.thumbnail.deals .caption p {
  margin-bottom: 20px;
  min-height: 63px;
  font-weight: 300;
  font-size: 16px;
  color: #8b8b8b;
  font-family: 'Montserrat', sans-serif;
  line-height: 28px;
}

.thumbnail.deals .caption .detailsInfo {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.thumbnail.deals .caption .detailsInfo h5 {
  float: left;
  line-height: 21px;
  margin: 0;
  color: #005fae;
  font-weight: 700;
  font-size: 30px;
}

.thumbnail.deals .caption .detailsInfo h5 span {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  color: #8b8b8b;
  text-transform: capitalize;
  display: block;
}

@media (min-width: 768px) {
  .thumbnail.deals .caption .detailsInfo h5 span {
    display: block;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .caption .detailsInfo h5 span {
    display: block;
  }
}

@media (min-width: 320px) {
  .thumbnail.deals .caption .detailsInfo h5 {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals .caption .detailsInfo h5 {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    text-align: left;
  }
}

.thumbnail.deals.packagesPage .discountCol-3 .rating {
  bottom: 80px;
}

@media (min-width: 480px) {
  .thumbnail.deals.packagesPage .discountCol-3 .rating {
    bottom: 0px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals.packagesPage .discountCol-3 .rating {
    bottom: 40px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals.packagesPage .discountCol-3 .rating {
    bottom: -30px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals.packagesPage .discountCol-3 .rating {
    bottom: 45px;
  }
}

.thumbnail.deals.packagesPage .discountCol-3 .duration {
  bottom: 60px;
}

@media (min-width: 480px) {
  .thumbnail.deals.packagesPage .discountCol-3 .duration {
    bottom: -20px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals.packagesPage .discountCol-3 .duration {
    bottom: 20px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals.packagesPage .discountCol-3 .duration {
    bottom: -60px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals.packagesPage .discountCol-3 .duration {
    bottom: 25px;
  }
}

.thumbnail.deals.packagesPage .discountCol-3 .dates {
  bottom: 60px;
}

@media (min-width: 480px) {
  .thumbnail.deals.packagesPage .discountCol-3 .dates {
    bottom: -20px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals.packagesPage .discountCol-3 .dates {
    bottom: 20px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals.packagesPage .discountCol-3 .dates {
    bottom: -40px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals.packagesPage .discountCol-3 .dates {
    bottom: 20px;
  }
}

@media (max-width: 767px) {
  .thumbnail.deals.packagesPage .discountOffer {
    margin-bottom: 40%;
    width: 60px;
    padding: 15px 0;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals.packagesPage .discountOffer {
    margin-bottom: 30px;
    width: 60px;
    padding: 15px 0;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals.packagesPage .discountOffer {
    margin-bottom: 45px;
    width: 70px;
    padding: 16px 0;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals.packagesPage .rating {
    bottom: 110px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals.packagesPage .rating {
    bottom: 55px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals.packagesPage .rating {
    bottom: 105px;
  }
}

.thumbnail.deals.packagesPage .duration {
  margin-left: 0;
}

@media (min-width: 768px) {
  .thumbnail.deals.packagesPage .duration {
    bottom: 90px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals.packagesPage .duration {
    bottom: 35px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals.packagesPage .duration {
    bottom: 88px;
  }
}

.thumbnail.deals.packagesPage .duration li {
  color: #ffffff;
  font-weight: 700;
  border-right: 1px solid #ffffff;
  line-height: 21px;
  float: left;
}

@media (min-width: 768px) {
  .thumbnail.deals.packagesPage .duration li {
    font-size: 11px;
    padding: 0 10px;
  }
}

.thumbnail.deals.packagesPage .duration li:first-child {
  padding-left: 0;
}

.thumbnail.deals.packagesPage .duration li:last-child {
  border-right: 0;
}

.thumbnail.deals.packagesPage .caption .detailsInfo .detailsBtn .buttonTransparent, .thumbnail.deals.packagesPage .caption .detailsInfo .detailsBtn .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .thumbnail.deals.packagesPage .caption .detailsInfo .detailsBtn a, .thumbnail.deals.packagesPage .caption .detailsInfo .detailsBtn .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .thumbnail.deals.packagesPage .caption .detailsInfo .detailsBtn a, .thumbnail.deals.packagesPage .caption .detailsInfo .detailsBtn .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .thumbnail.deals.packagesPage .caption .detailsInfo .detailsBtn a {
  font-size: 13px;
}

.thumbnail.deals:hover {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.thumbnail.deals.hotelgrid .discountCol-3 .dates {
  position: absolute;
  top: 180px;
  left: 20px;
}

@media (min-width: 480px) {
  .thumbnail.deals.hotelgrid .discountCol-3 .dates {
    top: 265px;
  }
}

@media (min-width: 768px) {
  .thumbnail.deals.hotelgrid .discountCol-3 .dates {
    top: 220px;
  }
}

@media (min-width: 992px) {
  .thumbnail.deals.hotelgrid .discountCol-3 .dates {
    top: 285px;
  }
}

@media (min-width: 1200px) {
  .thumbnail.deals.hotelgrid .discountCol-3 .dates {
    top: 225px;
  }
}

.thumbnail.blogPostItem {
  position: relative;
  border: none;
  padding: 0;
  float: left;
  box-shadow: none;
}

.thumbnail.blogPostItem img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.thumbnail.blogPostItem .caption {
  padding: 30px 20px;
}

.thumbnail.blogPostItem .caption p {
  margin-bottom: 30px;
}

.thumbnail.blogPostItem .caption .detailsInfo {
  display: inline-block;
  width: 100%;
}

.thumbnail.blogPostItem .caption .detailsInfo h4 {
  display: inline-block;
  float: left;
}

.thumbnail.blogPostItem .caption .detailsInfo h4 {
  line-height: 21px;
  margin-bottom: 0;
  color: #005fae;
  font-weight: 700;
  font-size: 20px;
}

.thumbnail.blogPostItem .caption .detailsInfo h4 span {
  font-size: 14px;
  color: #5f5f5f;
  text-transform: capitalize;
  font-weight: 400;
}

@media (min-width: 768px) {
  .thumbnail.blogPostItem .caption .detailsInfo h4 span {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .thumbnail.blogPostItem .caption .detailsInfo h4 span {
    display: block;
  }
}

.thumbnail.citiesContent {
  margin-bottom: 80px;
}

.thumbnail.citiesContent .imageArea {
  margin-bottom: 18px;
  padding: 0 9px;
  position: relative;
}

.thumbnail.citiesContent .imageArea img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.thumbnail.citiesContent .imageArea .sticker {
  position: absolute;
  left: 19px;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.35);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  padding: 12px 20px;
  text-transform: uppercase;
}

.thumbnail.citiesContent .caption .media {
  background-color: #bebebe;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #b7b7b7;
}

@media (max-width: 767px) {
  .thumbnail.citiesContent .caption .media {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .thumbnail.citiesContent .caption .media {
    margin-bottom: 0;
  }
}

.thumbnail.citiesContent .caption .media .media-body {
  padding-left: 10px;
}

.thumbnail.citiesContent .caption .media .media-body a {
  color: #222222;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 15px 0;
  display: block;
  font-weight: 400;
}

.thumbnail.citiesContent .caption .media .media-body a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  color: #005fae;
}

.thumbnail.citiesContent .caption .media .media-body h4 {
  color: #005fae;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 400;
}

.thumbnail.citiesContent .media-left {
  float: left;
}

.pageLink {
  width: 100%;
  height: 263px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
}

.pageLink:before {
  background-color: black;
  content: '';
  width: 100%;
  height: 253px;
}

.marginExten {
  margin-left: -24px;
  margin-right: -24px;
}

.buttonArea {
  width: 100%;
  display: block;
  text-align: center;
}

.buttonArea .buttonTransparent, .buttonArea .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .buttonArea a, .buttonArea .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .buttonArea a, .buttonArea .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .buttonArea a {
  height: 45px;
  line-height: 45px;
}

@media (max-width: 767px) {
  .buttonArea .buttonTransparent, .buttonArea .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .buttonArea a, .buttonArea .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .buttonArea a, .buttonArea .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .buttonArea a {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .buttonArea .buttonTransparent, .buttonArea .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .buttonArea a, .buttonArea .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .buttonArea a, .buttonArea .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .buttonArea a {
    margin-bottom: 0;
  }
}

.buttonArea.galleryBtnArea {
  text-align: left;
  padding: 60px 0;
}

.buttonArea.galleryBtnArea .buttonTransparent, .buttonArea.galleryBtnArea .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .buttonArea.galleryBtnArea a, .buttonArea.galleryBtnArea .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .buttonArea.galleryBtnArea a, .buttonArea.galleryBtnArea .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .buttonArea.galleryBtnArea a {
  background-color: #bebebe;
  border-color: #bebebe;
  color: #222222;
  padding: 0 30px;
}

.buttonArea.galleryBtnArea .buttonTransparent:hover, .buttonArea.galleryBtnArea .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .buttonArea.galleryBtnArea a:hover, .buttonArea.galleryBtnArea .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .buttonArea.galleryBtnArea a:hover, .buttonArea.galleryBtnArea .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .buttonArea.galleryBtnArea a:hover {
  color: #ffffff;
  background-color: #005fae;
  border-color: #005fae;
}

.blogTitle {
  font-size: 20px;
  color: #222222;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 0 20px 0;
  display: block;
}

.blogTitle:hover {
  color: #005fae;
}

.blogInfo {
  margin-bottom: 25px;
}

.blogInfo li {
  font-size: 12px;
  line-height: 18px;
  color: #5f5f5f;
}

.blogInfo li i {
  margin-right: 6px;
}

.blogInfo li a {
  color: #5f5f5f;
}

.blogInfo li a:hover {
  color: #005fae;
}

.rating {
  margin-left: 0;
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.rating li {
  padding: 0;
}

.rating li i {
  color: #01238f;
}

.rating.homePage {
  margin-left: 0;
  display: block;
  width: 100%;
  position: absolute;
  left: 20px;
  bottom: 80px;
}

@media (min-width: 480px) {
  .rating.homePage {
    bottom: 0px;
  }
}

@media (min-width: 768px) {
  .rating.homePage {
    bottom: 130px;
  }
}

@media (min-width: 992px) {
  .rating.homePage {
    bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .rating.homePage {
    bottom: 50px;
  }
}

.rating.homePage li {
  padding: 0;
}

.rating.homePage li i {
  color: #01238f;
}

.detailsBtn {
  float: right;
  margin-top: 5px;
}

@media (min-width: 320px) {
  .detailsBtn {
    display: block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .detailsBtn {
    display: inline-block;
    width: auto;
  }
}

.detailsBtn li {
  padding: 0;
}

.detailsBtn li .buttonTransparent, .detailsBtn .paginationCenter .pagination li:first-child a, .paginationCenter .pagination .detailsBtn li:first-child a, .detailsBtn .paginationCenter .pagination li:last-child a, .paginationCenter .pagination .detailsBtn li:last-child a, .detailsBtn .isotopeFilters ul.filter > li a, .isotopeFilters .detailsBtn ul.filter > li a {
  color: #5f5f5f;
}

.detailsBtn li .buttonTransparent:hover, .detailsBtn .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination .detailsBtn li:first-child a:hover, .detailsBtn .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination .detailsBtn li:last-child a:hover, .detailsBtn .isotopeFilters ul.filter > li a:hover, .isotopeFilters .detailsBtn ul.filter > li a:hover {
  color: #ffffff;
}

.btnArea {
  padding-top: 30px;
  text-align: center;
}

/*=== 2.9 modal ===*/
@media (max-width: 767px) {
  .modal.bookingModal .modal-dialog {
    width: auto;
  }
}

@media (min-width: 768px) {
  .modal.bookingModal .modal-dialog {
    width: 402px;
  }
}

.modal.bookingModal .modal-dialog .modal-content {
  border: none;
  border-top: 5px solid #005fae;
}

.modal.bookingModal .modal-dialog .modal-content .modal-header {
  border: none;
}

.modal.bookingModal .modal-dialog .modal-content .modal-header .modal-title {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*=== 2.10 PANEL ===*/
.packagesFilter {
  border: none;
  margin-bottom: 30px;
  border-top: 5px solid #005fae;
}

.packagesFilter .panel-heading {
  background-color: #ffffff;
  border: none;
}

.packagesFilter .panel-heading .panel-title {
  text-transform: uppercase;
  color: black;
  font-size: 18px;
  line-height: 25px;
  border: none;
}

.packagesFilter .panel-body {
  padding-top: 0px;
}

.packagesFilter .panel-body .buttonTransparent, .packagesFilter .panel-body .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .packagesFilter .panel-body a, .packagesFilter .panel-body .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .packagesFilter .panel-body a, .packagesFilter .panel-body .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .packagesFilter .panel-body a {
  color: #5f5f5f;
}

.packagesFilter .panel-body .buttonTransparent:hover, .packagesFilter .panel-body .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .packagesFilter .panel-body a:hover, .packagesFilter .panel-body .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .packagesFilter .panel-body a:hover, .packagesFilter .panel-body .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .packagesFilter .panel-body a:hover {
  color: #ffffff;
}

/*=== 2.11 PRICE RANGE SLIDER ===*/
.priceRange .progress {
  margin: 20px 0 25px 0;
  height: 5px;
  background-color: #a7a7a7;
  position: relative;
  overflow: visible;
}

.priceRange .progress .progress-bar {
  background-color: #262a2e;
}

.priceRange .progress .bulet {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid #262a2e;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  left: 0px;
  top: -5px;
  position: absolute;
}

.priceRange .progress .bulet.next {
  left: 60px;
}

.priceRange .btn-default {
  display: none;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-transform: uppercase;
  background-color: #262a2e;
  border-radius: 0;
  border: none;
  color: #ffffff;
  font-weight: 700;
}

@media (min-width: 320px) {
  .priceRange .btn-default {
    width: 82px;
    font-size: 14px;
  }
}

@media (min-width: 480px) {
  .priceRange .btn-default {
    width: 82px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .priceRange .btn-default {
    width: 50px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .priceRange .btn-default {
    width: 45px;
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .priceRange .btn-default {
    width: 82px;
    font-size: 14px;
  }
}

.priceRange .priceLabel {
  float: right;
  line-height: 30px;
  color: #262a2e;
}

@media (min-width: 480px) {
  .priceRange .priceLabel {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .priceRange .priceLabel {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .priceRange .priceLabel {
    font-size: 14px;
  }
}

.priceRange {
  position: relative;
}

.priceRange .price-slider-inner {
  display: block;
  margin: 15px 0 25px 0;
}

.priceRange .price-slider-inner .amount-wrapper {
  display: none;
  position: absolute;
  bottom: 25px;
  right: 20px;
  font-size: 14px;
  width: 120px;
}

.priceRange .price-slider-inner .amount-wrapper input {
  background-color: transparent;
  border: 0;
  width: 28%;
  font-size: 14px;
  color: #262a2e;
  font-weight: 700;
  text-align: right;
}

.priceRange .price-slider-inner .ui-widget-content {
  background: none;
  border: 0;
  background-color: #a7a7a7;
  height: 4px;
  clear: both;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 0 5px 0 9px;
}

.priceRange .price-slider-inner .ui-widget-content .ui-slider-range {
  background-color: #005fae;
  border: none;
  border-radius: 0;
}

.priceRange .price-slider-inner .ui-widget-content .ui-slider-handle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: none;
  border: 3px solid #005fae;
  background-color: #005fae;
  top: -6px;
  width: 16px;
  height: 16px;
  outline: none;
}

.media-left {
  display: inline-block;
  float: left;
}

/*=== 2.12 SELECT BOX ===*/
/* SEARCH TOUR */
.searchTour .sbHolder, .searchTour .sbSelector {
  width: 100% !important;
  height: 45px !important;
}

.searchTour .sbHolder {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(222, 222, 222, 0.3);
  margin: 4px 0 0 0;
}

.searchTour .sbSelector:link,
.searchTour .sbSelector:visited,
.searchTour .sbSelector:hover {
  color: rgba(255, 255, 255, 0.3) !important;
  text-transform: uppercase;
}

.searchTour .sbSelector {
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  line-height: 42px;
  text-indent: 0px;
}

.searchTour .sbToggle {
  background: rgba(0, 0, 0, 0) url("../plugins/selectbox/images/arrow-down-1.png") no-repeat scroll 0 0;
  height: 5px;
  right: 4px;
  top: 22px;
  width: 9px;
}

.searchTour .sbOptions {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  right: 0 !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 100;
}

.searchTour .sbOptions li {
  padding: 4px;
  border-bottom: 1px solid #e5e5e5;
}

.searchTour .sbOptions li:last-child {
  border-bottom: none;
}

.searchTour .sbOptions a {
  color: #969696 !important;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: uppercase;
}

.searchTour .sbOptions a:hover {
  color: #ff891e !important;
  background-color: #f5f5f5;
}

/* SEARCH FILTER */
.searchFilter .sbHolder, .searchFilter .sbSelector {
  width: 100% !important;
  height: 45px !important;
}

.searchFilter .sbHolder {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #dedede;
  margin: 4px 0 20px 0;
}

.searchFilter .sbSelector:link,
.searchFilter .sbSelector:visited,
.searchFilter .sbSelector:hover {
  color: #969696 !important;
  text-transform: uppercase;
}

.searchFilter .sbSelector {
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  line-height: 42px;
  text-indent: 0px;
}

.searchFilter .sbToggle {
  background: rgba(0, 0, 0, 0) url("../plugins/selectbox/images/arrow-down-2.png") no-repeat scroll 0 0;
  height: 5px;
  right: 4px;
  top: 22px;
  width: 9px;
}

.searchFilter .sbOptions {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  right: 0 !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 100;
}

.searchFilter .sbOptions li {
  padding: 4px;
  border-bottom: 1px solid #e5e5e5;
}

.searchFilter .sbOptions li:last-child {
  border-bottom: none;
}

.searchFilter .sbOptions a {
  color: #969696 !important;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: uppercase;
}

.searchFilter .sbOptions a:hover {
  color: #ff891e !important;
  background-color: #f5f5f5;
}

/* BOOKING DROP */
.bookingDrop .sbHolder, .bookingDrop .sbSelector {
  width: 100% !important;
  height: 45px !important;
}

.bookingDrop .sbHolder {
  background-color: #f5f5f5;
  border: none;
  margin: 4px 0 20px 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.bookingDrop .sbSelector:link,
.bookingDrop .sbSelector:visited,
.bookingDrop .sbSelector:hover {
  color: #969696 !important;
  text-transform: capitalize;
}

.bookingDrop .sbSelector {
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  line-height: 42px;
  text-indent: 12px;
}

.bookingDrop .sbToggle {
  background: rgba(0, 0, 0, 0) url("../plugins/selectbox/images/booking-drop.png") no-repeat scroll 0 0;
  height: 45px;
  right: 0px;
  top: 0px;
  width: 25px;
}

.bookingDrop .sbOptions {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  right: 0 !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 100;
}

.bookingDrop .sbOptions li {
  padding: 4px;
  border-bottom: 1px solid #e5e5e5;
}

.bookingDrop .sbOptions li:last-child {
  border-bottom: none;
}

.bookingDrop .sbOptions a {
  color: #969696 !important;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: uppercase;
}

.bookingDrop .sbOptions a:hover {
  color: #ff891e !important;
  background-color: #f5f5f5;
}

/* PRIMARY DROP */
.primaryDrop .sbHolder,
.primaryDrop .sbSelector {
  width: 100% !important;
  height: 45px !important;
}

.primaryDrop .sbHolder {
  background-color: #ff891e;
  border: none;
  margin: 20px 0 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.primaryDrop .sbSelector:link,
.primaryDrop .sbSelector:visited,
.primaryDrop .sbSelector:hover {
  color: #fff !important;
  text-transform: uppercase;
}

.primaryDrop .sbSelector {
  font-family: "Montserrat",sans-serif;
  font-size: 14px;
  line-height: 46px;
  text-indent: 20px;
}

.primaryDrop .sbToggle {
  background: rgba(0, 0, 0, 0) url("../plugins/selectbox/images/primary-drop.png") no-repeat scroll 0 0;
  height: 6px;
  right: 20px;
  top: 21px;
  width: 9px;
}

.primaryDrop .sbOptions {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  right: 0 !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 100;
}

.primaryDrop .sbOptions li {
  padding: 4px;
  border-bottom: 1px solid #e5e5e5;
}

.primaryDrop .sbOptions li:last-child {
  border-bottom: none;
}

.primaryDrop .sbOptions a {
  color: #969696 !important;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: uppercase;
}

.primaryDrop .sbOptions a:hover {
  color: #ff891e !important;
  background-color: #f5f5f5;
}

/* LIGHT DROP */
.lightDrop .sbHolder,
.lightDrop .sbSelector {
  width: 100% !important;
  height: 45px !important;
}

.lightDrop .sbHolder {
  background-color: #ededed;
  border: none;
  margin: 20px 0 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.lightDrop .sbSelector:link,
.lightDrop .sbSelector:visited,
.lightDrop .sbSelector:hover {
  color: #222222 !important;
  text-transform: uppercase;
}

.lightDrop .sbSelector {
  font-family: "Montserrat",sans-serif;
  font-size: 14px;
  line-height: 46px;
  text-indent: 20px;
}

.lightDrop .sbToggle {
  background: rgba(0, 0, 0, 0) url("../plugins/selectbox/images/light-drop.png") no-repeat scroll 0 0;
  height: 6px;
  right: 20px;
  top: 21px;
  width: 9px;
}

.lightDrop .sbOptions {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  right: 0 !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 100;
}

.lightDrop .sbOptions li {
  padding: 4px;
  border-bottom: 1px solid #e5e5e5;
}

.lightDrop .sbOptions li:last-child {
  border-bottom: none;
}

.lightDrop .sbOptions a {
  color: #969696 !important;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: uppercase;
}

.lightDrop .sbOptions a:hover {
  color: #ff891e !important;
  background-color: #f5f5f5;
}

/* TRANSPARENT DROP */
.transparentDrop .sbHolder,
.transparentDrop .sbSelector {
  width: 100% !important;
  height: 45px !important;
}

.transparentDrop .sbHolder {
  background-color: transparent;
  border: none;
  margin: 20px 0 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #dedede;
}

.transparentDrop .sbSelector:link,
.transparentDrop .sbSelector:visited,
.transparentDrop .sbSelector:hover {
  color: #222222 !important;
  text-transform: uppercase;
}

.transparentDrop .sbSelector {
  font-family: "Montserrat",sans-serif;
  font-size: 14px;
  line-height: 46px;
  text-indent: 20px;
}

.transparentDrop .sbToggle {
  background: rgba(0, 0, 0, 0) url("../plugins/selectbox/images/light-drop.png") no-repeat scroll 0 0;
  height: 6px;
  right: 20px;
  top: 21px;
  width: 9px;
}

.transparentDrop .sbOptions {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  right: 0 !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 100;
}

.transparentDrop .sbOptions li {
  padding: 4px;
  border-bottom: 1px solid #e5e5e5;
}

.transparentDrop .sbOptions li:last-child {
  border-bottom: none;
}

.transparentDrop .sbOptions a {
  color: #969696 !important;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: uppercase;
}

.transparentDrop .sbOptions a:hover {
  color: #ff891e !important;
  background-color: #f5f5f5;
}

.primaryDrop.addCircle .sbHolder,
.lightDrop.addCircle .sbHolder,
.transparentDrop.addCircle .sbHolder {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

/* HOTEL SELECT BOX */
.searchHotel .sbHolder {
  width: 220px;
  height: 45px;
  background-color: transparent;
  line-height: 45px;
  border: 1px solid #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-family: "Montserrat",sans-serif;
}

.searchHotel .sbHolder .sbToggle {
  background-image: url(../plugins/selectbox/images/primary-drop.png);
  background-repeat: no-repeat;
  top: 19px;
}

.searchHotel .sbHolder .sbSelector {
  line-height: 45px;
  color: #fff !important;
  text-transform: uppercase;
  width: 100%;
}

.searchHotel .sbHolder .sbSelector li a {
  color: #969696;
  font-size: 12px;
  float: none;
  width: 100%;
  background-color: transparent;
  padding: 10px 12px;
}

.searchHotel .sbHolder .sbOptions {
  right: auto;
  left: 1px;
  width: 100% !important;
  top: 44px !important;
}

.searchHotel .sbHolder .sbOptions li a {
  color: #969696;
  font-size: 12px;
  float: none;
  width: 100%;
  background-color: transparent;
  padding: 10px 12px;
  font-family: "Montserrat",sans-serif;
}

.searchHotel .sbHolder .sbOptions li a:hover {
  color: #005fae !important;
}

.amazingSelectbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .amazingSelectbox {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .amazingSelectbox {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

.amazingSelectbox .searchHotel {
  margin-bottom: 20px;
}

.amazingSelectbox .searchHotel:last-child {
  margin-right: 0 !important;
}

@media (min-width: 768px) {
  .amazingSelectbox .searchHotel {
    float: left;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.amazingSelectbox .searchHotel .ed-datepicker {
  border: 1px solid #dedede;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-top: 0;
  width: 220px;
  margin-right: 10px;
}

.amazingSelectbox .searchHotel .ed-datepicker .form-control {
  padding: 0 15px;
  color: #fff;
}

.amazingSelectbox .searchHotel .ed-datepicker .form-control::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.amazingSelectbox .searchHotel .ed-datepicker .form-control::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.amazingSelectbox .searchHotel .ed-datepicker .form-control:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.amazingSelectbox .searchHotel .ed-datepicker .input-group-addon span {
  color: #fff;
  padding-right: 15px;
}

.amazingSelectbox .searchHotelBtn .buttonCustomPrimary {
  padding: 0 20px;
  width: 222px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .amazingSelectbox .searchHotelBtn .buttonCustomPrimary {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .amazingSelectbox .searchHotelBtn .buttonCustomPrimary {
    width: 124px;
  }
}

.amazingSelectbox .searchHotelBtn .buttonCustomPrimary:hover {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.subscribSection .amazingSelectbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .subscribSection .amazingSelectbox {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .subscribSection .amazingSelectbox {
    justify-content: space-around;
    align-items: center;
  }
}

.subscribSection .amazingSelectbox .subscribInput {
  float: left;
  margin-right: 5px;
}

.subscribSection .amazingSelectbox .subscribInput input[type="email"] {
  width: 250px;
  height: 45px;
  background-color: transparent;
  border: 1px solid #fff;
  margin-bottom: 20px;
  display: inline-block;
}

@media (min-width: 768px) {
  .subscribSection .amazingSelectbox .subscribInput input[type="email"] {
    width: 230px;
  }
}

@media (min-width: 992px) {
  .subscribSection .amazingSelectbox .subscribInput input[type="email"] {
    width: 340px;
  }
}

@media (min-width: 1200px) {
  .subscribSection .amazingSelectbox .subscribInput input[type="email"] {
    width: 350px;
  }
}

.subscribSection .amazingSelectbox .subscribInput input::-webkit-input-placeholder {
  color: #fff;
}

.subscribSection .amazingSelectbox .subscribInput input::-moz-placeholder {
  color: #fff;
}

.subscribSection .amazingSelectbox .subscribInput input:-ms-input-placeholder {
  color: #fff;
}

.subscribSection .amazingSelectbox .searchHotel .sbHolder {
  width: 250px;
}

@media (min-width: 768px) {
  .subscribSection .amazingSelectbox .searchHotel .sbHolder {
    width: 230px;
  }
}

@media (min-width: 992px) {
  .subscribSection .amazingSelectbox .searchHotel .sbHolder {
    width: 240px;
  }
}

@media (min-width: 1200px) {
  .subscribSection .amazingSelectbox .searchHotel .sbHolder {
    width: 250px;
  }
}

.subscribSection .amazingSelectbox .searchHotelBtn .buttonCustomPrimary {
  width: 250px;
  background-color: #fff;
  color: #005fae !important;
}

@media (min-width: 768px) {
  .subscribSection .amazingSelectbox .searchHotelBtn .buttonCustomPrimary {
    width: auto;
  }
}

@media (min-width: 1200px) {
  .subscribSection .amazingSelectbox .searchHotelBtn .buttonCustomPrimary {
    width: 175px;
  }
}

.subscribSection .amazingSelectbox .searchHotelBtn .buttonCustomPrimary:hover {
  background-color: transparent;
  color: #fff !important;
}

/*=== 2.13 INPUT TYPE TEXT ===*/
.inputText {
  border-bottom: 1px solid #dedede;
  height: 45px;
}

.inputText .input-group .input-label {
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 20;
  transform: translate(0, -50%);
}

.inputText .form-control {
  border-color: transparent;
  box-shadow: none;
  padding: 6px 0px;
  height: 44px;
  position: relative;
}

.inputText .form-control::-webkit-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.inputText .form-control::-moz-placeholder {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.inputText .form-control:-ms-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.panel {
  border-width: 2px !important;
}

/*=== 3. HEADER ===*/
header {
  position: relative;
  z-index: 999;
}

/*=== 3.1 HEADER TRANSPARENT ===*/
.navbar-default {
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  position: relative;
}

.navbar-default.navbar-fixed-top {
  position: fixed;
}

.navbar-nav > li.open a, .navbar-nav > li.open a:hover, .navbar-nav > li.open a:focus {
  background-color: transparent !important;
  color: #005fae !important;
}

.navbar-nav > li.open a:after, .navbar-nav > li.open a:hover:after, .navbar-nav > li.open a:focus:after {
  width: 30%;
}

@media (max-width: 767px) {
  .navbar-nav {
    background-color: #ffffff;
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    background-color: transparent !important;
  }
}

.navbar-nav > li > a {
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-nav > li > a:after {
  content: "";
  height: 3px;
  left: 0;
  bottom: -3px;
  width: 0px;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #005fae;
}

.navbar-nav > li > a:hover {
  color: #005fae !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .navbar-nav > li > a:hover:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li > a:hover:after {
    width: 100%;
  }
}

.navbar-nav > li > a:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 767px) {
  .navbar-nav > li > a {
    color: #000000 !important;
    padding: 12px;
    border-bottom: 1px solid white;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    margin: 0 5px 29px 5px;
    font-size: 10px;
    color: #ffffff !important;
    padding: 38px 0 7px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

@media (min-width: 992px) {
  .navbar-nav > li > a {
    margin: 0 8px 29px 8px;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav > li > a {
    margin: 0 15px 29px 15px;
  }
}

.navbar-nav > li.active a, .navbar-nav > li.active a:hover {
  color: #005fae !important;
  background-color: transparent !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .navbar-nav > li.active a:after, .navbar-nav > li.active a:hover:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.active a:after, .navbar-nav > li.active a:hover:after {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .navbar-nav > li.active a:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.active a:after {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .navbar-nav > li.open a:hover:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.open a:hover:after {
    width: 100%;
  }
}

.navbar-nav > li.dropdown .dropdown-menu {
  box-shadow: none;
}

.navbar-nav > li.dropdown .dropdown-menu a {
  padding: 3px 20px;
  margin-bottom: 0;
}

.navbar-nav > li.dropdown.searchBox a {
  border: none;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.searchBox a {
    padding: 12px;
    margin-bottom: 10px;
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.searchBox a {
    padding-top: 42px;
    margin-bottom: 25px;
    display: block;
  }
}

.navbar-nav > li.dropdown.searchBox a .searchIcon {
  padding: 10px 12px;
  background-color: #005fae;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #ffffff !important;
}

.navbar-nav > li.dropdown.searchBox a:hover .searchIcon {
  color: #ffffff !important;
}

.navbar-nav > li.dropdown.searchBox a:hover:after {
  display: none;
}

.navbar-nav > li.dropdown.searchBox a:after {
  display: none;
}

.navbar-nav > li.dropdown.searchBox .dropdown-menu {
  margin: 0 10px 0 0;
  padding: 10px 15px;
  border-radius: 0;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.searchBox .dropdown-menu {
    width: 100%;
    position: relative;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.searchBox .dropdown-menu {
    width: 350px;
  }
}

.navbar-nav > li.dropdown.searchBox .dropdown-menu li .form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #aeaeae;
}

.navbar-nav > li.dropdown.searchBox .dropdown-menu li .input-group-addon {
  background-color: #005fae;
  border-color: #005fae;
  border-radius: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-nav > li.dropdown.megaDropMenu {
  position: static;
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
  margin: -1px auto 0 auto;
  right: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    left: 0;
    padding: 0;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    left: 15px;
    padding: 10px 5px 0;
    width: 720px;
  }
}

@media (min-width: 992px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    width: 1140px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
  width: 100%;
  float: left;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 15px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  color: #222222;
  float: left;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px 10px 0;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
  color: #5f5f5f !important;
  font-size: 12px;
  float: left;
  width: 100%;
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 6px 12px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover {
  background-color: #bebebe !important;
  color: #005fae !important;
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-width: 270px;
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
  color: #5f5f5f !important;
  font-size: 12px;
  float: none;
  width: 100%;
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
    padding: 6px 12px 6px 24px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a:hover {
  background-color: #bebebe !important;
  color: #005fae !important;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu li a {
    padding: 6px 12px 6px 36px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu li a {
    padding: 10px 12px;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a i {
  float: right;
  margin-top: 4px;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  margin-top: 0 !important;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

@media (min-width: 480px) {
  .dropdown-submenu > .dropdown-menu {
    left: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-submenu > .dropdown-menu {
    left: 100%;
  }
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

@media (max-width: 767px) {
  .navbar-nav > li.open a:after, .navbar-nav > li.open a:hover:after, .navbar-nav > li.open a:focus:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.open a:after, .navbar-nav > li.open a:hover:after, .navbar-nav > li.open a:focus:after {
    width: 100%;
  }
}

.navbar-nav.searchBox a:after {
  display: none;
}

.navbar-toggle {
  margin-top: 20px;
}

.navbar-toggle {
  background-color: #ffffff;
}

.navbar-collapse {
  border-color: #ffffff;
}

.navbar-collapse li.dropdown .dropdown-menu {
  box-shadow: none;
}

@media (max-width: 767px) {
  .navbar-collapse li.dropdown .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-collapse li.dropdown .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
  }
}

.navbar-collapse li.dropdown.open .dropdown-menu {
  display: block;
}

@media (max-width: 767px) {
  .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
  }
}

.navbar-collapse li.dropdown.dropdown-submenu.open .dropdown-menu {
  display: block;
}

.navbar-brand {
  float: left;
  margin-top: 20px;
  background-image: url("../img/citybike/logo.png");
  background-position: 144px 144px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/citybike/logo.png");
  background-position: 144px 144px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 144px;
  height: 144px;
  background-color: transparent;
  padding: 24px 15px 24px 15px;
}

@media (max-width: 767px) {
  .navbar-brand {
    background-size: contain !important;
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .navbar-brand {
    background-size: cover !important;
  }
}

.static .navbar-right {
  margin-right: -30px !important;
}

/*=== 3.2 LIGHT HEADER ===*/
.lightHeader {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .lightHeader {
    height: 83px;
  }
}

@media (min-width: 768px) {
  .lightHeader {
    height: 95px;
  }
}

.lightHeader .navbar-brand {
  background-image: url("../img/citybike/logo.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 767px) {
  .lightHeader .navbar-brand {
    margin-top: 0px;
    height: 83px;
  }
}

@media (min-width: 768px) {
  .lightHeader .navbar-brand {
    /*background-size: $base-xs*101+1px $base-xs*27 !important;*/
    height: 144px;
    background-image: url("../img/citybike/logo.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.lightHeader .navbar-nav > li > a {
  color: #000000 !important;
}

.lightHeader .navbar-nav > li > a:hover {
  color: #005fae !important;
  border-color: #005fae;
}

@media (max-width: 767px) {
  .lightHeader .navbar-nav > li > a {
    border-bottom: 1px solid #ffffff;
  }
}

@media (min-width: 768px) {
  .lightHeader .navbar-nav > li > a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

/*=== 3.3 DARK HEADER ===*/
.darkHeader {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #262a2e;
}

@media (max-width: 767px) {
  .darkHeader {
    height: 83px;
  }
}

@media (min-width: 768px) {
  .darkHeader {
    height: 95px;
  }
}

.darkHeader .navbar-brand {
  background-image: url("../img/logo-dark.png");
  background-position: 15px 19px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .darkHeader .navbar-brand {
    /*background-size: $base-xs*76 $base-xs*20 !important;*/
    margin-top: 0;
    height: 83px;
  }
}

@media (min-width: 768px) {
  .darkHeader .navbar-brand {
    /*background-size: $base-xs*101+1px $base-xs*27 !important;*/
    height: 95px;
  }
}

.darkHeader .navbar-nav > li > a:hover {
  color: #005fae !important;
  border-color: #005fae;
}

@media (max-width: 767px) {
  .darkHeader .navbar-nav > li > a {
    border-bottom: 1px solid #ffffff;
    color: #000000 !important;
  }
}

@media (min-width: 768px) {
  .darkHeader .navbar-nav > li > a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff !important;
  }
}

/*=== 3.4 HEADER TOP ===*/
.headerTop {
  display: none;
  background-color: #f5f5f5;
  color: #969696;
}

@media (min-width: 768px) {
  .headerTop {
    display: block;
  }
}

@media (min-width: 992px) {
  .headerTop {
    min-height: 40px;
    height: 100%;
  }
}

.headerTop .headerTopNav {
  padding: 7px 0;
}

.headerTop .headerTopNav .headerTopNavbar {
  float: left;
  margin-top: 2px;
}

.headerTop .headerTopNav .headerTopNavbar li {
  display: inline-block;
  padding: 0 15px;
}

.headerTop .headerTopNav .headerTopNavbar li a {
  color: #005fae;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
}

@media (min-width: 1200px) {
  .headerTop .headerTopNav .headerTopNavbar li a {
    font-size: 17px;
  }
}

.headerTop .headerTopNav .headerTopNavbar li a i, .headerTop .headerTopNav .headerTopNavbar li a img {
  margin-right: 10px;
  margin-top: -2px;
}

.headerTop .headerTopNav .headerTopNavbar li:first-child {
  padding-left: 0;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a {
  border: none;
}

@media (max-width: 767px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a {
    padding: 12px;
    margin-bottom: 10px;
    display: none;
  }
}

@media (min-width: 768px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a {
    display: block;
  }
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a:hover .searchIcon {
  color: #ffffff !important;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a:hover:after {
  display: none;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a:after {
  display: none;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu {
  margin: 0 10px 0 0;
  padding: 10px 0 5px 0;
  border-radius: 0;
}

@media (max-width: 767px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu {
    width: 100%;
    position: relative;
    display: block;
  }
}

@media (min-width: 768px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu {
    width: 350px;
  }
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu li .form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #aeaeae;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu li .input-group-addon {
  background-color: #005fae;
  border-color: #005fae;
  border-radius: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

/*=== 3.5 NAVBAR HOME CITY ===*/
.navbar .navbar-btn {
  margin-left: 10px;
}

@media (min-width: 768px) {
  .navbar .navbar-btn {
    margin-top: 34px;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-btn {
    margin-top: 29px;
  }
}

.navbar .navbar-btn .btn-nav {
  text-transform: uppercase;
  width: 127px;
  height: 40px;
  background-color: #005fae;
  color: #ffffff;
  line-height: 14px;
  font-size: 14px;
  font-weight: normal;
  padding: 12px 0;
}

@media (min-width: 768px) {
  .navbar .navbar-btn .btn-nav {
    width: 100px;
    height: 33px;
    font-size: 10px;
    line-height: 13px;
    padding: 10px 0px;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-btn .btn-nav {
    width: 127px;
    height: 40px;
    line-height: 14px;
    font-size: 14px;
    font-weight: normal;
    padding: 12px 0;
  }
}

a.navbar-brand:hover {
  opacity: 1;
  transform: rotate(-3deg);
}

/* Languages */
.languages {
  padding-right: 0px;
  padding-top: 10px;
}

@media (min-width: 992px) {
  .languages {
    padding-top: 0;
  }
}

.languages img {
  -webkit-filter: opacity(60%);
  /* Safari 6.0 - 9.0 */
  filter: opacity(60%);
  max-width: 26px;
}

.languages img:hover {
  -webkit-filter: opacity(100%);
  /* Safari 6.0 - 9.0 */
  filter: opacity(100%);
}

.languages img.active {
  -webkit-filter: opacity(100%);
  /* Safari 6.0 - 9.0 */
  filter: opacity(100%);
}

/*=== 4. FOOTER ===*/
.footer {
  width: 100%;
  display: block;
  background-color: #252b2f;
  padding: 60px 0;
}

.foo .footer-logo {
  display: block;
  margin-bottom: 25px;
}

.footer-logo img {
  max-width: 80px;
}

.footerContent h3 {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
}

.footerContent p {
  margin-top: 30px;
  margin-bottom: 20px;
  line-height: 24px;
  color: #c2c2c2;
}

@media (min-width: 768px) {
  .footerContent p {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .footerContent p {
    font-size: 15px;
  }
}

.footerContent .list-unstyled li {
  color: #c2c2c2;
}

@media (min-width: 768px) {
  .footerContent .list-unstyled li {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-unstyled li {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .footerContent .list-unstyled li i {
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .footerContent .list-unstyled li i {
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-unstyled li i {
    margin-right: 14px;
  }
}

.footerContent .list-unstyled li a {
  color: #c2c2c2;
}

.footerContent .list-unstyled li a:hover {
  color: #005fae;
}

.footerContent .list-unstyled li strong {
  color: #fff;
  padding-right: 5px;
}

@media (min-width: 480px) {
  .footerContent.imgGallery .row {
    width: auto;
  }
}

@media (min-width: 480px) {
  .footerContent.imgGallery .row {
    width: 100%;
  }
}

.footerContent.imgGallery .row .col-xs-4 {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .footerContent.imgGallery .row .col-xs-4 {
    padding: 0 15px 0 15px;
  }
}

@media (min-width: 768px) {
  .footerContent.imgGallery .row .col-xs-4 {
    padding: 0 0 0 15px;
  }
}

.footerContent.imgGallery .row .col-xs-4 a {
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.footerContent.imgGallery .row .col-xs-4 a img {
  width: 100%;
}

.footerContent .input-group {
  border: 1px solid #181615;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.footerContent .input-group .form-control {
  background-color: transparent;
  border: 0;
  color: #5f5d5b;
}

.footerContent .input-group .input-group-addon {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #262422;
  border: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.footerContent .input-group .input-group-addon i {
  color: #ffffff;
}

.footerContent .input-group .input-group-addon:hover {
  background-color: #005fae;
  border-color: #005fae;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 768px) {
  .footerContent .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .footerContent .list-inline li {
    padding: 0 5px;
  }
}

.footerContent .list-inline li a {
  display: block;
  text-align: center;
  border: 1px solid #181615;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
}

.footerContent .list-inline li a:hover {
  background-color: #005fae;
  border-color: #005fae;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .footerContent .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .footerContent .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

.footerContent .list-inline .list-facebook {
  background: #3b5998;
}

.footerContent .list-inline .list-facebook:hover {
  background: #3b5999;
}

.footerContent .list-inline .list-twitter {
  background: #55acee;
}

.footerContent .list-inline .list-twitter:hover {
  background: #56acee;
}

.footerContent .list-inline .list-instagram {
  background: #6a453b;
}

.footerContent .list-inline .list-instagram:hover {
  background: #6b453b;
}

.footerContent .list-inline .list-tripadvisor {
  background: #589441;
}

.footerContent .list-inline .list-tripadvisor:hover {
  background: #589541;
}

@media (min-width: 992px) {
  .footerContent .list-inline .list-tripadvisor:after {
    background: url("../img/citybike/certificate.png") no-repeat top left;
    width: 163px;
    height: 46px;
    position: absolute;
    display: block;
    content: ' ';
    margin-top: -41px;
    margin-left: 38px;
  }
}

.copyRight {
  display: block;
  background-color: #1a1d1f;
  padding: 30px 0;
  color: #c2c2c2;
  font-size: 14px;
}

@media (max-width: 767px) {
  .copyRight .list-inline {
    float: left;
    width: 100%;
    text-align: center;
    margin: 0 0 30px 0;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline {
    float: right;
    width: auto;
    text-align: left;
    margin-bottom: 0;
  }
}

.copyRight .list-inline li a {
  font-size: 13px;
  color: #5f5d5b;
}

.copyRight .list-inline li a:hover {
  color: #005fae;
}

.copyRight a {
  color: #c2c2c2;
  font-weight: bold;
}

.copyRightText p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Montserrat',sans-serif;
  color: #c2c2c2;
}

.copyRightText p a {
  color: #c2c2c2;
  font-weight: bold;
}

.copyRightText p a:hover {
  color: #005fae;
}

@media (max-width: 767px) {
  .copyRightText p {
    font-size: 11px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .copyRightText p {
    text-align: left;
  }
}

.footer-light {
  background-color: transparent;
}

.footer-light .footerContent .footer-logo img {
  width: auto;
}

@media (min-width: 768px) {
  .footer-light .footerContent .footer-logo img {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .footer-light .footerContent .footer-logo img {
    width: auto;
  }
}

.footer-light .footerContent h5 {
  color: #222222;
}

.footer-light .footerContent .list-inline li a {
  color: #969696;
  border-color: #969696;
}

.footer-light .footerContent .list-inline li a:hover {
  color: #ffffff;
  border-color: #005fae;
}

.footer-light .footerContent .list-item li {
  margin-bottom: 17px;
}

.footer-light .footerContent .list-item li a {
  color: #969696;
}

.footer-light .footerContent .list-item li a:hover {
  color: #005fae;
}

/*================= 5. SECTION ==================*/
/*=== 5.1 MAIN CONTENT SECTION ===*/
.static .pageTitle {
  margin-top: 0;
}

.mainContentSection, .whiteSection, .lightSection {
  width: 100%;
  padding: 100px 0 70px 0;
  background-color: #ffffff;
  display: block;
}

/*=== 5.2 DARK SECTION ===*/
.darkSection {
  width: 100%;
  padding: 42px 0;
  background-color: #262a2e;
  display: block;
}

.darkSection.citiesPage {
  padding: 36px 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 80px;
}

@media (min-width: 768px) {
  .gridResize > .col-sm-3 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .gridResize > .col-sm-3 {
    width: 21%;
  }
}

@media (max-width: 767px) {
  .gridResize > .col-sm-7 {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .gridResize > .col-sm-7 {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .gridResize > .col-sm-7 {
    width: 68%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .gridResize > .col-sm-2 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .gridResize > .col-sm-2 {
    width: 11%;
  }
}

.gridResize .buttonCustomPrimary {
  margin-top: 4px;
}

/*=== 5.3 WHITE SECTION ===*/
.destinations {
  background-color: #262a2e;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.destinations a img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.destinations .media-left {
  display: inline-block;
  float: left;
}

@media (max-width: 767px) {
  .destinations .media-left {
    width: 100%;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .destinations .media-left {
    width: 50%;
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .destinations .media-left {
    width: auto;
  }
}

.destinations .media-left img {
  width: 100%;
}

@media (max-width: 767px) {
  .destinations .media-body {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .destinations .media-body {
    padding: 15px 23px 0 23px;
  }
}

@media (min-width: 992px) {
  .destinations .media-body {
    padding: 80px 23px 0 23px;
  }
}

@media (min-width: 1200px) {
  .destinations .media-body {
    padding: 80px 45px 0 45px;
  }
}

.destinations .media-body .media-heading {
  color: #ffffff;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .destinations .media-body .media-heading {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .destinations .media-body .media-heading {
    font-size: 20px;
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .destinations .media-body p {
    font-size: 11px;
    margin-bottom: 10px;
    line-height: 17px;
  }
}

@media (min-width: 992px) {
  .destinations .media-body p {
    font-size: 20px;
    margin-bottom: 25px;
    line-height: 21px;
  }
}

@media (min-width: 1200px) {
  .destinations .media-body p {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (max-width: 767px) {
  .destinations .media-body .list-unstyled {
    margin-bottom: 15px;
    width: 100%;
    display: block;
    float: none;
  }
}

@media (min-width: 768px) {
  .destinations .media-body .list-unstyled {
    margin-bottom: 5px;
    width: 33%;
    display: inline-block;
    float: left;
  }
}

@media (min-width: 992px) {
  .destinations .media-body .list-unstyled {
    margin-bottom: 24px;
  }
}

.destinations .media-body .list-unstyled li {
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
}

.destinations .media-body .list-unstyled li i {
  color: #005fae;
  margin-right: 10px;
}

.destinations .media-body .list-unstyled li a {
  text-transform: capitalize;
  color: #5f5d5b;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .destinations .media-body .list-unstyled li a {
    font-size: 11px;
    line-height: 17px;
  }
}

@media (min-width: 992px) {
  .destinations .media-body .list-unstyled li a {
    font-size: 14px;
    line-height: 21px;
  }
}

.destinations .media-body .list-unstyled li a i {
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 8px;
  color: #5f5d5b;
}

.destinations .media-body .list-unstyled li a:hover {
  color: #005fae;
}

.destinations .media-body .list-unstyled li a:hover i {
  color: #005fae;
}

@media (min-width: 768px) {
  .destinations .media-body .list-unstyled li:first-child {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .destinations .media-body .list-unstyled li:first-child {
    margin-bottom: 16px;
  }
}

.destinations .media-body .media-btn {
  display: block;
  width: 100%;
}

.filter-container {
  padding: 0 20px 30px 20px;
}

@media (max-width: 767px) {
  .isotopeFilters ul.filter > li {
    padding: 2px;
  }
}

@media (min-width: 768px) {
  .isotopeFilters ul.filter > li {
    padding: 0;
  }
}

.isotopeFilters ul.filter > li a {
  font-size: 14px;
  line-height: 35px;
  color: #5f5d5b;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
}

.isotopeFilters ul.filter > li.active a {
  background-color: #005fae;
  color: #ffffff;
  border-color: #005fae;
}

.fancybox-lock .fancybox-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.isotopeSelector figure {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector figure:hover {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector:hover img {
  -moz-transform: scale(1.2) rotate(0);
  -o-transform: scale(1.2) rotate(0);
  -ms-transform: scale(1.2) rotate(0);
  -webkit-transform: scale(1.2) rotate(0);
  transform: scale(1.2) rotate(0);
  border-radius: 5px;
}

.overlay-background {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector figure {
  position: relative;
  margin-bottom: 30px;
}

.isotopeSelector h4 {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  z-index: 100;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  text-align: center;
}

.isotopeSelector .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector .overlay .fancybox-pop {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  position: absolute;
  z-index: 1000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector .overlay .fancybox-pop .overlayInfo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
}

.isotopeSelector .overlay .fancybox-pop .overlayInfo h5 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 55px;
  margin-top: 0;
}

.isotopeSelector .overlay .fancybox-pop .overlayInfo h5 span {
  color: #005fae;
}

.isotopeSelector .overlay .fancybox-pop .overlayInfo p {
  color: #ffffff;
  margin-bottom: 0;
}

.isotopeSelector .overlay .fancybox-pop .overlayInfo p i {
  margin-right: 5px;
}

.isotopeSelector:hover .overlay {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.isotopeSelector:hover .overlay .overlayInfo {
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.isotopeSelector:hover h4 {
  color: #005fae;
}

.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo h2 {
  margin: 0 0 15px 0;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo p {
  color: #ffffff;
  margin-bottom: 25px;
}

.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .buttonTransparent, .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a, .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a, .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a {
  color: #ffffff;
  background-color: transparent;
}

.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .buttonTransparent:hover, .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover, .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover, .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover {
  background-color: #005fae;
}

/*=== 5.4 PAGE TITLE SECTION ===*/
.pageTitle {
  background-image: url("../img/pages/page-title-bg.jpg");
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center top;
  color: #ffffff;
  height: 150px;
}

@media (max-width: 767px) {
  .pageTitle {
    margin-top: 83px;
  }
}

@media (min-width: 768px) {
  .pageTitle {
    margin-top: 95px;
  }
}

.pageTitle .titleTable {
  display: table;
  height: 150px;
  width: 100%;
}

@media (min-width: 768px) {
  .pageTitle .titleTable {
    margin-top: -60px;
  }
}

.pageTitle .titleTable .titleTableInner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.pageTitle .titleTable .titleTableInner .pageTitleInfo {
  text-align: center;
}

.pageTitle .titleTable .titleTableInner .pageTitleInfo h1 {
  color: #ffffff;
  font-size: 30px;
}

.pageTitle .titleTable .titleTableInner .pageTitleInfo p {
  color: #ffffff;
  font-size: 15px;
}

.under-border {
  width: 60px;
  height: 3px;
  background-color: #ffffff;
  margin: 16px auto 0;
}

/*=== 5.5 PACKAGES SECTION ===*/
.packagesSection, .lightSection {
  background-color: #f5f5f5;
}

/*================= 6. HOME ==================*/
/*=== 3. HEADER ===*/
header {
  position: relative;
  z-index: 999;
}

/*=== 3.1 HEADER TRANSPARENT ===*/
.navbar-default {
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  position: relative;
}

.navbar-default.navbar-fixed-top {
  position: fixed;
}

.navbar-nav > li.open a, .navbar-nav > li.open a:hover, .navbar-nav > li.open a:focus {
  background-color: transparent !important;
  color: #005fae !important;
}

.navbar-nav > li.open a:after, .navbar-nav > li.open a:hover:after, .navbar-nav > li.open a:focus:after {
  width: 30%;
}

@media (max-width: 767px) {
  .navbar-nav {
    background-color: #ffffff;
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    background-color: transparent !important;
  }
}

.navbar-nav > li > a {
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-nav > li > a:after {
  content: "";
  height: 3px;
  left: 0;
  bottom: -3px;
  width: 0px;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #005fae;
}

.navbar-nav > li > a:hover {
  color: #005fae !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .navbar-nav > li > a:hover:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li > a:hover:after {
    width: 100%;
  }
}

.navbar-nav > li > a:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 767px) {
  .navbar-nav > li > a {
    color: #000000 !important;
    padding: 12px;
    border-bottom: 1px solid white;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    margin: 0 5px 29px 5px;
    font-size: 10px;
    color: #ffffff !important;
    padding: 38px 0 7px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

@media (min-width: 992px) {
  .navbar-nav > li > a {
    margin: 0 8px 29px 8px;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav > li > a {
    margin: 0 15px 29px 15px;
  }
}

.navbar-nav > li.active a, .navbar-nav > li.active a:hover {
  color: #005fae !important;
  background-color: transparent !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .navbar-nav > li.active a:after, .navbar-nav > li.active a:hover:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.active a:after, .navbar-nav > li.active a:hover:after {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .navbar-nav > li.active a:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.active a:after {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .navbar-nav > li.open a:hover:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.open a:hover:after {
    width: 100%;
  }
}

.navbar-nav > li.dropdown .dropdown-menu {
  box-shadow: none;
}

.navbar-nav > li.dropdown .dropdown-menu a {
  padding: 3px 20px;
  margin-bottom: 0;
}

.navbar-nav > li.dropdown.searchBox a {
  border: none;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.searchBox a {
    padding: 12px;
    margin-bottom: 10px;
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.searchBox a {
    padding-top: 42px;
    margin-bottom: 25px;
    display: block;
  }
}

.navbar-nav > li.dropdown.searchBox a .searchIcon {
  padding: 10px 12px;
  background-color: #005fae;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #ffffff !important;
}

.navbar-nav > li.dropdown.searchBox a:hover .searchIcon {
  color: #ffffff !important;
}

.navbar-nav > li.dropdown.searchBox a:hover:after {
  display: none;
}

.navbar-nav > li.dropdown.searchBox a:after {
  display: none;
}

.navbar-nav > li.dropdown.searchBox .dropdown-menu {
  margin: 0 10px 0 0;
  padding: 10px 15px;
  border-radius: 0;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.searchBox .dropdown-menu {
    width: 100%;
    position: relative;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.searchBox .dropdown-menu {
    width: 350px;
  }
}

.navbar-nav > li.dropdown.searchBox .dropdown-menu li .form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #aeaeae;
}

.navbar-nav > li.dropdown.searchBox .dropdown-menu li .input-group-addon {
  background-color: #005fae;
  border-color: #005fae;
  border-radius: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-nav > li.dropdown.megaDropMenu {
  position: static;
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
  margin: -1px auto 0 auto;
  right: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    left: 0;
    padding: 0;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    left: 15px;
    padding: 10px 5px 0;
    width: 720px;
  }
}

@media (min-width: 992px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu {
    width: 1140px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
  width: 100%;
  float: left;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 15px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  color: #222222;
  float: left;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px 10px 0;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
  color: #5f5f5f !important;
  font-size: 12px;
  float: left;
  width: 100%;
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 6px 12px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover {
  background-color: #bebebe !important;
  color: #005fae !important;
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-width: 270px;
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
  color: #5f5f5f !important;
  font-size: 12px;
  float: none;
  width: 100%;
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
    padding: 6px 12px 6px 24px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li a {
    padding: 10px 12px;
  }
}

.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a:hover {
  background-color: #bebebe !important;
  color: #005fae !important;
}

@media (max-width: 767px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu li a {
    padding: 6px 12px 6px 36px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.dropdown.singleDrop .dropdown-menu li .dropdown-menu li a {
    padding: 10px 12px;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a i {
  float: right;
  margin-top: 4px;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  margin-top: 0 !important;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

@media (min-width: 480px) {
  .dropdown-submenu > .dropdown-menu {
    left: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-submenu > .dropdown-menu {
    left: 100%;
  }
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

@media (max-width: 767px) {
  .navbar-nav > li.open a:after, .navbar-nav > li.open a:hover:after, .navbar-nav > li.open a:focus:after {
    width: 0%;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li.open a:after, .navbar-nav > li.open a:hover:after, .navbar-nav > li.open a:focus:after {
    width: 100%;
  }
}

.navbar-nav.searchBox a:after {
  display: none;
}

.navbar-toggle {
  margin-top: 20px;
}

.navbar-toggle {
  background-color: #ffffff;
}

.navbar-collapse {
  border-color: #ffffff;
}

.navbar-collapse li.dropdown .dropdown-menu {
  box-shadow: none;
}

@media (max-width: 767px) {
  .navbar-collapse li.dropdown .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-collapse li.dropdown .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
  }
}

.navbar-collapse li.dropdown.open .dropdown-menu {
  display: block;
}

@media (max-width: 767px) {
  .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-collapse li.dropdown.dropdown-submenu .dropdown-menu {
    position: absolute;
    float: left;
    display: none;
  }
}

.navbar-collapse li.dropdown.dropdown-submenu.open .dropdown-menu {
  display: block;
}

.navbar-brand {
  float: left;
  margin-top: 20px;
  background-image: url("../img/citybike/logo.png");
  background-position: 144px 144px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/citybike/logo.png");
  background-position: 144px 144px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 144px;
  height: 144px;
  background-color: transparent;
  padding: 24px 15px 24px 15px;
}

@media (max-width: 767px) {
  .navbar-brand {
    background-size: contain !important;
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .navbar-brand {
    background-size: cover !important;
  }
}

.static .navbar-right {
  margin-right: -30px !important;
}

/*=== 3.2 LIGHT HEADER ===*/
.lightHeader {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .lightHeader {
    height: 83px;
  }
}

@media (min-width: 768px) {
  .lightHeader {
    height: 95px;
  }
}

.lightHeader .navbar-brand {
  background-image: url("../img/citybike/logo.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 767px) {
  .lightHeader .navbar-brand {
    margin-top: 0px;
    height: 83px;
  }
}

@media (min-width: 768px) {
  .lightHeader .navbar-brand {
    /*background-size: $base-xs*101+1px $base-xs*27 !important;*/
    height: 144px;
    background-image: url("../img/citybike/logo.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.lightHeader .navbar-nav > li > a {
  color: #000000 !important;
}

.lightHeader .navbar-nav > li > a:hover {
  color: #005fae !important;
  border-color: #005fae;
}

@media (max-width: 767px) {
  .lightHeader .navbar-nav > li > a {
    border-bottom: 1px solid #ffffff;
  }
}

@media (min-width: 768px) {
  .lightHeader .navbar-nav > li > a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

/*=== 3.3 DARK HEADER ===*/
.darkHeader {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #262a2e;
}

@media (max-width: 767px) {
  .darkHeader {
    height: 83px;
  }
}

@media (min-width: 768px) {
  .darkHeader {
    height: 95px;
  }
}

.darkHeader .navbar-brand {
  background-image: url("../img/logo-dark.png");
  background-position: 15px 19px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .darkHeader .navbar-brand {
    /*background-size: $base-xs*76 $base-xs*20 !important;*/
    margin-top: 0;
    height: 83px;
  }
}

@media (min-width: 768px) {
  .darkHeader .navbar-brand {
    /*background-size: $base-xs*101+1px $base-xs*27 !important;*/
    height: 95px;
  }
}

.darkHeader .navbar-nav > li > a:hover {
  color: #005fae !important;
  border-color: #005fae;
}

@media (max-width: 767px) {
  .darkHeader .navbar-nav > li > a {
    border-bottom: 1px solid #ffffff;
    color: #000000 !important;
  }
}

@media (min-width: 768px) {
  .darkHeader .navbar-nav > li > a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff !important;
  }
}

/*=== 3.4 HEADER TOP ===*/
.headerTop {
  display: none;
  background-color: #f5f5f5;
  color: #969696;
}

@media (min-width: 768px) {
  .headerTop {
    display: block;
  }
}

@media (min-width: 992px) {
  .headerTop {
    min-height: 40px;
    height: 100%;
  }
}

.headerTop .headerTopNav {
  padding: 7px 0;
}

.headerTop .headerTopNav .headerTopNavbar {
  float: left;
  margin-top: 2px;
}

.headerTop .headerTopNav .headerTopNavbar li {
  display: inline-block;
  padding: 0 15px;
}

.headerTop .headerTopNav .headerTopNavbar li a {
  color: #005fae;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
}

@media (min-width: 1200px) {
  .headerTop .headerTopNav .headerTopNavbar li a {
    font-size: 17px;
  }
}

.headerTop .headerTopNav .headerTopNavbar li a i, .headerTop .headerTopNav .headerTopNavbar li a img {
  margin-right: 10px;
  margin-top: -2px;
}

.headerTop .headerTopNav .headerTopNavbar li:first-child {
  padding-left: 0;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a {
  border: none;
}

@media (max-width: 767px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a {
    padding: 12px;
    margin-bottom: 10px;
    display: none;
  }
}

@media (min-width: 768px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a {
    display: block;
  }
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a:hover .searchIcon {
  color: #ffffff !important;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a:hover:after {
  display: none;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox a:after {
  display: none;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu {
  margin: 0 10px 0 0;
  padding: 10px 0 5px 0;
  border-radius: 0;
}

@media (max-width: 767px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu {
    width: 100%;
    position: relative;
    display: block;
  }
}

@media (min-width: 768px) {
  .headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu {
    width: 350px;
  }
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu li .form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #aeaeae;
}

.headerTop .headerTopNav .headerTopNavbar.navbar-right .dropdown.searchBox .dropdown-menu li .input-group-addon {
  background-color: #005fae;
  border-color: #005fae;
  border-radius: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

/*=== 3.5 NAVBAR HOME CITY ===*/
.navbar .navbar-btn {
  margin-left: 10px;
}

@media (min-width: 768px) {
  .navbar .navbar-btn {
    margin-top: 34px;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-btn {
    margin-top: 29px;
  }
}

.navbar .navbar-btn .btn-nav {
  text-transform: uppercase;
  width: 127px;
  height: 40px;
  background-color: #005fae;
  color: #ffffff;
  line-height: 14px;
  font-size: 14px;
  font-weight: normal;
  padding: 12px 0;
}

@media (min-width: 768px) {
  .navbar .navbar-btn .btn-nav {
    width: 100px;
    height: 33px;
    font-size: 10px;
    line-height: 13px;
    padding: 10px 0px;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-btn .btn-nav {
    width: 127px;
    height: 40px;
    line-height: 14px;
    font-size: 14px;
    font-weight: normal;
    padding: 12px 0;
  }
}

a.navbar-brand:hover {
  opacity: 1;
  transform: rotate(-3deg);
}

/* Languages */
.languages {
  padding-right: 0px;
  padding-top: 10px;
}

@media (min-width: 992px) {
  .languages {
    padding-top: 0;
  }
}

.languages img {
  -webkit-filter: opacity(60%);
  /* Safari 6.0 - 9.0 */
  filter: opacity(60%);
  max-width: 26px;
}

.languages img:hover {
  -webkit-filter: opacity(100%);
  /* Safari 6.0 - 9.0 */
  filter: opacity(100%);
}

.languages img.active {
  -webkit-filter: opacity(100%);
  /* Safari 6.0 - 9.0 */
  filter: opacity(100%);
}

/*=== 4. FOOTER ===*/
.footer {
  width: 100%;
  display: block;
  background-color: #252b2f;
  padding: 60px 0;
}

.foo .footer-logo {
  display: block;
  margin-bottom: 25px;
}

.footer-logo img {
  max-width: 80px;
}

.footerContent h3 {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
}

.footerContent p {
  margin-top: 30px;
  margin-bottom: 20px;
  line-height: 24px;
  color: #c2c2c2;
}

@media (min-width: 768px) {
  .footerContent p {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .footerContent p {
    font-size: 15px;
  }
}

.footerContent .list-unstyled li {
  color: #c2c2c2;
}

@media (min-width: 768px) {
  .footerContent .list-unstyled li {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-unstyled li {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .footerContent .list-unstyled li i {
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .footerContent .list-unstyled li i {
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-unstyled li i {
    margin-right: 14px;
  }
}

.footerContent .list-unstyled li a {
  color: #c2c2c2;
}

.footerContent .list-unstyled li a:hover {
  color: #005fae;
}

.footerContent .list-unstyled li strong {
  color: #fff;
  padding-right: 5px;
}

@media (min-width: 480px) {
  .footerContent.imgGallery .row {
    width: auto;
  }
}

@media (min-width: 480px) {
  .footerContent.imgGallery .row {
    width: 100%;
  }
}

.footerContent.imgGallery .row .col-xs-4 {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .footerContent.imgGallery .row .col-xs-4 {
    padding: 0 15px 0 15px;
  }
}

@media (min-width: 768px) {
  .footerContent.imgGallery .row .col-xs-4 {
    padding: 0 0 0 15px;
  }
}

.footerContent.imgGallery .row .col-xs-4 a {
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.footerContent.imgGallery .row .col-xs-4 a img {
  width: 100%;
}

.footerContent .input-group {
  border: 1px solid #181615;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.footerContent .input-group .form-control {
  background-color: transparent;
  border: 0;
  color: #5f5d5b;
}

.footerContent .input-group .input-group-addon {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #262422;
  border: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.footerContent .input-group .input-group-addon i {
  color: #ffffff;
}

.footerContent .input-group .input-group-addon:hover {
  background-color: #005fae;
  border-color: #005fae;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (min-width: 768px) {
  .footerContent .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .footerContent .list-inline li {
    padding: 0 5px;
  }
}

.footerContent .list-inline li a {
  display: block;
  text-align: center;
  border: 1px solid #181615;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
}

.footerContent .list-inline li a:hover {
  background-color: #005fae;
  border-color: #005fae;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .footerContent .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .footerContent .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .footerContent .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

.footerContent .list-inline .list-facebook {
  background: #3b5998;
}

.footerContent .list-inline .list-facebook:hover {
  background: #3b5999;
}

.footerContent .list-inline .list-twitter {
  background: #55acee;
}

.footerContent .list-inline .list-twitter:hover {
  background: #56acee;
}

.footerContent .list-inline .list-instagram {
  background: #6a453b;
}

.footerContent .list-inline .list-instagram:hover {
  background: #6b453b;
}

.footerContent .list-inline .list-tripadvisor {
  background: #589441;
}

.footerContent .list-inline .list-tripadvisor:hover {
  background: #589541;
}

@media (min-width: 992px) {
  .footerContent .list-inline .list-tripadvisor:after {
    background: url("../img/citybike/certificate.png") no-repeat top left;
    width: 163px;
    height: 46px;
    position: absolute;
    display: block;
    content: ' ';
    margin-top: -41px;
    margin-left: 38px;
  }
}

.copyRight {
  display: block;
  background-color: #1a1d1f;
  padding: 30px 0;
  color: #c2c2c2;
  font-size: 14px;
}

@media (max-width: 767px) {
  .copyRight .list-inline {
    float: left;
    width: 100%;
    text-align: center;
    margin: 0 0 30px 0;
  }
}

@media (min-width: 768px) {
  .copyRight .list-inline {
    float: right;
    width: auto;
    text-align: left;
    margin-bottom: 0;
  }
}

.copyRight .list-inline li a {
  font-size: 13px;
  color: #5f5d5b;
}

.copyRight .list-inline li a:hover {
  color: #005fae;
}

.copyRight a {
  color: #c2c2c2;
  font-weight: bold;
}

.copyRightText p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Montserrat',sans-serif;
  color: #c2c2c2;
}

.copyRightText p a {
  color: #c2c2c2;
  font-weight: bold;
}

.copyRightText p a:hover {
  color: #005fae;
}

@media (max-width: 767px) {
  .copyRightText p {
    font-size: 11px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .copyRightText p {
    text-align: left;
  }
}

.footer-light {
  background-color: transparent;
}

.footer-light .footerContent .footer-logo img {
  width: auto;
}

@media (min-width: 768px) {
  .footer-light .footerContent .footer-logo img {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .footer-light .footerContent .footer-logo img {
    width: auto;
  }
}

.footer-light .footerContent h5 {
  color: #222222;
}

.footer-light .footerContent .list-inline li a {
  color: #969696;
  border-color: #969696;
}

.footer-light .footerContent .list-inline li a:hover {
  color: #ffffff;
  border-color: #005fae;
}

.footer-light .footerContent .list-item li {
  margin-bottom: 17px;
}

.footer-light .footerContent .list-item li a {
  color: #969696;
}

.footer-light .footerContent .list-item li a:hover {
  color: #005fae;
}

/*=== 6.1 BANNER ===*/
/* Home Slider 1 */
#rev_slider_wrapper1 #rev_slider_container .slide-layer-4 {
  text-transform: uppercase;
  background-color: #005fae;
  border: 0;
}

#rev_slider_wrapper1 #rev_slider_container .slide-layer-4 a {
  color: #fff;
}

#rev_slider_wrapper1 #rev_slider_container .slide-layer-4:hover {
  background: #005fae;
  cursor: pointer;
}

.rev_slider .caption, .rev_slider .tp-caption {
  max-width: 600px !important;
}

/*  HOME CITY SLIER */
#rev_slider_wrapper2 #rev_slider_container .slide-layer-1 {
  font-weight: 700;
  line-height: 55px;
  color: #fff;
  text-transform: uppercase;
  z-index: 10;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  z-index: 10;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-3 {
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-3:hover {
  background: #005fae;
  border: 2px solid #005fae;
  cursor: pointer;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-3:focus {
  text-decoration: none;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-4 {
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-4:hover {
  background: #005fae;
  border: 2px solid #005fae;
  cursor: pointer;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-4:focus {
  text-decoration: none;
}

/* HOME DESTINATION SLIDER */
#rev_slider_wrapper3 #rev_slider_container .slide-layer-2 {
  text-transform: uppercase;
}

#rev_slider_wrapper3 #rev_slider_container .slide-layer-3 .container-container-custom {
  width: 530px !important;
  margin-left: -15px;
  margin-right: -15px;
}

#rev_slider_wrapper3 #rev_slider_container .tp-rightarrow {
  display: none;
}

#rev_slider_wrapper3 #rev_slider_container .tp-leftarrow {
  display: none;
}

#rev_slider_wrapper3 #rev_slider_container .tp-bullet {
  display: none;
}

.tp-dottedoverlay.twoxtwo {
  background: rgba(0, 0, 0, 0.6) !important;
}

.bannercontainer {
  background: url("../img/citybike/banner-bg.jpg") no-repeat;
  position: relative;
  margin-top: 0;
  padding: 40px;
}

@media (min-width: 768px) {
  .bannercontainer {
    min-height: 787px;
    padding: 160px 0 0;
  }
}

.bannercontainer__inner {
  color: #fff;
  z-index: 400;
  position: relative;
  max-width: 800px;
  margin: 0px auto;
}

.bannercontainer__inner #text-container {
  min-height: 220px !important;
}

.bannercontainer__inner h1, .bannercontainer__inner h2 {
  font-size: 60px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.bannercontainer__inner p {
  font-size: 20px;
  color: #ffffff;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  margin: 20px auto 50px;
}

.bannercontainer__inner .box {
  border-radius: 4px;
  box-shadow: 0 0 20px #000000;
  max-width: 200px;
  background: top center no-repeat;
  min-height: 190px;
  position: relative;
  margin: 0 auto 30px;
}

.bannercontainer__inner .box:hover {
  margin-top: -5px;
  transition: 0.3s;
}

.bannercontainer__inner .box .title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  font-family: 'Montserrat',sans-serif;
  padding: 20px 0;
  margin-top: 130px;
  text-align: center;
  border-radius: 4px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.bannercontainer__inner .box--red {
  background-image: url("../img/citybike/box-1.jpg");
}

.bannercontainer__inner .box--red .title {
  background-color: #d90d22;
}

.bannercontainer__inner .box--blue {
  background-image: url("../img/citybike/box-2.jpg");
}

.bannercontainer__inner .box--blue .title {
  background-color: #005fae;
}

.bannercontainer__inner .box--green {
  background-image: url("../img/citybike/box-3.jpg");
}

.bannercontainer__inner .box--green .title {
  background-color: #00af72;
}

/*=== REASONS ===*/
.reason {
  background: url("../img/citybike/reason.png") no-repeat top left;
  padding: 0px 44px 0px 59px;
  font-size: 18px;
  color: #171612;
  font-weight: bold;
  float: left;
  line-height: 24px;
  width: 33%;
  text-transform: uppercase;
  margin-bottom: 10px;
  min-height: 60px;
}

@media (max-width: 767px) {
  .reason {
    width: 100%;
  }
}

.reason:nth-child(3n):after {
  clear: both;
  display: block;
  content: " ";
}

.reason--one-line {
  padding-top: 12px;
}

/*=== ABOUT US ===*/
.homepage__about-us {
  padding: 40px;
}

@media (min-width: 1200px) {
  .homepage__about-us {
    padding: 60px 240px 60px 40px;
  }
}

.homepage__about-us .title {
  color: #000;
  font-family: 'Montserrat',sans-serif;
  font-size: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.homepage__about-us p {
  color: #8b8b8b;
  font-size: 18px;
  line-height: 26px;
  font-family: 'Montserrat',sans-serif;
  font-weight: 300;
  margin-bottom: 30px;
}

.homepage__about-us .btn {
  float: left;
}

.map .map-in {
  width: 100%;
  height: 500px;
  margin: 0;
}

/*=== 6.2 PROMOTION ===*/
.promotionWrapper {
  background-image: url("../img/home/promotion/promotion-1.jpg");
  background-attachment: fixed;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 403px;
}

.promotionWrapper .promotionTable {
  display: table;
  height: 403px;
  width: 100%;
}

.promotionWrapper .promotionTable .promotionTableInner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.promotionWrapper .promotionTable .promotionTableInner .promotionInfo {
  text-align: center;
}

.promotionWrapper .promotionTable .promotionTableInner .promotionInfo span {
  line-height: 21px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 18px;
}

.promotionWrapper .promotionTable .promotionTableInner .promotionInfo h2 {
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
  margin-bottom: 40px;
  margin: 10px 0 40px 0;
}

.promotionWrapper .promotionTable .promotionTableInner .promotionInfo .rating li {
  color: #01238f;
  padding: 0;
}

.promotionWrapper .promotionTable .promotionTableInner .promotionInfo p {
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 20px 0;
  color: #ffffff;
}

.promotionWrapper .promotionTable .promotionTableInner .promotionInfo .buttonCustomPrimary {
  padding: 0 16px;
}

/*=== 6.3 COUNT UP ===*/
.countUpSection {
  background-image: url("../img/home/promotion/promotion-2.jpg");
  background-attachment: fixed;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center !important;
  color: #ffffff;
  padding: 130px 0;
}

.countUpSection .icon {
  width: 96px;
  height: 96px;
  background-color: rgba(0, 95, 174, 0.9);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 15px;
}

.countUpSection .icon i {
  font-size: 42px;
  line-height: 96px;
}

.countUpSection .counter {
  line-height: 40px;
}

@media (max-width: 767px) {
  .countUpSection .counter {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .countUpSection .counter {
    font-size: 52px;
  }
}

.countUpSection h5 {
  line-height: 21px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .countUpSection h5 {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .countUpSection h5 {
    font-size: 15px;
  }
}

.photoGallerySection {
  display: none;
}

@media (min-width: 768px) {
  .photoGallerySection {
    display: block;
  }
}

/*================= 7. PACKAGES ==================*/
/*================= 7.1 PACKAGES LIST ==================*/
.packagesList {
  background-color: #ffffff;
  min-height: 270px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .packagesList .media-left {
    float: left;
    width: 100%;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .packagesList .media-left {
    float: left;
    width: 100%;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .packagesList .media-left {
    float: left;
    width: auto;
    padding-right: 10px;
  }
}

.packagesList .media-left img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media (min-width: 992px) {
  .packagesList .media-left img {
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.packagesList .media-body {
  position: relative;
  height: 100%;
  overflow: visible;
}

@media (max-width: 767px) {
  .packagesList .media-body {
    padding: 30px;
  }
}

@media (min-width: 768px) {
  .packagesList .media-body {
    padding: 30px 30px 0 20px;
  }
}

.packagesList .media-body .detailsBtn {
  float: left;
  margin-left: 0;
  position: absolute;
  left: 20px;
  top: 200px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .packagesList .media-body .detailsBtn {
    position: relative;
    left: auto;
    top: auto;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .packagesList .media-body .detailsBtn {
    top: 180px;
  }
}

@media (min-width: 992px) {
  .packagesList .media-body .detailsBtn {
    top: 210px;
  }
}

@media (min-width: 1200px) {
  .packagesList .media-body .detailsBtn {
    top: 200px;
  }
}

.packagesList .media-body .detailsBtn .buttonTransparent, .packagesList .media-body .detailsBtn .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .packagesList .media-body .detailsBtn a, .packagesList .media-body .detailsBtn .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .packagesList .media-body .detailsBtn a, .packagesList .media-body .detailsBtn .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .packagesList .media-body .detailsBtn a {
  margin-bottom: 10px;
}

.packagesList .media-body .detailsBtn .buttonTransparent i, .packagesList .media-body .detailsBtn .paginationCenter .pagination li:first-child a i, .paginationCenter .pagination li:first-child .packagesList .media-body .detailsBtn a i, .packagesList .media-body .detailsBtn .paginationCenter .pagination li:last-child a i, .paginationCenter .pagination li:last-child .packagesList .media-body .detailsBtn a i, .packagesList .media-body .detailsBtn .isotopeFilters ul.filter > li a i, .isotopeFilters ul.filter > li .packagesList .media-body .detailsBtn a i {
  margin-right: 10px;
}

.packagesList .media-body .countryRating span {
  display: inline-block;
  float: left;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #a7a7a7;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
  color: #5f5f5f;
}

.textInfo {
  height: 35px;
  line-height: 35px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #a7a7a7;
  padding: 0 15px;
  color: #5f5f5f;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 13px;
  display: block;
}

.media-heading a {
  color: black;
}

.media-heading a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  color: #005fae;
}

.bodyLeft, .bodyRight {
  display: inline-block;
  float: left;
  width: 70%;
}

@media (max-width: 767px) {
  .bodyLeft, .bodyRight {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .bodyLeft, .bodyRight {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .bodyLeft, .bodyRight {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .bodyLeft, .bodyRight {
    width: 70%;
  }
}

@media (max-width: 767px) {
  .bodyLeft p {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .bodyLeft p {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .bodyLeft p {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .bodyLeft p {
    font-size: 14px;
  }
}

.bodyRight {
  float: right;
}

@media (max-width: 767px) {
  .bodyRight {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .bodyRight {
    width: 28%;
  }
}

@media (min-width: 992px) {
  .bodyRight {
    width: 38%;
  }
}

@media (min-width: 1200px) {
  .bodyRight {
    width: 28%;
  }
}

.bookingDetails {
  width: 100%;
  display: block;
  text-align: center;
  background-color: #005fae;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 25px 55px 15px 55px;
  min-height: 210px;
}

.bookingDetails h2 {
  color: #ffffff;
  margin-bottom: 5px;
}

.bookingDetails p {
  color: #ffffff;
}

.bookingDetails .buttonTransparent, .bookingDetails .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .bookingDetails a, .bookingDetails .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .bookingDetails a, .bookingDetails .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .bookingDetails a {
  margin-bottom: 10px;
  width: 100%;
  display: block;
  color: #ffffff;
  border-color: #ffffff;
  padding: 0;
}

.bookingDetails .buttonTransparent:hover, .bookingDetails .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .bookingDetails a:hover, .bookingDetails .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .bookingDetails a:hover, .bookingDetails .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .bookingDetails a:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #005fae;
}

.sidebarPage .packagesList {
  min-height: 234px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .sidebarPage .packagesList .media-left {
    float: left;
    width: 100%;
    padding-right: 0;
    height: auto;
  }
}

@media (min-width: 768px) {
  .sidebarPage .packagesList .media-left {
    float: left;
    width: 100%;
    padding-right: 0;
    height: auto;
  }
}

@media (min-width: 992px) {
  .sidebarPage .packagesList .media-left {
    float: left;
    width: 39%;
    padding-right: 10px;
    height: 240px;
    overflow: hidden;
  }
}

@media (min-width: 1200px) {
  .sidebarPage .packagesList .media-left {
    height: auto;
  }
}

@media (min-width: 992px) {
  .sidebarPage .packagesList .media-left img {
    transform: scale(1.5);
  }
}

.sidebarPage .packagesList .media-body {
  padding: 20px;
}

@media (max-width: 767px) {
  .sidebarPage .packagesList .media-body .detailsBtn {
    position: relative;
    left: auto;
    top: auto;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .sidebarPage .packagesList .media-body .detailsBtn {
    top: 180px;
  }
}

@media (min-width: 992px) {
  .sidebarPage .packagesList .media-body .detailsBtn {
    top: 175px;
  }
}

@media (min-width: 1200px) {
  .sidebarPage .packagesList .media-body .detailsBtn {
    top: 175px;
  }
}

@media (max-width: 767px) {
  .sidebarPage .packagesList .media-body .detailsBtn .btn {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .sidebarPage .packagesList .media-body .detailsBtn .btn {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .sidebarPage .packagesList .media-body .detailsBtn .btn {
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .sidebarPage .packagesList .media-body .detailsBtn .btn {
    font-size: 14px;
  }
}

.sidebarPage .packagesList .media-body .bookingDetails {
  min-height: 180px;
  padding: 15px;
}

/*================= 7.2 SINGLE PACKAGE FULL WIDTH ==================*/
.singlePackage .generalInfo h2, .relatedProduct h2 {
  font-size: 20px;
  color: #222222;
  line-height: 26px;
  margin: 0 0 25px 0;
  font-weight: 400;
}

.singlePackage .carousel {
  margin-bottom: 30px;
}

.singlePackage .carousel .carousel-inner {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 2px solid #005fae;
}

.singlePackage .carousel .carousel-inner .item img {
  width: 100%;
}

.singlePackage .carousel-indicators {
  display: none;
}

.singlePackage .carousel-control {
  background-image: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.singlePackage .carousel-control .glyphicon-menu-left,
.singlePackage .carousel-control .glyphicon-menu-right,
.singlePackage .carousel-control .icon-next,
.singlePackage .carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  margin-top: -40px;
  z-index: 5;
  width: 75px;
  height: 75px;
  line-height: 75px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.35);
}

@media (max-width: 767px) {
  .singlePackage .carousel-control .glyphicon-menu-left,
  .singlePackage .carousel-control .glyphicon-menu-right,
  .singlePackage .carousel-control .icon-next,
  .singlePackage .carousel-control .icon-prev {
    display: none;
  }
}

@media (min-width: 768px) {
  .singlePackage .carousel-control .glyphicon-menu-left,
  .singlePackage .carousel-control .glyphicon-menu-right,
  .singlePackage .carousel-control .icon-next,
  .singlePackage .carousel-control .icon-prev {
    display: inline-block;
  }
}

.singlePackage .carousel-control .icon-prev,
.singlePackage .carousel-control .glyphicon-menu-left {
  margin-left: 0;
  left: 20px;
}

.singlePackage .carousel-control .icon-next,
.singlePackage .carousel-control .glyphicon-menu-right {
  right: 20px;
  margin-right: 0;
}

.singlePackage .well {
  background-color: #bebebe;
  border-color: #b7b7b7;
  box-shadow: none;
  padding: 30px;
  margin-bottom: 70px;
}

.singlePackage .well .infoImage {
  width: 100%;
}

.singlePackage .well .infoImage img {
  width: 100%;
  min-height: 220px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.singlePackage .packageInfo h4 {
  text-transform: uppercase;
  font-weight: 400;
  color: #222222;
  margin: 20px 0 30px 0;
}

.singlePackage .packageInfo .dl-horizontal {
  margin-bottom: 0;
}

.singlePackage .packageInfo .dl-horizontal dt {
  text-align: left;
  color: #005fae;
  float: left;
  text-transform: uppercase;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .singlePackage .packageInfo .dl-horizontal dt {
    font-size: 12px;
    line-height: 19px;
    width: 110px;
  }
}

@media (min-width: 768px) {
  .singlePackage .packageInfo .dl-horizontal dt {
    font-size: 14px;
    line-height: 21px;
    width: 140px;
  }
}

.singlePackage .packageInfo .dl-horizontal dd {
  line-height: 21px;
}

.singlePackage .packageInfo .dl-horizontal dd span {
  color: #005fae;
}

@media (max-width: 767px) {
  .singlePackage .packageInfo .dl-horizontal dd {
    font-size: 12px;
    margin-left: 110px;
  }
}

@media (min-width: 768px) {
  .singlePackage .packageInfo .dl-horizontal dd {
    font-size: 14px;
    margin-left: 140px;
  }
}

.singlePackage .packageInfo p {
  margin-bottom: 25px;
}

.singlePackage .packageInfo p span {
  color: #005fae;
  font-size: 18px;
  margin-left: 10px;
}

.singlePackage .packageInfo .buttonCustomPrimary {
  padding: 0 20px;
}

.singlePackage .generalInfo {
  margin-bottom: 10px;
  float: left;
}

.review-aria .reviewContent {
  margin-top: 36px;
}

.review-aria .reviewContent .reviewTitle {
  font-size: 20px;
  color: #222222;
  font-weight: 400;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.review-aria .reviewContent .reviewTitle span {
  color: #01238f;
  font-size: 20px;
  margin-left: 10px;
}

.review-aria .reviewContent .reviewMedia .media-left img {
  width: 76px;
  height: 76px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 25px;
}

.review-aria .reviewContent .reviewMedia .media-body h5 {
  font-size: 15px;
  line-height: 26px;
  color: #222222;
}

.review-aria .reviewContent .reviewMedia .media-body span {
  color: #01238f;
}

.review-aria .reviewContent .commentsForm {
  margin-top: 30px;
}

.review-aria .reviewContent .commentsForm p span {
  margin-left: 15px;
  color: #01238f;
}

.review-aria .reviewContent .commentsForm .form-group .form-control {
  padding: 20px;
}

.review-aria .reviewContent .commentsForm .buttonCustomPrimary {
  margin-top: 15px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .review-aria .reviewContent .commentsForm .buttonCustomPrimary {
    margin-bottom: 0;
  }
}

.description-aria {
  margin-top: 35px;
}

.description-aria .descriptionList li {
  margin-bottom: 15px;
}

.description-aria .descriptionList li i.fa {
  margin-right: 9px;
}

.singlePackage .panel-group {
  margin-bottom: 70px;
}

.singlePackage .panel-group .panel-heading {
  padding: 15px;
  text-transform: uppercase;
  font-size: 18px;
  color: #5f5f5f;
  background-color: #bebebe;
  border-color: #b7b7b7;
}

.singlePackage .panel-group .panel-heading i {
  line-height: 22px;
}

.singlePackage .panel-default > a.panel-heading {
  display: block;
}

.singlePackage .panel-group .panel + .panel {
  margin-top: 15px;
}

.selectbox-sidebar .sbHolder {
  width: 140px;
  height: 45px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border-color: #dedede;
}

@media (min-width: 768px) {
  .selectbox-sidebar .sbHolder {
    width: 184px;
  }
}

@media (min-width: 992px) {
  .selectbox-sidebar .sbHolder {
    width: 140px;
  }
}

@media (min-width: 1200px) {
  .selectbox-sidebar .sbHolder {
    width: 178px;
  }
}

.selectbox-sidebar .sbHolder .sbToggle {
  background: url(../plugins/selectbox/images/arrow-down-1.png) no-repeat;
  z-index: -5;
}

.selectbox-sidebar .sbHolder .sbSelector {
  line-height: 45px;
  color: #666666 !important;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
  width: 100%;
}

.selectbox-sidebar .sbHolder .sbOptions {
  width: 100% !important;
  right: 0 !important;
  top: 44px !important;
  border-color: #dedede;
}

.selectbox-sidebar .sbHolder .sbOptions li a {
  color: #666666 !important;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
  font-size: 13px;
}

.selectbox-sidebar .sbHolder .sbOptions li a:hover {
  color: #005fae !important;
}

.selectbox-sidebar .icon-clock {
  position: absolute;
  right: 20px;
  top: 13px;
  color: #666666;
}

.relatedItem {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .relatedItem {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .relatedItem {
    margin-bottom: 0;
  }
}

.relatedItem img {
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.relatedItem .maskingInfo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.35);
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

.relatedItem .maskingInfo h4 {
  margin: 80px 0 15px 0;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.relatedItem .maskingInfo p {
  color: #ffffff;
  margin-bottom: 25px;
}

.relatedItem .maskingInfo .buttonTransparent, .relatedItem .maskingInfo .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .relatedItem .maskingInfo a, .relatedItem .maskingInfo .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .relatedItem .maskingInfo a, .relatedItem .maskingInfo .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .relatedItem .maskingInfo a {
  color: #ffffff;
}

.relatedItem:hover img {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.relatedItem:hover .maskingInfo {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.relatedItem:hover .maskingInfo h4 {
  margin: 70px 0 15px 0;
}

.relatedItem:hover {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .galleryCarousel .carousel-indicators {
    display: none;
  }
}

@media (min-width: 768px) {
  .galleryCarousel .carousel-indicators {
    display: block;
  }
}

.galleryCarousel .carousel-control .glyphicon-menu-left,
.galleryCarousel .carousel-control .glyphicon-menu-right,
.galleryCarousel .carousel-control .icon-next,
.galleryCarousel .carousel-control .icon-prev {
  background-color: rgba(255, 255, 255, 0.35);
}

.galleryCarousel .carousel-indicators li {
  display: inline-block;
  width: auto;
  height: auto;
  text-indent: 0;
  border-radius: 0px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-width: 2px;
  background-color: #ffffff;
}

.galleryCarousel .carousel-indicators li img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0.5);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}

.galleryCarousel .carousel-indicators li.active {
  width: auto;
  height: auto;
}

.galleryCarousel .carousel-indicators li.active img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.countryTitle .carousel-inner > .item {
  height: 200px;
}

@media (min-width: 480px) {
  .countryTitle .carousel-inner > .item {
    height: 225px;
  }
}

@media (min-width: 768px) {
  .countryTitle .carousel-inner > .item {
    height: 450px;
  }
}

.countryTitle .carousel-inner > .item img {
  height: 100%;
}

.weatherInfo {
  width: 100%;
  height: 450px;
  position: relative;
}

.weatherInfo img {
  width: 100%;
  height: 100%;
}

.weatherInfo .cityWeather {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.weatherInfo .cityWeather .cityTable {
  display: table;
  height: 450px;
  width: 100%;
}

.weatherInfo .cityWeather .cityTable .cityTableInner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.weatherInfo .cityWeather .cityTable .cityTableInner .cityWeatherInfo img {
  width: auto;
  height: auto;
  margin-bottom: 15px;
}

.weatherInfo .cityWeather .cityTable .cityTableInner .cityWeatherInfo h2 {
  font-size: 45px;
  color: #ffffff;
  line-height: 45px;
  margin: 0 0 25px 0;
}

.weatherInfo .cityWeather .cityTable .cityTableInner .cityWeatherInfo p {
  font-size: 20px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0;
  font-weight: 700;
}

.weatherInfo .dayTime {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  display: block;
  padding: 20px;
}

.weatherInfo .dayTime span {
  color: #ffffff;
}

.weatherInfo .daysWeather {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
  height: auto;
  display: block;
}

.weatherInfo .daysWeather .list-inline {
  margin-left: 0;
  text-align: center;
}

.weatherInfo .daysWeather .list-inline li {
  color: #ffffff;
  text-align: center;
  margin: 0 30px;
}

.weatherInfo .daysWeather .list-inline li span img {
  width: auto;
  height: auto;
  display: block;
  margin: 5px 0;
}

.countryTabs {
  margin-bottom: 60px;
}

.countryTabs .nav-tabs {
  border-color: #a7a7a7;
}

.countryTabs .nav-tabs > li {
  margin: 0;
}

.countryTabs .nav-tabs > li > a {
  color: #222222;
  border: none;
  text-transform: uppercase;
  border-bottom: 3px solid transparent;
  position: relative;
  margin: 0;
}

.countryTabs .nav-tabs > li > a:hover {
  background-color: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.countryTabs .nav-tabs > li > a:hover:after {
  width: 100%;
}

.countryTabs .nav-tabs > li > a:after {
  content: "";
  height: 3px;
  left: 0;
  bottom: -3px;
  width: 0px;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #005fae;
}

.countryTabs .nav-tabs > li.pull-right .list-inline li a {
  line-height: 43px;
  color: #5f5f5f;
}

.countryTabs .nav-tabs > li.pull-right .list-inline li a:hover {
  color: #005fae;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.countryTabs .nav-tabs > li.active > a,
.countryTabs .nav-tabs > li.active > a:focus,
.countryTabs .nav-tabs > li.active > a:hover {
  border: none;
  border-bottom: 3px solid #005fae;
  color: #222222;
}

.countryTabs .tab-content {
  padding-top: 30px;
}

.countryTabs .tab-content .well h3 {
  color: #222222;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 25px;
}

.countryTabs .tab-content .well .list-unstyled {
  margin-bottom: 15px;
  float: left;
}

.countryTabs .tab-content .well .list-unstyled li {
  color: #222222;
  margin-bottom: 15px;
}

.countryTabs .tab-content .well .list-unstyled li i {
  margin-right: 10px;
}

.countryTabs .tab-content .well .buttonCustomPrimary {
  padding: 0 20px;
}

.countryTabs .tab-content .well .buttonCustomPrimary:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.commonInfo h2 {
  color: #222222;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 25px;
}

.commonInfo p {
  margin-bottom: 25px;
}

.commonInfo .list-inline li {
  margin-bottom: 10px;
  width: 40%;
}

.commonInfo .list-inline li i {
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 10px;
  color: #323130;
  margin-right: 10px;
}

.relatedSection {
  margin-bottom: 80px;
}

.relatedSection .sectionTitle h2 {
  margin-bottom: 25px;
}

.relatedSection .deals {
  background-color: #bebebe;
}

.relatedSection .deals:hover {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.information-aria {
  margin-top: 25px;
}

.information-aria h3 {
  font-size: 20px;
  font-weight: 400;
  color: #222222;
  margin-bottom: 22px;
}

.information-aria p {
  margin-bottom: 25px;
}

.information-aria .singleContent {
  margin-bottom: 10px;
}

.information-aria .singleContent .media {
  background-color: #f5f5f5;
  padding: 40px 30px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.information-aria .singleContent .media .media-body {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .information-aria .singleContent .media .media-body {
    display: table-cell;
  }
}

.information-aria .singleContent .media .media-body .media-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 11px;
  color: #222222;
}

.information-aria .singleContent .media .media-right {
  padding-left: 0px;
}

@media (min-width: 768px) {
  .information-aria .singleContent .media .media-right {
    padding-left: 10px;
  }
}

.information-aria .singleContent .media .media-right .days {
  background-color: #f2f2f2;
  width: 80px;
  height: 80px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  text-transform: uppercase;
  color: #005fae;
  font-size: 16px;
  font-weight: 700;
}

.location-aria {
  margin-top: 65px;
  margin-bottom: 65px;
}

.singleCartSidebar .panel-heading {
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .singleCartSidebar .panel-heading {
    font-size: 26px;
    line-height: 40px;
  }
}

.singleCartSidebar .control-label {
  padding-top: 15px;
  font-weight: 400;
  color: #222222;
}

.singleCartSidebar .datepickerWrap .ed-datepicker {
  border-bottom: none;
  border: 1px solid #dedede;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 0 10px;
  margin-bottom: 0;
}

.singleCartSidebar .datepickerWrap .searchFilter .sbHolder {
  border-bottom: none;
  border: 1px solid #dedede;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .singleCartSidebar .datepickerWrap .searchFilter .sbHolder {
    margin: 0;
  }
}

.singleCartSidebar .datepickerWrap .searchFilter .sbHolder .sbSelector {
  left: 10px;
  text-align: left;
}

.singleCartSidebar .datepickerWrap .searchFilter .sbHolder .sbToggle {
  right: 10px;
  top: 18px;
}

.singleCartSidebar .datepickerWrap .searchFilter .sbHolder .sbOptions a {
  text-align: left;
  padding: 11px 5px;
}

.singleCartSidebar .datepickerWrap .count-input {
  position: relative;
  width: 140px;
}

@media (min-width: 768px) {
  .singleCartSidebar .datepickerWrap .count-input {
    margin: auto;
  }
}

@media (min-width: 992px) {
  .singleCartSidebar .datepickerWrap .count-input {
    margin-left: 0;
    width: 120px;
  }
}

@media (min-width: 1200px) {
  .singleCartSidebar .datepickerWrap .count-input {
    width: 140px;
  }
}

.singleCartSidebar .datepickerWrap .count-input .incr-btn {
  color: #666666;
  display: block;
  font-size: 30px;
  font-weight: 300;
  height: 48px;
  line-height: 45px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 48px;
}

@media (min-width: 992px) {
  .singleCartSidebar .datepickerWrap .count-input .incr-btn {
    width: 40px;
  }
}

@media (min-width: 1200px) {
  .singleCartSidebar .datepickerWrap .count-input .incr-btn {
    width: 48px;
  }
}

.singleCartSidebar .datepickerWrap .count-input .incr-btn:first-child {
  left: 0;
  right: auto;
  border-right: 1px solid #eaeaea;
}

.singleCartSidebar .datepickerWrap .count-input .incr-btn:last-child {
  border-left: 1px solid #eaeaea;
}

.singleCartSidebar .datepickerWrap .count-input input {
  -moz-appearance: none;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #eaeaea;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 48px;
  font-weight: 700;
  color: #666666;
  text-align: center;
  width: 100%;
}

.singleCartSidebar .price {
  padding-top: 15px;
  text-align: center;
}

.singleCartSidebar .totalCost {
  border-top: 1px solid;
  border-bottom: 1px solid;
  overflow: hidden;
  padding: 15px 0;
  border-color: #a7a7a7;
}

.singleCartSidebar .totalCost .totalCostLeft {
  padding: 0;
}

.singleCartSidebar .totalCost .totalCostLeft p {
  margin: 0;
  color: #222222;
  font-size: 18px;
  line-height: 40px;
  text-transform: uppercase;
}

.singleCartSidebar .totalCost .totalCostRight {
  text-align: right;
  color: #005fae;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  padding-right: 5px;
}

@media (min-width: 320px) {
  .singleCartSidebar .form-horizontal .form-group .control-label {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .singleCartSidebar .form-horizontal .form-group .control-label {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .singleCartSidebar .form-horizontal .form-group .control-label {
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 320px) {
  .singleCartSidebar .form-horizontal .form-group .datepickerWrap {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .singleCartSidebar .form-horizontal .form-group .datepickerWrap {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .singleCartSidebar .form-horizontal .form-group .datepickerWrap {
    text-align: right;
    margin-bottom: 10px;
  }
}

.singleCartSidebar .form-horizontal .form-group .datepickerWrap .ed-datepicker input {
  color: #666;
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
}

.singleCartSidebar .form-horizontal .buttonTransparent, .singleCartSidebar .form-horizontal .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .singleCartSidebar .form-horizontal a, .singleCartSidebar .form-horizontal .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .singleCartSidebar .form-horizontal a, .singleCartSidebar .form-horizontal .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .singleCartSidebar .form-horizontal a {
  height: 50px;
  line-height: 50px;
  margin-top: 30px;
}

.singleCartSidebar .form-horizontal .buttonTransparent .fa-angle-right, .singleCartSidebar .form-horizontal .paginationCenter .pagination li:first-child a .fa-angle-right, .paginationCenter .pagination li:first-child .singleCartSidebar .form-horizontal a .fa-angle-right, .singleCartSidebar .form-horizontal .paginationCenter .pagination li:last-child a .fa-angle-right, .paginationCenter .pagination li:last-child .singleCartSidebar .form-horizontal a .fa-angle-right, .singleCartSidebar .form-horizontal .isotopeFilters ul.filter > li a .fa-angle-right, .isotopeFilters ul.filter > li .singleCartSidebar .form-horizontal a .fa-angle-right {
  margin-left: 20px;
  font-size: 18px;
}

/* ======== HOME CITY PAGE ================*/
.sectionTitleHomeCity {
  text-align: center;
  margin-bottom: 80px;
  font-family: Montserrat, sans-serif;
}

.sectionTitleHomeCity h2 {
  font-size: 30px;
  font-weight: 700;
  color: #222222;
}

.sectionTitleHomeCity h2 span {
  color: #005fae;
}

.sectionTitleHomeCity p {
  font-size: 22px;
  font-weight: 300;
  color: #8b8b8b;
  margin-top: 20px;
}

.sectionTitleHomeCity2 {
  text-align: center;
  margin-bottom: 50px;
}

.sectionTitleHomeCity2 h2 {
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
}

.sectionTitleHomeCity2 h2 span {
  color: #005fae;
}

.sectionTitleHomeCity2 p {
  font-size: 18px;
  color: #ffffff;
  line-height: 26px;
}

.homeCitybtn {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.homeCitybtn .buttonTransparent, .homeCitybtn .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .homeCitybtn a, .homeCitybtn .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .homeCitybtn a, .homeCitybtn .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .homeCitybtn a {
  height: 46px;
  line-height: 46px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.mostPopulerToure .thumbnail .caption h4 p {
  font-family: 'Montserrat',sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.mostPopulerToure .thumbnail .caption .detailsInfo .detailsBtn li a {
  font-size: 13px;
  padding: 0 10px;
}

.amazingHtoleSection {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0;
}

@media (min-width: 768px) {
  .amazingHtoleSection {
    height: 460px;
  }
}

@media (min-width: 992px) {
  .amazingHtoleSection {
    height: 386px;
  }
}

.amazingHtoleSection:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  content: '';
}

.topHotelSection .thumbnail {
  background-color: #bebebe;
}

.topHotelSection .thumbnail .caption h4 a:hover {
  color: #222222 !important;
  text-decoration: none;
  cursor: default;
}

.topHotelSection .thumbnail .caption .detailsInfo .detailsBtn li a {
  font-size: 13px;
  padding: 0 10px;
}

.topHotelSection .thumbnail:hover {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.foodDrinkSection .foodLeftImg {
  display: none;
}

@media (min-width: 768px) {
  .foodDrinkSection .foodLeftImg {
    display: block;
    overflow: hidden;
    height: 100%;
  }
}

.foodDrinkSection .foodLeftImg .foodImage {
  height: 100%;
}

.foodDrinkSection .foodLeftImg .homepageAboutUsImage {
  background: url("../img/citybike/about-us.jpg") no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.foodDrinkSection .foodDrinkContent {
  padding: 95px 30px 80px 30px;
}

@media (min-width: 768px) {
  .foodDrinkSection .foodDrinkContent {
    padding: 95px 0 72px 30px;
  }
}

.foodDrinkSection .foodDrinkContent .foodDrinkHeader {
  margin-bottom: 35px;
}

.foodDrinkSection .foodDrinkContent .foodDrinkHeader h2 {
  font-size: 26px;
  font-weight: 679px;
  color: #222222;
}

.foodDrinkSection .foodDrinkContent .foodDrinkHeader h2 span {
  color: #005fae;
}

.foodDrinkSection .foodDrinkContent .FoodDrinkList .item-list {
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.foodDrinkSection .foodDrinkContent .FoodDrinkList .item-list h4 {
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.foodDrinkSection .foodDrinkContent .FoodDrinkList .item-list a {
  font-size: 13px;
  color: #969696;
  text-transform: uppercase;
}

.foodDrinkSection .foodDrinkContent .FoodDrinkList .item-list span {
  margin-top: -18px;
  border: 1px solid #dedede;
  padding: 5px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
}

.sightInCity .sightContent {
  border-bottom: 1px solid #dedede;
  padding-top: 25px;
  padding-bottom: 25px;
}

.sightInCity .sightContent .media .media-left img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.sightInCity .sightContent .media .media-body .media-heading {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 700;
  color: #222222;
}

@media (min-width: 768px) {
  .sightInCity .sightContent .media .media-body .media-heading {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .sightInCity .sightContent .media .media-body .media-heading {
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .sightInCity .sightContent .media .media-body .media-heading {
    margin-top: 20px;
  }
}

.sightInCity .sightContent .media .media-body a {
  font-size: 13px;
  color: #969696;
  text-transform: uppercase;
}

.sightInCity .sightContent .media .media-body a:focus {
  text-decoration: none;
}

.exclusiveDaySection {
  position: relative;
  height: auto;
  background-position: center;
  background-size: cover;
}

.exclusiveDaySection:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  content: '';
}

.exclusiveDaySection .homeCitybtn .buttonCustomPrimary {
  padding: 0px 20px;
}

.exclusiveDaySection .homeCitybtn .buttonCustomPrimary:hover {
  background-color: #fff;
  color: #005fae !important;
}

.planToureCitySection .planToureContent {
  background-color: #fff;
  padding: 70px 35px;
}

.planToureCitySection .planToureContent .icon {
  width: 120px;
  height: 120px;
  border: 1px solid #e5e5e5;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.planToureCitySection .planToureContent .icon i {
  color: #005fae;
  font-size: 50px;
  line-height: 120px;
}

.planToureCitySection .planToureContent .content h4 {
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 25px;
}

.planToureCitySection .planToureContent .content a {
  color: #969696;
  text-decoration: underline;
  font-size: 13px;
  text-transform: uppercase;
}

.planToureCitySection .planToureContent .content a:hover {
  color: #005fae;
}

.photoGallerySection .photoGallery .slick-carousel .slick-prev {
  left: 0;
}

@media (min-width: 768px) {
  .photoGallerySection .photoGallery .slick-carousel .slick-prev {
    left: -60px;
  }
}

@media (min-width: 992px) {
  .photoGallerySection .photoGallery .slick-carousel .slick-prev {
    left: -80px;
  }
}

.photoGallerySection .photoGallery .slick-carousel .slick-prev:before {
  content: "\f053";
  font-family: 'FontAwesome';
  color: #a5a5a5;
  font-size: 25px;
  display: none;
}

@media (min-width: 768px) {
  .photoGallerySection .photoGallery .slick-carousel .slick-prev:before {
    display: block;
  }
}

.photoGallerySection .photoGallery .slick-carousel .slick-next {
  right: 0px;
  display: none;
}

@media (min-width: 768px) {
  .photoGallerySection .photoGallery .slick-carousel .slick-next {
    right: -60px;
  }
}

@media (min-width: 992px) {
  .photoGallerySection .photoGallery .slick-carousel .slick-next {
    right: -80px;
  }
}

.photoGallerySection .photoGallery .slick-carousel .slick-next:before {
  content: "\f054";
  font-family: 'FontAwesome';
  color: #a5a5a5;
  font-size: 25px;
  display: none;
}

@media (min-width: 768px) {
  .photoGallerySection .photoGallery .slick-carousel .slick-next:before {
    display: block;
  }
}

.photoGallerySection .photoGallery .slick-carousel .singlItem {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  margin: 0;
  z-index: 0 !important;
}

@media (min-width: 768px) {
  .photoGallerySection .photoGallery .slick-carousel .singlItem {
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.3;
    margin: 0px -100px;
    transition: transform 0.4s ease;
  }
}

.photoGallerySection .photoGallery .slick-carousel .singlItem .singleImg img {
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .photoGallerySection .photoGallery .slick-carousel .singlItem .singleImg img {
    width: 525px;
  }
}

@media (min-width: 992px) {
  .photoGallerySection .photoGallery .slick-carousel .singlItem .singleImg img {
    width: 725px;
  }
}

@media (min-width: 1200px) {
  .photoGallerySection .photoGallery .slick-carousel .singlItem .singleImg img {
    width: auto;
  }
}

.photoGallerySection .photoGallery .slick-carousel .singlItem.slick-active {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  position: relative;
  opacity: 1;
  z-index: 1 !important;
}

@media (min-width: 768px) {
  .photoGallerySection .photoGallery .slick-carousel .singlItem.slick-active .singleImg img {
    border: 5px solid #ffffff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
}

.travelGuideSection .travelGuideImg {
  position: relative;
}

.travelGuideSection .travelGuideImg img {
  width: 100%;
}

.travelGuideSection .travelGuideImg span {
  position: absolute;
  width: 105px;
  height: 105px;
  background: #fff;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  line-height: 105px;
  top: -46px;
  right: 15px;
  color: #005fae;
}

.travelGuideSection .sectionTitleHomeCity {
  padding-top: 100px;
}

@media (min-width: 768px) {
  .travelGuideSection .sectionTitleHomeCity {
    text-align: left;
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .travelGuideSection .sectionTitleHomeCity {
    padding-top: 50px;
  }
}

@media (min-width: 1200px) {
  .travelGuideSection .sectionTitleHomeCity {
    padding-top: 100px;
  }
}

.travelGuideSection .sectionTitleHomeCity h2 {
  margin-bottom: 20px;
}

.travelGuideSection .sectionTitleHomeCity h4 {
  font-weight: 400;
  color: #969696;
}

.travelGuideSection .sectionTitleHomeCity h4 span {
  color: #005fae;
}

.travelGuideSection .travelGuideContent {
  text-align: center;
}

@media (min-width: 768px) {
  .travelGuideSection .travelGuideContent {
    text-align: left;
  }
}

.travelGuideSection .travelGuideContent .buttonCustomPrimary {
  padding: 0 15px;
  margin-top: 50px;
}

.travelGuideSection .travelGuideContent .buttonCustomPrimary:hover {
  background-color: transparent;
  color: #005fae !important;
  border: 1px solid #005fae;
}

.travelTipsSection .travelTipsImg {
  overflow: hidden;
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .travelTipsSection .travelTipsImg {
    margin-top: 0;
  }
}

.travelTipsSection .travelTipsImg img {
  width: 100%;
}

@media (min-width: 992px) {
  .travelTipsSection .travelTipsImg img {
    width: auto;
  }
}

.travelTipsSection .travelTipsContent {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .travelTipsSection .travelTipsContent {
    margin-top: 65px;
  }
}

@media (min-width: 1200px) {
  .travelTipsSection .travelTipsContent {
    margin-top: 30px;
  }
}

.travelTipsSection .travelTipsContent h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #222222;
  margin-bottom: 10px;
}

.travelTipsSection .travelTipsContent .meta-content {
  margin-bottom: 25px;
}

.travelTipsSection .travelTipsContent .meta-content a {
  margin-right: 15px;
  color: #969696;
  font-size: 14px;
  line-height: 24px;
}

.travelTipsSection .travelTipsContent .meta-content a i {
  margin-right: 10px;
  color: #005fae;
}

.travelTipsSection .travelTipsContent .readMoreBtn {
  text-transform: uppercase;
  text-decoration: underline;
  color: #969696;
  font-size: 14px;
}

.travelTipsSection .travelTipsContent .readMoreBtn:hover {
  color: #005fae;
}

.travelTipsSection .buttonCustomPrimary {
  background-color: #fff;
  color: #969696 !important;
  border: 1px solid #dedede;
  font-size: 15px;
  padding: 0 30px;
  margin-top: 20px;
}

.travelTipsSection .buttonCustomPrimary:hover {
  background-color: #005fae;
  color: #fff !important;
}

.subscribSection {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

@media (min-width: 768px) {
  .subscribSection {
    height: 460px;
  }
}

@media (min-width: 992px) {
  .subscribSection {
    height: 386px;
  }
}

.subscribSection:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(211, 115, 37, 0.8);
  content: '';
}

@media (min-width: 768px) {
  .subscribSection .amazingSelebox {
    display: block;
  }
}

/* ===== HOME DESTINATION =====*/
/* SEARCH YOUR DESTINATION */
.searchDestinationSection {
  position: relative;
  top: -150px;
}

@media (min-width: 480px) {
  .searchDestinationSection {
    top: -300px;
  }
}

@media (min-width: 768px) {
  .searchDestinationSection {
    position: absolute;
    top: 130px;
    left: 2%;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection {
    top: 135px;
    left: 7%;
  }
}

@media (min-width: 1200px) {
  .searchDestinationSection {
    top: 185px;
    left: 7%;
  }
}

.searchDestinationSection .headingTitle {
  text-align: center;
}

@media (min-width: 768px) {
  .searchDestinationSection .headingTitle {
    text-align: left;
  }
}

.searchDestinationSection .headingTitle h2 {
  color: #ff891e;
  font-size: 19px;
  line-height: 36px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .searchDestinationSection .headingTitle h2 {
    font-size: 25px;
  }
}

.searchDestinationSection .headingTitle h2 span {
  display: inline-block;
  line-height: 36px;
  color: #fff;
  font-weight: 400;
}

@media (min-width: 992px) {
  .searchDestinationSection .headingTitle h2 span {
    display: block;
    font-size: 32px;
  }
}

.searchDestinationSection .searchDestination {
  border: none;
  background-color: #fff;
  color: #222222 !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.searchDestinationSection .searchDestination .nav-tabs {
  border-bottom: 0;
}

.searchDestinationSection .searchDestination .nav-tabs li {
  border-width: 1px !important;
  border-color: #dedede;
  border-style: solid;
  border-top: 0px;
  margin: 0px !important;
  float: left;
  width: 25%;
  border-right: 0;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li {
    border-right: 0px;
  }
}

.searchDestinationSection .searchDestination .nav-tabs li:first-child {
  border-left: 0;
}

.searchDestinationSection .searchDestination .nav-tabs li:last-child {
  border-right: 0;
}

.searchDestinationSection .searchDestination .nav-tabs li.active {
  border-bottom: 0px;
}

.searchDestinationSection .searchDestination .nav-tabs li.active a {
  color: #005fae;
  position: relative;
  border: 0px solid transparent;
}

.searchDestinationSection .searchDestination .nav-tabs li.active a span {
  color: #222222;
}

.searchDestinationSection .searchDestination .nav-tabs li.active a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #005fae;
  content: '';
}

.searchDestinationSection .searchDestination .nav-tabs li a {
  text-align: center !important;
  border: none;
  margin: 0 !important;
  color: #969696;
  height: 70px;
  padding: 15px 7px;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li a {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .nav-tabs li a {
    height: 90px;
    padding-top: 20px;
  }
}

.searchDestinationSection .searchDestination .nav-tabs li a:hover {
  background-color: transparent;
  border: 0px !important;
}

.searchDestinationSection .searchDestination .nav-tabs li a i {
  display: block;
  font-size: 20px;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li a i {
    font-size: 25px;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .nav-tabs li a i {
    font-size: 30px;
  }
}

.searchDestinationSection .searchDestination .nav-tabs li a span {
  text-transform: uppercase;
  font-size: 12px !important;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li a span {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content {
    margin: 10px 0 30px 0px;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content {
    margin: 30px 0 30px 0px;
  }
}

@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content {
    margin: 30px 0 30px 30px;
  }
}

.searchDestinationSection .searchDestination .tab-content .tab-pane {
  padding: 20px 10px;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane {
    padding: 20px 40px 50px 20px;
  }
}

.searchDestinationSection .searchDestination .tab-content .tab-pane label {
  font-weight: 400;
  color: #222222;
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .marginTop {
  margin-top: 0;
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .marginTop {
    margin-top: 15px;
  }
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
  width: 100%;
  height: 45px;
  border-color: #dedede;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
    width: 150px;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
    width: 200px;
  }
}

@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
    width: 222px;
  }
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .form-control:active, .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control:focus {
  border-color: #dedede;
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
  border-color: #dedede;
  margin-bottom: 15px;
  width: 100%;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
    width: 150px;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
    width: 200px;
  }
}

@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
    width: 220px;
  }
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbToggle {
  background-image: url(../plugins/selectbox/images/arrow-down-2.png);
  background-repeat: no-repeat;
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbSelector {
  color: #969696 !important;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbSelector {
    margin-left: -10px;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbSelector {
    margin-left: 0px;
  }
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbOptions {
  border-color: #dedede;
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate {
  position: relative;
  border: 0;
  margin-top: 0;
  margin-bottom: 15px;
  display: block;
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .form-control {
  border-style: solid;
  border-width: 1px !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 4px 12px 4px 12px;
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .form-control:active, .searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .form-control:focus {
  border-color: #969696;
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .input-group-addon {
  position: absolute;
  top: 10px;
  right: 31px;
}

@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .input-group-addon {
    right: 10px;
  }
}

@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .input-group-addon {
    right: 31px;
  }
}

@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchbtn {
    margin-top: 20px;
  }
}

.searchDestinationSection .searchDestination .tab-content .tab-pane .searchbtn .btn-default {
  background-color: #005fae;
  padding: 14px 27px;
  color: #fff;
}

/*OUR PACKAGES SECTION */
.ourPackagesSection {
  padding-top: 0;
  margin-top: -80px;
}

@media (min-width: 480px) {
  .ourPackagesSection {
    margin-top: -200px;
  }
}

@media (min-width: 768px) {
  .ourPackagesSection {
    padding-top: 100px;
    margin-top: 0px;
  }
}

.ourPackagesSection .ourPackage {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.ourPackagesSection .ourPackage:hover .ourPackageContent {
  bottom: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.ourPackagesSection .ourPackage:hover .ourPackageContent .buttonCustomPrimary {
  visibility: visible;
  opacity: 1;
}

.ourPackagesSection .ourPackage:hover .ourPackageImg {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.ourPackagesSection .ourPackage:hover .ourPackageImg:after {
  opacity: 1;
}

.ourPackagesSection .ourPackage .ourPackageImg {
  height: auto;
  position: relative;
}

.ourPackagesSection .ourPackage .ourPackageImg img {
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.ourPackagesSection .ourPackage .ourPackageImg:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.ourPackagesSection .ourPackage .ourPackageContent {
  position: absolute;
  left: 30px;
  bottom: -30px;
  transition: all 0.4s ease;
}

.ourPackagesSection .ourPackage .ourPackageContent h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ourPackagesSection .ourPackage .ourPackageContent p {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.ourPackagesSection .ourPackage .ourPackageContent p span {
  color: #005fae;
}

.ourPackagesSection .ourPackage .buttonCustomPrimary {
  visibility: hidden;
  opacity: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  font-size: 13px;
}

.nextTravelSection {
  position: relative;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.nextTravelSection:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  content: '';
}

.nextTravelSection .sectionTitleHomeCity2 {
  text-align: left;
}

.nextTravelSection .content .nextTravelContent {
  margin-top: 35px;
}

.nextTravelSection .content .nextTravelContent li {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
}

.nextTravelSection .content .nextTravelContent li i {
  margin-right: 15px;
  margin-left: 1px;
}

.nextTravelSection .content .buttonCustomPrimary {
  width: 140px;
  height: 45px;
  line-height: 45px;
  margin-top: 35px;
}

.videoPlayerSection {
  position: relative;
  background-position: center;
  background-size: cover;
  height: 350px;
}

@media (min-width: 768px) {
  .videoPlayerSection {
    height: 500px;
  }
}

@media (min-width: 768px) {
  .videoPlayerSection {
    height: 550px;
  }
}

.videoPlayerSection:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  content: '';
}

.videoPlayerSection .videoPlayerIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.videoPlayerSection .videoPlayerIcon a {
  display: block;
  text-align: center;
}

.videoPlayerSection .videoPlayerIcon i {
  width: 110px;
  height: 110px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid #fff;
  font-size: 60px;
  color: #fff;
  line-height: 100px;
  padding-left: 10px;
}

.topDestinationSection .destinationContent img {
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.topDestinationSection .destinationContent h5 {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #222222;
  margin-top: 30px;
  margin-bottom: 15px;
}

.packageSAleSection {
  position: relative;
  background-position: center;
  background-size: cover;
  height: 400px;
}

@media (min-width: 768px) {
  .packageSAleSection {
    height: 400px;
  }
}

@media (min-width: 768px) {
  .packageSAleSection {
    height: 400px;
  }
}

.packageSAleSection:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  content: '';
}

.packageSAleSection .sectionTitleHomeCity2 {
  text-align: left;
}

.packageSAleSection .buttonSale .buttonCustomPrimary {
  line-height: 45px;
  font-size: 15px;
  padding: 0 15px;
}

.packageSAleSection .buttonSale .buttonCustomPrimary:hover {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.packageSAleSection .buttonSale .buttonTransparent, .packageSAleSection .buttonSale .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .packageSAleSection .buttonSale a {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  height: auto;
  padding: 4px 20px;
}

@media (min-width: 768px) {
  .packageSAleSection .buttonSale .buttonTransparent, .packageSAleSection .buttonSale .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .packageSAleSection .buttonSale a {
    margin-left: 20px;
  }
}

.packageSAleSection .buttonSale .buttonTransparent:hover, .packageSAleSection .buttonSale .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .packageSAleSection .buttonSale a:hover, .packageSAleSection .buttonSale .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .packageSAleSection .buttonSale a:hover, .packageSAleSection .buttonSale .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .packageSAleSection .buttonSale a:hover {
  background-color: #005fae;
  border: 1px solid #005fae;
}

.travelTipsThumbanail .thumbnail {
  margin-bottom: 50px;
}

.travelTipsThumbanail .thumbnail img {
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.travelTipsThumbanail .thumbnail .caption {
  padding: 0;
}

.travelTipsThumbanail .thumbnail .caption h3 {
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  margin-top: 24px;
  margin-bottom: 10px;
}

.travelTipsThumbanail .thumbnail .caption .meta-content a {
  margin-right: 15px;
  color: #969696;
}

.travelTipsThumbanail .thumbnail .caption .meta-content a i {
  margin-right: 10px;
}

.travelTipsThumbanail .thumbnail .caption p {
  line-height: 24px;
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: 27px;
  color: #8b8b8b;
}

.travelTipsThumbanail .thumbnail .caption .readMoreBtn {
  text-transform: uppercase;
  color: #969696;
}

.travelTipsThumbanail .thumbnail .caption .readMoreBtn:hover {
  color: #005fae;
  text-decoration: underline;
}

.brandSliderSection {
  padding-top: 60px;
  padding-bottom: 60px;
}

.brandSliderSection .brandSlider .slick-prev {
  left: -5px;
}

.brandSliderSection .brandSlider .slick-prev:before {
  font-family: 'FontAwesome';
  content: "\f104";
  color: #a5a5a5;
  font-size: 25px;
}

.brandSliderSection .brandSlider .slick-next {
  right: -5px;
}

.brandSliderSection .brandSlider .slick-next:before {
  content: "\f105";
  font-family: 'FontAwesome';
  color: #a5a5a5;
  font-size: 25px;
}

.brandSliderSection .brandSlider .slick-list .slick-track .slick-slide img {
  margin: 0 auto;
}

.google-maps {
  width: 100%;
  margin: 50px 0 30px;
}

.google-maps iframe {
  width: 100%;
  display: block;
  pointer-events: none;
  position: relative;
  /* IE needs a position other than static */
}

.google-maps iframe.clicked {
  pointer-events: auto;
}

.package__thumbnail-image {
  width: 100%;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.package__thumbnail-image--rental {
  background-size: contain;
}

.thumbnail.deals {
  width: 100%;
  border: 2px solid #005fae;
}

.package__duration, .package__daily {
  border-radius: 17px;
  background-color: #005fae;
  padding: 8px 14px;
  color: #fff;
  margin-top: -50px;
  position: absolute;
  margin-left: 11px;
  margin-right: 11px;
  font-family: 'Montserrat',sans-serif;
  font-size: 14px;
}

.package__duration .glyphicon {
  padding-right: 4px;
}

.package__daily {
  background-color: #d90d22;
  top: 65px;
}

.thumbnail.deals .discountInfo .duration li {
  text-shadow: 1px 1px 2px black;
}

.blog-post table {
  width: 100%;
  margin-bottom: 40px;
}

.blog-post table td {
  padding: 20px;
}

@media (max-width: 992px) {
  .blog-post table td {
    width: 100% !important;
    display: block;
  }
}

.blog-post table tr:nth-child(odd) td {
  background: #fff;
}

.blog-post table tr:nth-child(even) td {
  background: #e5e5e5;
}

.blog-post table tr td:nth-child(2), .blog-post table tr td:nth-child(3) {
  text-align: right;
}

.blog-post table tr td:nth-child(2) {
  color: #005fae;
}

.blog-post table thead td {
  background: transparent !important;
}

.captionTitle {
  color: #222;
}

.captionTitle:hover {
  cursor: pointer;
  color: #d90d22;
  cursor: pointer;
}

.row.bigger-space {
  margin-bottom: 20px;
}

.footer__partners li {
  margin-bottom: 20px;
  color: #c2c2c2;
}

.footer__partners li a {
  color: #c2c2c2;
  text-decoration: underline;
}

.footer__partners li img {
  margin-bottom: 15px;
  display: block;
}

@media (min-width: 1200px) {
  .footer__partners li img {
    float: left;
    margin-right: 35px;
    display: inline-block;
    margin-bottom: 0;
  }
}

.footer__contact li {
  margin-bottom: 15px;
}

.footer__contact li img {
  margin-right: 15px;
}

.package__warning {
  color: #005fae;
  background-color: transparent;
  border-color: #005fae;
  border-left: 4px solid #005fae;
  padding: 15px;
  margin-bottom: 20px;
}

.pageTitleInfo__text {
  color: #fff;
}

.lang-pt .navbar-main .nav li > a {
  font-size: 12px;
}

.lang-fr .navbar-main .nav li > a {
  font-size: 12px;
}

.lang-es .navbar-main .nav li > a {
  font-size: 11px;
}

.pageTitleInfo {
  max-width: 80%;
  margin: 0 auto;
}

.pageTitleInfo__text p {
  color: #fff;
}

.bottom-page-text {
  padding-top: 50px;
  padding-bottom: 50px;
}

.bottom-page-text h2 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.panel-blue {
  background: #005fae;
  color: #fff;
}

.panel-blue-order {
  border-color: #005fae;
}

.panel-blue-order .panel-heading {
  background: #005fae;
  color: #fff;
}

.panel-red {
  background: #d90d22;
  color: #fff;
}

.singleCartSidebar .buttonTransparent, .singleCartSidebar .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .singleCartSidebar a, .singleCartSidebar .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .singleCartSidebar a, .singleCartSidebar .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .singleCartSidebar a {
  background: transparent !important;
}

.singleCartSidebar .buttonTransparent:hover, .singleCartSidebar .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .singleCartSidebar a:hover, .singleCartSidebar .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .singleCartSidebar a:hover, .singleCartSidebar .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .singleCartSidebar a:hover {
  background: #005fae !important;
}

.row-equal {
  display: flex;
  flex-wrap: wrap;
}

.package-detail-text p {
  font-size: 16px;
  line-height: 1.5em;
}

.buttonCustomPrimarySidebar {
  margin-top: 25px;
}

.thumbnail.thumbnail--red.deals {
  border-color: #d90d22;
}

.thumbnail.thumbnail--red.deals .captionTitle a {
  color: #d90d22;
}

.thumbnail.thumbnail--red.deals .package__warning {
  color: #d90d22;
  border-left-color: #d90d22;
}

.thumbnail.thumbnail--red.deals .caption .detailsInfo h5 {
  color: #d90d22;
}

.thumbnail.thumbnail--red.deals .detailsBtn .buttonTransparent:hover, .thumbnail.thumbnail--red.deals .detailsBtn .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .thumbnail.thumbnail--red.deals .detailsBtn a:hover, .thumbnail.thumbnail--red.deals .detailsBtn .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .thumbnail.thumbnail--red.deals .detailsBtn a:hover, .thumbnail.thumbnail--red.deals .detailsBtn .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .thumbnail.thumbnail--red.deals .detailsBtn a:hover {
  background: #d90d22;
  border-color: #d90d22;
}

.thumbnail.thumbnail--orange.deals {
  border-color: #ff891e;
}

.thumbnail.thumbnail--orange.deals .captionTitle a {
  color: #ff891e;
}

.thumbnail.thumbnail--orange.deals .package__warning {
  color: #ff891e;
  border-left-color: #ff891e;
}

.thumbnail.thumbnail--orange.deals .caption .detailsInfo h5 {
  color: #ff891e;
}

.thumbnail.thumbnail--orange.deals .detailsBtn .buttonTransparent:hover, .thumbnail.thumbnail--orange.deals .detailsBtn .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .thumbnail.thumbnail--orange.deals .detailsBtn a:hover, .thumbnail.thumbnail--orange.deals .detailsBtn .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .thumbnail.thumbnail--orange.deals .detailsBtn a:hover, .thumbnail.thumbnail--orange.deals .detailsBtn .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .thumbnail.thumbnail--orange.deals .detailsBtn a:hover {
  background: #ff891e;
  border-color: #ff891e;
}

.thumbnail.thumbnail--green.deals {
  border-color: #00af72;
}

.thumbnail.thumbnail--green.deals .captionTitle a {
  color: #00af72;
}

.thumbnail.thumbnail--green.deals .package__warning {
  color: #00af72;
  border-left-color: #00af72;
}

.thumbnail.thumbnail--green.deals .caption .detailsInfo h5 {
  color: #00af72;
}

.thumbnail.thumbnail--green.deals .detailsBtn .buttonTransparent:hover, .thumbnail.thumbnail--green.deals .detailsBtn .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .thumbnail.thumbnail--green.deals .detailsBtn a:hover, .thumbnail.thumbnail--green.deals .detailsBtn .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .thumbnail.thumbnail--green.deals .detailsBtn a:hover, .thumbnail.thumbnail--green.deals .detailsBtn .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .thumbnail.thumbnail--green.deals .detailsBtn a:hover {
  background: #00af72;
  border-color: #00af72;
}

.payment-review .row {
  margin-bottom: 5px;
  font-size: 14px;
}

.payment-review .row .price-highlight {
  color: #005fae;
  font-weight: bold;
  text-align: right;
}

.payment-review .row.total-price {
  font-size: 22px;
  margin-top: 15px;
}

.payment-row {
  font-size: 18px;
}

@media (min-width: 992px) {
  .foodDrinkSection .static-content-wrapper {
    min-width: 500px;
  }
}

.singleHotel .carousel {
  margin-bottom: 30px;
}

.singleHotel .carousel .carousel-inner {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.singleHotel .carousel .carousel-inner .item img {
  width: 100%;
}

.singleHotel .carousel-indicators {
  display: none;
}

.singleHotel .carousel-control {
  background-image: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.singleHotel .carousel-control .glyphicon-menu-left,
.singleHotel .carousel-control .glyphicon-menu-right,
.singleHotel .carousel-control .icon-next,
.singleHotel .carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  margin-top: -40px;
  z-index: 5;
  width: 75px;
  height: 75px;
  line-height: 75px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.35);
}

@media (max-width: 767px) {
  .singleHotel .carousel-control .glyphicon-menu-left,
  .singleHotel .carousel-control .glyphicon-menu-right,
  .singleHotel .carousel-control .icon-next,
  .singleHotel .carousel-control .icon-prev {
    display: none;
  }
}

@media (min-width: 768px) {
  .singleHotel .carousel-control .glyphicon-menu-left,
  .singleHotel .carousel-control .glyphicon-menu-right,
  .singleHotel .carousel-control .icon-next,
  .singleHotel .carousel-control .icon-prev {
    display: inline-block;
  }
}

.singleHotel .carousel-control .icon-prev,
.singleHotel .carousel-control .glyphicon-menu-left {
  margin-left: 0;
  left: 20px;
}

.singleHotel .carousel-control .icon-next,
.singleHotel .carousel-control .glyphicon-menu-right {
  right: 20px;
  margin-right: 0;
}

.rooms-types-aria {
  margin-top: 40px;
}

.rooms-types-aria .roomCategory .roomAdvantage {
  margin-bottom: 30px;
}

@media (min-width: 320px) {
  .rooms-types-aria .roomCategory .roomAdvantage {
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .rooms-types-aria .roomCategory .roomAdvantage {
    text-align: left;
  }
}

.rooms-types-aria .roomCategory .roomAdvantage li {
  display: inline-block;
  margin-right: 20px;
}

.rooms-types-aria .roomCategory .roomAdvantage li a {
  color: #5f5f5f;
  display: block;
  font-size: 14px;
}

.rooms-types-aria .roomCategory .roomAdvantage li a:hover {
  color: #005fae;
}

.rooms-types-aria .roomCategory .roomAdvantage li a img {
  margin-right: 7px;
}

.rooms-types-aria .roomCategory .roomRate {
  font-weight: 700;
  font-size: 16px;
}

@media (min-width: 320px) {
  .rooms-types-aria .roomCategory .roomRate {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .rooms-types-aria .roomCategory .roomRate {
    text-align: right;
  }
}

.rooms-types-aria .roomCategory .roomRate span {
  color: #005fae;
}

.rooms-types-aria .roomCategory .roomGellary {
  display: flex;
  flex-wrap: wrap;
}

.rooms-types-aria .roomCategory .roomGellary .single-item {
  flex-basis: 133px;
}

@media (min-width: 768px) {
  .rooms-types-aria .roomCategory .roomGellary .single-item {
    flex-basis: 156px;
  }
}

@media (min-width: 992px) {
  .rooms-types-aria .roomCategory .roomGellary .single-item {
    flex-basis: 123px;
  }
}

@media (min-width: 1200px) {
  .rooms-types-aria .roomCategory .roomGellary .single-item {
    flex-basis: 149px;
  }
}

.rooms-types-aria .roomCategory .roomGellary .single-item img {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 3px;
}

.rooms-types-aria .roomCategory .roomGellary .single-item img:last-child {
  padding: 3px 0 3px 3px;
}

.rooms-types-aria .roomCategory .roomGellary .single-item img:first-child {
  padding: 3px 3px 3px 0;
}

.singleCartSidebar {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .singleCartSidebar {
    margin-top: 0px;
  }
}

.hotelListSidebar {
  border: 1px solid #e5e5e5;
  border-top: none;
  margin-bottom: 30px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #ffffff;
}

.hotelListSidebar .panel-heading-title {
  background: #fff;
  padding: 30px 20px 12px 20px;
  border-top: 2px solid #005fae;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.hotelListSidebar .panel-heading-title h3 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.hotelListSidebar .panel {
  box-shadow: none;
}

.hotelListSidebar .panel-heading {
  margin-bottom: 20px;
}

.hotelListSidebar .panel-heading .panel-title a {
  font-size: 13px;
  color: #969696;
  font-weight: 700;
  display: block;
}

.hotelListSidebar .panel-heading .panel-title a:focus {
  text-decoration: none;
}

.hotelListSidebar .panel-heading .panel-title i {
  margin-right: 10px;
}

.hotelListSidebar .panel-body {
  border-top: 0px !important;
}

.hotelListSidebar .panel-body .check-box-list {
  position: relative;
}

.hotelListSidebar .panel-body .check-box-list:before {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #dedede;
  content: '';
  left: 0;
  top: -36px;
}

.hotelListSidebar .panel-body .check-box-list .form-check label i {
  color: #fec701;
}

.hotelListSidebar .panel-body .check-box-list .form-check-input {
  margin-left: 4px;
  margin-top: 6px;
  padding: 5px;
  opacity: 0;
  display: none;
}

.hotelListSidebar .panel-body .check-box-list .form-check-label {
  padding-left: 0px;
  margin-bottom: 10px;
  font-size: 13px;
  cursor: default;
  color: #5f5f5f;
  font-weight: 400;
  width: 100%;
}

.hotelListSidebar .panel-body .check-box-list .checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: #ffffff;
  border: 1px solid #dedede;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  padding: 0;
  margin-right: 10px;
  text-align: center;
  line-height: 22px;
}

.hotelListSidebar .panel-body .check-box-list .checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #ffffff;
  background: #005fae;
  border: 0px solid #005fae;
  font-size: 10px;
  font-weight: normal;
}

.hotelListSidebar .control-label {
  padding: 12px 0 0 0;
  color: #969696;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  text-align: left;
}

@media (min-width: 768px) {
  .hotelListSidebar .control-label {
    text-align: center;
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .hotelListSidebar .control-label {
    text-align: left;
    margin-bottom: 0;
  }
}

.hotelListSidebar .datepickerWrap {
  padding: 0;
}

@media (min-width: 1200px) {
  .hotelListSidebar .datepickerWrap {
    padding: 0 15px;
  }
}

.hotelListSidebar .datepickerWrap .count-input {
  position: relative;
  width: 135px;
}

@media (min-width: 768px) {
  .hotelListSidebar .datepickerWrap .count-input {
    margin: auto;
  }
}

@media (min-width: 992px) {
  .hotelListSidebar .datepickerWrap .count-input {
    margin-left: 0;
    width: 120px;
  }
}

@media (min-width: 1200px) {
  .hotelListSidebar .datepickerWrap .count-input {
    margin-left: 0;
    width: 137px;
  }
}

.hotelListSidebar .datepickerWrap .count-input .incr-btn {
  color: #666666;
  display: block;
  font-size: 30px;
  font-weight: 300;
  height: 40px;
  line-height: 37px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 48px;
}

@media (min-width: 992px) {
  .hotelListSidebar .datepickerWrap .count-input .incr-btn {
    width: 40px;
  }
}

@media (min-width: 1200px) {
  .hotelListSidebar .datepickerWrap .count-input .incr-btn {
    width: 48px;
  }
}

.hotelListSidebar .datepickerWrap .count-input .incr-btn:first-child {
  left: 0;
  right: auto;
  border-right: 1px solid #eaeaea;
}

.hotelListSidebar .datepickerWrap .count-input .incr-btn:last-child {
  border-left: 1px solid #eaeaea;
}

.hotelListSidebar .datepickerWrap .count-input input {
  -moz-appearance: none;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #eaeaea;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 40px;
  font-weight: 700;
  color: #666666;
  text-align: center;
  width: 100%;
}

.sort-by-section {
  margin-bottom: 30px;
}

.sort-by-section .sort-select-box {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .sort-by-section .sort-select-box {
    margin-bottom: 0;
  }
}

.sort-by-section .sort-select-box .sbHolder {
  width: 100%;
  height: 45px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border-color: #dedede;
}

@media (min-width: 768px) {
  .sort-by-section .sort-select-box .sbHolder {
    width: 270px;
  }
}

.sort-by-section .sort-select-box .sbHolder .sbToggle {
  top: 20px;
  background: url(../plugins/selectbox/images/light-drop.png) no-repeat;
}

.sort-by-section .sort-select-box .sbHolder .sbSelector {
  line-height: 45px;
  color: #666666 !important;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
  width: 100%;
}

.sort-by-section .sort-select-box .sbHolder .sbOptions {
  width: 100% !important;
  right: 0 !important;
  top: 44px !important;
  border-color: #dedede;
  z-index: 1100;
}

.sort-by-section .sort-select-box .sbHolder .sbOptions li a {
  color: #666666 !important;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
  font-size: 13px;
}

.sort-by-section .sort-select-box .sbHolder .sbOptions li a:hover {
  color: #005fae !important;
}

.sort-by-section .icon-right {
  float: unset !important;
}

@media (min-width: 768px) {
  .sort-by-section .icon-right {
    float: right !important;
  }
}

.sort-by-section .icon-right .icon {
  display: inline-block;
  width: 47px;
  height: 47px;
  margin-left: 10px;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .sort-by-section .icon-right .icon {
    margin-top: 0;
    width: 40px;
    height: 40px;
  }
}

.sort-by-section .icon-right .icon a {
  display: block;
  color: #969696;
  text-align: center;
  line-height: 47px;
  font-size: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
}

@media (min-width: 768px) {
  .sort-by-section .icon-right .icon a {
    line-height: 40px;
  }
}

.sort-by-section .icon-right .icon.active a {
  background-color: #005fae;
  color: #ffffff;
}

.hotelsList .media-left {
  padding-right: 0 !important;
}

.hotelsList .media-body {
  padding: 30px 30px 28px 20px;
  border: 1px solid #e5e5e5;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.hotelsList .media-body .countryRating span {
  border-right: 0;
  float: unset;
  margin-left: 10px;
}

.hotelsList .media-body .countryRating .rating {
  display: inline-block;
  width: auto;
}

.sidebarPage .packagesList.hotelsList .media-body {
  padding: 30px 30px 20px 20px;
}

/*================= 8. BLOG ==================*/
/*================= 8.1 BLOG POST LIST ITEM ==================*/
.blogPostListItem {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #aeaeae;
}

.blogPostListItem .media-body {
  padding: 20px;
}

.blogPostListItem .media-left img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/*================= 8.2 BLOG POST SIDEBAR ==================*/
@media (max-width: 767px) {
  .blogSidebar .media-left {
    width: auto;
  }
}

@media (min-width: 768px) {
  .blogSidebar .media-left {
    width: 50%;
  }
}

.blogSidebar .media-left img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.blogSidebar .panel {
  border-color: #b7b7b7;
  box-shadow: none;
  background-color: #bebebe;
  padding-bottom: 15px;
}

.blogSidebar .panel .panel-heading {
  border: none;
  font-size: 20px;
  color: #222222;
  line-height: 26px;
}

.blogSidebar .panel .panel-body .input-group .form-control {
  box-shadow: none;
  border: none;
}

.blogSidebar .panel .panel-body .input-group .input-group-addon {
  background-color: #005fae;
  box-shadow: none;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.blogSidebar .panel .panel-body .input-group .input-group-addon i {
  color: #ffffff;
}

.blogSidebar .panel .panel-body .list-group {
  margin-bottom: 0;
}

.blogSidebar .panel .panel-body .list-group .list-group-item {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #aeaeae;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  color: #222222;
}

.blogSidebar .panel .panel-body .list-group .list-group-item .badge {
  background-color: transparent;
  color: #222222;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blogSidebar .panel .panel-body .list-group .list-group-item:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  color: #005fae;
}

.blogSidebar .panel .panel-body .list-group .list-group-item:hover .badge {
  color: #005fae;
}

.blogSidebar .panel .panel-body .list-group .list-group-item:last-child {
  border: none;
}

.blogSidebar .panel .panel-body .list-inline li {
  width: 33.33333333%;
  float: left;
  padding: 5px;
}

.blogSidebar .panel .panel-body .list-inline li a {
  width: 100%;
  display: block;
}

.blogSidebar .panel .panel-body .list-inline li a img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.tabArea {
  margin-bottom: 20px;
}

.tabArea .nav-tabs {
  border-color: #b7b7b7;
}

.tabArea .nav-tabs > li {
  padding: 0;
}

@media (min-width: 768px) {
  .tabArea .nav-tabs > li {
    display: block;
    width: 100%;
    float: none;
  }
}

@media (min-width: 992px) {
  .tabArea .nav-tabs > li {
    display: table-cell;
    width: 1%;
  }
}

@media (min-width: 1200px) {
  .tabArea .nav-tabs > li {
    padding-left: 4px;
  }
}

.tabArea .nav-tabs > li > a {
  border: 1px solid #b7b7b7;
  color: #222222;
}

@media (min-width: 992px) {
  .tabArea .nav-tabs > li > a {
    padding: 10px 0;
  }
}

@media (min-width: 1200px) {
  .tabArea .nav-tabs > li > a {
    padding: 10px 15px;
  }
}

.tabArea .nav-tabs > li.active a {
  border-color: #b7b7b7;
  background-color: #bebebe;
  color: #222222;
  border-bottom-color: #bebebe;
}

.tabArea .nav-tabs > li:first-child {
  padding-left: 0;
}

.tabArea .nav-tabs > li:last-child {
  padding-right: 0;
}

.tabArea .tab-content {
  background-color: #bebebe;
  border: 1px solid #b7b7b7;
  border-top: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px 16px;
}

.tabArea .tab-content .media {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #aeaeae;
}

.tabArea .tab-content .media.removeMargin {
  margin: 0;
  border: none;
}

.tabArea .tab-content .media .media-left {
  width: 35%;
  padding-right: 8px;
}

.tabArea .tab-content .media .media-left img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.tabArea .tab-content .media .media-body .media-heading {
  color: #222222;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
  float: left;
  text-transform: capitalize;
  font-weight: 400;
}

.tabArea .tab-content .media .media-body .media-heading:hover {
  color: #005fae;
}

.tabArea .tab-content .media .media-body p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  display: inline-block;
}

.tabArea .tab-content .media .media-body p i {
  margin-right: 4px;
}

/*================= 8.3 BLOG SINGLE POST ==================*/
.blogSinglePost {
  border: none;
  padding: 0;
}

.blogSinglePost img {
  margin-bottom: 25px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.blogSinglePost .caption h2 {
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 400;
}

.blogSinglePost .caption p {
  margin-bottom: 20px;
}

.blogSinglePost .well {
  background-color: #bebebe;
  border: none;
  box-shadow: none;
  float: left;
  display: block;
  width: 100%;
  margin-bottom: 60px;
}

.blogSinglePost .well span {
  color: #222222;
  font-weight: 700;
  font-size: 15px;
  float: left;
}

.blogSinglePost .well .list-inline {
  float: right;
}

.blogSinglePost .well .list-inline li a {
  font-size: 14px;
  color: #5f5f5f;
}

.blogSinglePost .well .list-inline li a:hover {
  color: #005fae;
}

.imageBg {
  background-image: url("../img/blog/blog-single-02.png");
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center top;
  color: #ffffff;
  height: 208px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.imageBg .imageBgTable {
  display: table;
  height: 208px;
  width: 100%;
}

.imageBg .imageBgTable .imageBgTableInner {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
}

.imageBg .imageBgTable .imageBgTableInner blockquote {
  padding: 0 50px;
  border: none;
  margin-bottom: 0;
}

.imageBg .imageBgTable .imageBgTableInner blockquote p {
  color: #ffffff;
  font-size: 18px;
  line-height: 26px;
}

.imageBg .imageBgTable .imageBgTableInner blockquote footer {
  color: #ffffff;
  font-size: 14px;
  line-height: 26px;
}

.commentsArea h3 {
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 30px;
  font-size: 20px;
}

.commentsArea .media {
  margin: 0 0 50px 0;
}

.commentsArea .media .media-left {
  padding-right: 20px;
  width: auto;
  display: inline-block;
  float: left;
}

.commentsArea .media .media-body .media {
  margin: 50px 0 0 0;
}

.commentsArea .media .media-body h4, .commentsArea .media .media-body p {
  text-transform: capitalize;
  font-size: 14px;
  margin: 0 0 14px 0;
  display: block;
}

.commentsArea .media .media-body h4 span, .commentsArea .media .media-body p span {
  color: #5f5f5f;
  font-weight: 400;
}

.commentsArea .media .media-body h4 span i, .commentsArea .media .media-body p span i {
  margin-right: 14px;
}

.commentsArea .media .media-body p {
  color: #5f5f5f;
  line-height: 22px;
}

.commentsArea .media .media-body .btn-link {
  padding: 0;
  color: #005fae;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  font-size: 14px;
}

.commentsArea .media .media-body .btn-link:hover {
  color: #005fae;
  text-decoration: none;
}

.blog-img {
  max-width: 414px;
  max-height: 269px;
  height: 269px;
  border-radius: 4px;
  background-size: cover;
  background-position: center center;
}

/*================= 9. 404 NOT FOUND PAGE ==================*/
/*=== 9.1 404 PAGE BACKGROUND ===*/
.notFoundBg {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 840px;
  overflow: hidden;
  background: url(../img/not-found/not-found-bg.jpg) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.notFoundContent {
  min-height: 390px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 767px) {
  .notFoundContent {
    min-width: 100%;
  }
}

@media (min-width: 768px) {
  .notFoundContent {
    min-width: 585px;
  }
}

.notFoundContent img {
  margin-bottom: 75px;
}

.notFoundContent h4 {
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 75px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .notFoundContent h4 {
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .notFoundContent h4 {
    padding: 0;
  }
}

.notFoundContent p {
  color: #ffffff;
  margin-bottom: 20px;
}

.notFoundContent .input-group {
  margin: 0 auto;
}

@media (max-width: 767px) {
  .notFoundContent .input-group {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .notFoundContent .input-group {
    width: 350px;
  }
}

.notFoundContent .input-group .form-control {
  height: 45px;
  box-shadow: none;
  background-color: transparent;
  border-color: rgba(97, 99, 99, 0.25);
  color: #ffffff;
}

.notFoundContent .input-group .input-group-addon {
  height: 45px;
  background-color: #005fae;
  color: #ffffff;
  border-color: #005fae;
  padding: 6px 30px;
}

/*================= 10 CONTACT US ==================*/
/*=== 10.1 CONTACT INFO ===*/
.contactInfo h2 {
  font-size: 26px;
  color: #222222;
  margin-bottom: 13px;
}

.contactInfo p {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .contactInfo p {
    font-size: 10px;
    line-height: 16px;
  }
}

@media (min-width: 768px) {
  .contactInfo p {
    font-size: 14px;
    line-height: 21px;
  }
}

.contactInfo .media {
  margin-bottom: 20px;
}

.contactInfo .media .media-left {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background-color: #005fae;
  color: #ffffff;
  padding-right: 0;
  margin-right: 25px;
  font-size: 40px;
  float: left;
}

.contactInfo .media p {
  margin-bottom: 0;
}

.contactInfo .media p a {
  color: #5f5f5f;
}

.contactInfo .media .media-body h2, .contactInfo .media .media-body h4 {
  font-weight: 400;
  font-size: 18px;
}

/*=== 10.2 MAP AREA SECTION ===*/
.mapArea {
  width: 100%;
  display: block;
}

.mapArea iframe {
  width: 100%;
}

.contactInfo p.contact-bigger-text {
  font-size: 17px;
  color: #005fae;
}

.contactInfo p.contact-bigger-text a {
  color: #EA2E36;
}

/*================= 11 ABOUT US ==================*/
/*=== 11.1 ABOUT US TITLE ===*/
.aboutTitle {
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-bottom: 1px solid #aeaeae;
}

.aboutTitle h4 {
  text-transform: capitalize;
  color: #5f5f5f;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
}

.aboutTitle p {
  text-align: center;
}

.aboutItem .media {
  margin-bottom: 50px;
}

.aboutItem .media .media-left {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #262a2e;
  padding-right: 0;
  margin-right: 20px;
  text-align: center;
}

.aboutItem .media .media-left i {
  font-size: 35px;
  line-height: 60px;
  color: #ffffff;
}

.aboutItem .media .media-body h4 {
  text-align: left;
  color: #222222;
  margin-bottom: 13px;
  text-transform: capitalize;
}

.aboutItem .media .media-body p {
  text-align: left;
}

/*=== 11.2 ABOUT COMMENTS ===*/
.aboutComments {
  background-image: url("../img/home/promotion/promotion-1.jpg");
  background-attachment: fixed;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 407px;
  padding: 0;
}

.aboutComments .commentsTable {
  display: table;
  height: 407px;
  width: 100%;
}

.aboutComments .commentsTable .commentsTableInner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.aboutComments .commentsTable .commentsTableInner .commentsInfo i {
  padding: 16px 13px;
  background-color: black;
  font-size: 35px;
  color: #ffffff;
  line-height: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 45px;
}

.aboutComments .commentsTable .commentsTableInner .commentsInfo p {
  color: #ffffff;
  margin-bottom: 45px;
}

.aboutComments .commentsTable .commentsTableInner .commentsInfo h5 {
  font-size: 14px;
  line-height: 20px;
  color: #005fae;
  margin: 0;
}

/*=== 11.2 ABOUT TEAM MEMBERS ===*/
.teamMember {
  position: relative;
}

@media (max-width: 767px) {
  .teamMember {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .teamMember {
    margin-bottom: 0;
  }
}

.teamMember img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.teamMember .memberName {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 100;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.teamMember .memberName h4 {
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 0;
}

.teamMember .memberName p {
  margin-bottom: 0;
  color: #ffffff;
}

.teamMember .maskingArea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.teamMember .maskingArea p {
  position: absolute;
  left: 30px;
  bottom: 120px;
  margin-bottom: 0;
  color: #ffffff;
  padding-right: 48px;
}

.teamMember .maskingArea .list-inline {
  position: absolute;
  left: 30px;
  bottom: 54px;
}

@media (min-width: 768px) {
  .teamMember .maskingArea .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .teamMember .maskingArea .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .teamMember .maskingArea .list-inline li {
    padding: 0 5px;
  }
}

.teamMember .maskingArea .list-inline li a {
  display: block;
  text-align: center;
  border: 1px solid #181615;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
}

.teamMember .maskingArea .list-inline li a:hover {
  background-color: #005fae;
  border-color: #005fae;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .teamMember .maskingArea .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .teamMember .maskingArea .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .teamMember .maskingArea .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

.teamMember:hover .memberName {
  bottom: 180px;
}

.teamMember:hover .maskingArea {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

/*================= 12 BOOKING ==================*/
/*=== 12.1 BOOKING PROGRESS BAR ===*/
.progress-wizard {
  margin-top: 40px;
  border-bottom: 1px solid darkgray;
  padding: 0 0 30px 0;
}

.progress-wizard > .progress-wizard-step {
  padding: 0;
  position: relative;
  height: 100px;
}

@media (max-width: 767px) {
  .progress-wizard > .progress-wizard-step {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step {
    margin-bottom: 0;
  }
}

.progress-wizard > .progress-wizard-step > .progress {
  position: relative;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  height: 2px;
  box-shadow: none;
  margin: 20px 0;
}

.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
}

@media (max-width: 767px) {
  .progress-wizard > .progress-wizard-step > .progress > .progress-bar {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress > .progress-bar {
    background: #005fae;
  }
}

.progress-wizard > .progress-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}

.progress-wizard > .progress-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}

.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  width: 0%;
}

.progress-wizard > .progress-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}

.progress-wizard > .progress-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.progress-wizard > .progress-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.progress-wizard > .progress-wizard-step:last-child > .progress {
  width: 50%;
}

@media (max-width: 767px) {
  .progress {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .progress {
    background-color: #a7a7a7;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot {
  width: 203px;
  display: block;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid #005fae;
  padding: 21px 0;
  text-align: center;
  color: #222222;
}

@media (max-width: 767px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot {
    position: relative;
    top: 0;
    left: 0;
    margin: -20px auto 0 auto;
  }
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot {
    position: absolute;
    top: -15px;
    left: 28%;
    margin-top: -15px;
    margin-left: -15px;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot i {
  display: block;
  color: #222222;
  font-size: 20px;
  margin-bottom: 15px;
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.progress-wizard > .progress-wizard-step.disabled > .progress-wizard-dot {
  border-color: #a7a7a7;
  color: #5f5f5f;
}

.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot {
  background-color: #005fae;
  color: #ffffff;
}

.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot i {
  color: #ffffff;
}

/*=== 12.1 BOOKING INFO ===*/
.infoTitle h2 {
  font-weight: 400;
  color: #222222;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 25px;
}

/*=== 12.2 BOOKING FORM ===*/
.bookingForm .form .form-control, .bookingForm .commentsForm .form-control, .bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .form-control, .modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .form-control {
  border: none;
}

.bookingForm .form .form-group label, .bookingForm .commentsForm .form-group label, .bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .form-group label, .modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .form-group label {
  color: #222222;
  font-weight: 400;
}

.bookingForm .form .form-group label.blankLabel, .bookingForm .commentsForm .form-group label.blankLabel, .bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .form-group label.blankLabel, .modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .form-group label.blankLabel {
  height: 16px;
}

.bookingForm .form .checkbox label, .bookingForm .commentsForm .checkbox label, .bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .checkbox label, .modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .checkbox label {
  color: #222222;
  font-weight: 400;
}

.bookingForm .form .checkbox label a, .bookingForm .commentsForm .checkbox label a, .bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .checkbox label a, .modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .checkbox label a {
  color: #005fae;
}

.bookingForm .form .checkbox input[type=checkbox], .bookingForm .commentsForm .checkbox input[type=checkbox], .bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .checkbox input[type=checkbox], .modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .checkbox input[type=checkbox] {
  border-color: #005fae;
}

/*=== 12.3 BOOKING DETAILS ===*/
.bookDetailsInfo {
  border: 2px solid #d90d22;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .bookDetailsInfo {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .bookDetailsInfo {
    margin-bottom: 0;
  }
}

.bookDetailsInfo img {
  width: 100%;
}

.infoArea {
  padding: 20px;
  /*background-color: lighten(desaturate(adjust-hue($primary-color, -28.5333), 100.0000), 40.1961);*/
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.infoArea h3 {
  font-size: 16px;
  color: #222222;
  margin-bottom: 25px;
  text-transform: capitalize;
  font-weight: 400;
}

.infoArea .list-unstyled {
  margin-bottom: 50px;
}

.infoArea .list-unstyled li {
  font-size: 14px;
  color: #222222;
  padding: 15px 0;
  border-bottom: 1px solid #a7a7a7;
}

.infoArea .list-unstyled li i {
  margin-right: 5px;
}

.infoArea .list-unstyled li span {
  color: #5f5f5f;
}

.infoArea h2 {
  font-size: 20px;
  color: #222222;
  font-weight: 400;
}

.infoArea h2 span {
  color: #005fae;
  font-weight: 700;
}

.bookingForm .form-group .form-control {
  background: #fff;
  border: 1px solid #bebebe;
}

.total-price-order-review {
  text-align: right;
  color: #005fae;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  padding-right: 5px;
}

/*=== 12.4 BOOKING CONFIRMED ===*/
.bookingConfirmed .alert {
  position: relative;
  min-height: 90px;
  margin-bottom: 50px;
  background-image: url("../img/booking/alert-check.png");
  background-position: 0 0;
  background-size: 50px 90px;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .bookingConfirmed .alert {
    line-height: 20px;
    padding: 15px 65px 0 60px;
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .bookingConfirmed .alert {
    line-height: 90px;
    padding: 0 0 0 70px;
    font-size: 16px;
  }
}

.bookingConfirmed .alert-dismissable .close, .bookingConfirmed .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 20px;
  color: #613661;
  height: 90px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .bookingConfirmed .alert-dismissable .close, .bookingConfirmed .alert-dismissible .close {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .bookingConfirmed .alert-dismissable .close, .bookingConfirmed .alert-dismissible .close {
    font-size: 14px;
  }
}

.bookingConfirmed .infoTitle h2 {
  font-size: 26px;
}

.confirmDetilas img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.confirmInfo {
  padding: 40px 30px;
}

.confirmInfo .infoTitle h2 {
  font-size: 20px;
}

.confirmInfo .infoTitle h3 {
  font-size: 20px;
}

.confirmInfo p {
  margin-bottom: 40px;
}

.confirmInfo .dl-horizontal dt {
  width: 100px;
  text-align: left;
  color: #222222;
  font-weight: 400;
  margin-bottom: 5px;
  float: left;
}

.confirmInfo .dl-horizontal dt i {
  margin-right: 5px;
}

.confirmInfo .dl-horizontal dd {
  margin: 0 0 5px 100px;
}

.priceTotal h2 {
  text-transform: capitalize;
  color: #222222;
  font-size: 26px;
  font-weight: 400;
}

.priceTotal h2 span {
  color: #005fae;
  font-size: 25px;
}

/*================= 13 COMING SOON ==================*/
/*=== 13.1 COMING SOON BG ===*/
.comingSoonBg {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 840px;
  overflow: hidden;
  background: url(../img/coming-soon/coming-bg.jpg) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.comingTitle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 35px 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .comingTitle {
    padding: 15px 0;
  }
}

@media (min-width: 768px) {
  .comingTitle {
    padding: 35px 0;
  }
}

.comingContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 767px) {
  .comingContent {
    width: 100%;
    min-width: 100%;
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .comingContent {
    width: auto;
    min-width: 585px;
    padding: 0;
  }
}

.comingContent h1 {
  font-weight: 400;
  color: #ffffff;
  font-size: 40px;
}

@media (max-width: 767px) {
  .comingContent h1 {
    font-size: 30px;
    margin: 20px 0 15px 0;
  }
}

@media (min-width: 768px) {
  .comingContent h1 {
    font-size: 40px;
    margin: 20px 0 40px 0;
  }
}

.comingContent p {
  color: #6f6f6f;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .comingContent p {
    font-size: 10px;
    line-height: 15px;
  }
}

@media (min-width: 768px) {
  .comingContent p {
    font-size: 14px;
    line-height: 21px;
  }
}

.comingContent .input-group {
  margin: 0 auto;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .comingContent .input-group {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .comingContent .input-group {
    width: 550px;
  }
}

.comingContent .input-group .form-control {
  height: 45px;
  box-shadow: none;
  background-color: transparent;
  border-color: rgba(97, 99, 99, 0.25);
  color: #ffffff;
}

.comingContent .input-group .input-group-addon {
  height: 45px;
  background-color: #005fae;
  color: #ffffff;
  border-color: #005fae;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .comingContent .input-group .input-group-addon {
    padding: 6px 15px;
  }
}

@media (min-width: 768px) {
  .comingContent .input-group .input-group-addon {
    padding: 6px 30px;
  }
}

@media (min-width: 768px) {
  .comingContent .list-inline li {
    padding: 2px;
  }
}

@media (min-width: 992px) {
  .comingContent .list-inline li {
    padding: 0 2px;
  }
}

@media (min-width: 1200px) {
  .comingContent .list-inline li {
    padding: 0 5px;
  }
}

.comingContent .list-inline li a {
  display: block;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.4);
}

.comingContent .list-inline li a:hover {
  background-color: #005fae;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  color: #ffffff;
}

@media (max-width: 767px) {
  .comingContent .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .comingContent .list-inline li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .comingContent .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}

.count-down {
  padding: 0 0 35px 0;
}

.count-down #simple_timer {
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
}

.count-down #simple_timer .table-cell {
  display: inline-block;
}

@media (min-width: 480px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 992px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 1200px) {
  .count-down #simple_timer .table-cell {
    margin: 0 12px;
  }
}

.count-down #simple_timer .table-cell .tab-val {
  font-weight: 700;
  display: block;
  margin-bottom: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
}

@media (max-width: 767px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 35px;
  }
}

@media (min-width: 1200px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 109px;
    height: 109px;
    line-height: 109px;
    font-size: 45px;
  }
}

.count-down #simple_timer .table-cell .tab-metr {
  color: #6f6f6f;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell .tab-metr {
    font-size: 15px;
  }
}

/*================= 14 PRICING TABLE ==================*/
/*=== 14.1 PRICING TABLE 1 ===*/
.priceTable .panel {
  text-align: center;
  border-color: #a7a7a7;
  background-color: transparent;
}

.priceTable .panel .panel-heading {
  font-size: 24px;
  color: #222222;
  text-transform: uppercase;
  padding: 25px 15px;
  background-color: transparent;
  border-color: #a7a7a7;
}

.priceTable .panel .panel-body {
  background-color: #bebebe;
  border-bottom: 1px solid #a7a7a7;
}

.priceTable .panel .panel-body.changeColor {
  background-color: #b5b5b5;
}

.priceTable .panel .priceCircle {
  width: 131px;
  padding: 35px 0;
  background-color: #ffffff;
  border: 1px solid #a7a7a7;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.priceTable .panel .priceCircle h3 {
  font-size: 32px;
  color: #005fae;
  margin: 0;
  line-height: 45px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.priceTable .panel .priceCircle h3 small {
  display: block;
  color: #5f5f5f;
  font-size: 14px;
  text-transform: lowercase;
}

.priceTable .panel .list-unstyled {
  padding: 40px 0 10px 20px;
}

.priceTable .panel .list-unstyled li {
  text-align: left;
  margin-bottom: 25px;
  color: #5f5f5f;
  font-size: 16px;
}

.priceTable .panel .list-unstyled li i {
  margin-right: 10px;
}

.priceTable .panel .panel-footer {
  padding: 25px 15px;
  background-color: transparent;
  border-color: #a7a7a7;
}

.priceTable .panel .bodyImageBg {
  padding: 0;
  position: relative;
}

.priceTable .panel .bodyImageBg img {
  width: 100%;
}

.priceTable .panel .bodyImageBg .priceCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  padding: 0 0;
  border-radius: 0;
  background-color: transparent;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.priceTable .panel .bodyImageBg .priceCircle h3 {
  font-size: 32px;
  color: #ffffff;
  margin: 0;
  line-height: 45px;
}

.priceTable .panel .bodyImageBg .priceCircle h3 small {
  display: block;
  color: #ffffff;
  font-size: 14px;
  text-transform: lowercase;
}

.priceTable .panel:hover .priceCircle {
  background-color: #005fae;
}

.priceTable .panel:hover .priceCircle h3 {
  color: #ffffff;
}

.priceTable .panel:hover .priceCircle h3 small {
  color: #ffffff;
}

.priceTable .panel:hover .bodyImageBg .priceCircle {
  background-color: transparent;
}

.priceTable .panel:hover .bodyImageBg .priceCircle h3 {
  color: #005fae;
}

/*================= 15 TABS ==================*/
/*=== 15.1 TABS PART ===*/
.tabsPart .nav-tabs {
  text-align: center;
  border: none;
  margin-bottom: 70px;
}

.tabsPart .nav-tabs li {
  float: none;
  display: inline-block;
}

.tabsPart .nav-tabs li a {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  padding: 0 15px;
  color: #969696;
  text-transform: uppercase;
  outline: 0;
  border: 1px solid #a7a7a7;
}

.tabsPart .nav-tabs li a:hover {
  background-color: #005fae;
  color: #ffffff;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.tabsPart .nav-tabs li.active a {
  background-color: #005fae;
  color: #ffffff;
  border: 1px solid #005fae;
}

.tabsPart .tab-content .media img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.tabsPart .media-body {
  padding-left: 20px;
}

.tabsPart .commonInfo h2 {
  margin: 15px 0;
  text-transform: capitalize;
}

.tabsPart .tab-pane .well {
  margin-bottom: 60px;
}

.tabSection .tabArea .media:last-child {
  border: none;
  margin-bottom: 0;
}

.tabSection .tabArea .media-body {
  padding-left: 20px;
}

/*================= 16 ADMIN ==================*/
/*=== 16.1 DASHBOARD MENU ===*/
.dashboardMenu {
  background: #262a2e;
}

.dashboardMenu .navbar {
  margin: 0;
}

.dashboardMenu .navbar .navbar-collapse {
  float: none;
}

@media (min-width: 768px) {
  .dashboardMenu .navbar .navbar-collapse {
    float: left;
  }
}

.dashboardMenu .dashboradNav .navbar-header {
  display: inline-block;
}

.dashboardMenu .dashboradNav .navbar-header .navbar-toggle {
  float: left;
  margin-left: 10px;
  margin-top: 13px;
}

.dashboardMenu .dashboradNav .navbar-header .navbar-toggle .icon-bar {
  background-color: #333;
}

.dashboardMenu .dashboradNav .dashboardNavLeft > li > a {
  border-bottom: 0;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavLeft > li > a {
    color: rgba(255, 255, 255, 0.4) !important;
    padding: 10px 5px 7px 0;
    margin-bottom: 15px;
    margin-top: 11px;
  }
}

@media (min-width: 992px) {
  .dashboardMenu .dashboradNav .dashboardNavLeft > li > a {
    font-size: 14px;
    padding: 10px 17px 7px 0;
  }
}

@media (min-width: 1200px) {
  .dashboardMenu .dashboradNav .dashboardNavLeft > li > a {
    padding: 10px 20px 7px 0;
  }
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavLeft > li > a.active {
    color: #fff !important;
  }
}

.dashboardMenu .dashboradNav .dashboardNavLeft > li > a:after {
  background-color: transparent;
}

.dashboardMenu .dashboradNav .dashboardNavLeft > li > a:hover, .dashboardMenu .dashboradNav .dashboardNavLeft > li > a:focus {
  background-color: transparent;
  color: white !important;
}

.dashboardMenu .dashboradNav .dashboardNavLeft > li > a:active {
  color: #fff;
}

.dashboardMenu .dashboradNav .dashboardNavLeft > li > a i.fa {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavLeft > li > a i.fa {
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .dashboardMenu .dashboradNav .dashboardNavLeft > li > a i.fa {
    margin-right: 10px;
  }
}

.dashboardMenu .dashboradNav .dashboardNavLeft > li.dropdown.singleDrop .dropdown-menu li a {
  padding: 5px 12px;
}

.dashboardMenu .dashboradNav .dashboardNavRight {
  float: right;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop {
  display: inline-block;
  padding: 13px 8px;
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop {
    padding: 20px 8px;
  }
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a {
  position: relative;
  color: #222222;
  border-bottom: 0;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a .notifyNumber {
  color: #ffffff;
  background-color: #005fae;
  position: absolute;
  top: -17px;
  left: 52px;
  padding: 0px 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  z-index: 20;
  font-size: 11px;
  line-height: 1.5;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a:after {
  background-color: transparent;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a:hover {
  background-color: transparent;
  color: #222222;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a:focus {
  background-color: transparent;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a img {
  width: 34px;
  height: 34px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -9px;
  left: 0;
}

@media (min-width: 320px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a img {
    position: static;
  }
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a img {
    position: absolute;
  }
}

@media (min-width: 320px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a .fa {
    margin-left: 44px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
  }
}

@media (min-width: 320px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a .fa-angle-down {
    display: none;
  }
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop a .fa-angle-down {
    display: block;
  }
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu {
  right: -10px;
  left: auto;
  min-width: 228px;
  top: 98%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu {
    min-width: 320px;
  }
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li {
  border-bottom: 1px solid #e5e5e5;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li:last-child {
  border-bottom: 0;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li a {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li a h4 {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 10px 0;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li a h5 {
  font-size: 15px;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li a p {
  font-size: 12px;
  line-height: 20px;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li a .fa {
  top: 20px;
  right: 13px;
  font-size: 15px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  transition: all;
}

@media (min-width: 320px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li a .fa {
    position: relative;
  }
}

@media (min-width: 992px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li a .fa {
    position: absolute;
  }
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li:hover {
  background: #fafafa;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu li:hover .fa {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu:before {
  position: absolute;
  content: "";
  background: #fff;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.08) 1px 2px #CCC;
  -moz-box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.08) 1px 2px #CCC;
  box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.08) 1px 2px #CCC;
  top: -6px;
  right: 16px;
}

@media (min-width: 320px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .dashboardMenu .dashboradNav .dashboardNavRight .NavRight .singleDrop .dropdownMenu:before {
    display: block;
  }
}

/*=== 16.2 WELCOME SECTION ===*/
.welcomeSection {
  margin-top: 80px;
  margin-bottom: 45px;
}

.welcomeSection h2 {
  color: #222;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
}

.welcomeSection h2 span {
  color: #005fae;
}

/*=== 16.3 ALERT SECTION ===*/
.alertSection .alert {
  min-height: 70px;
  margin-bottom: 20px;
  background-size: 50px 90px;
  background-repeat: no-repeat;
  display: block;
}

@media (max-width: 767px) {
  .alertSection .alert {
    line-height: 20px;
    padding: 15px 65px 0 5px;
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .alertSection .alert {
    padding: 0 0 0 0px;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .alertSection .alert {
    min-height: 90px;
    line-height: 90px;
    padding: 0 0 0 0px;
    font-size: 16px;
  }
}

.alertSection .alert i {
  width: 50px;
  height: auto;
  line-height: 90px;
  display: none;
  text-align: center;
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 20px;
}

@media (min-width: 768px) {
  .alertSection .alert i {
    display: inline-block;
  }
}

.alertSection .alert .fa-check {
  background-color: #60a662;
}

.alertSection .alert .fa-info {
  background-color: #e25a5a;
}

.alertSection .alert-dismissable .close, .alertSection .alert-dismissible .close {
  position: absolute;
  top: -10px;
  right: 20px;
  height: 90px;
  padding-right: 10px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

@media (max-width: 767px) {
  .alertSection .alert-dismissable .close, .alertSection .alert-dismissible .close {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .alertSection .alert-dismissable .close, .alertSection .alert-dismissible .close {
    padding-right: 25px;
    top: 0;
    font-size: 14px;
    padding-right: 10px;
  }
}

.alertSection .alert-dismissable .close:hover, .alertSection .alert-dismissable .close:active, .alertSection .alert-dismissable .close:focus, .alertSection .alert-dismissible .close:hover, .alertSection .alert-dismissible .close:active, .alertSection .alert-dismissible .close:focus {
  color: inherit;
}

/*=== 16.4 DASHBOARD COLOR ===*/
.bg-blue-c {
  background-color: #1ab3e8;
}

.bg-red-c {
  background-color: #e25a5a;
}

.bg-green-c {
  background-color: #1cd066;
}

.bg-orange-c {
  background-color: #fa9335;
}

.bg-ash {
  background-color: #f5f5f5;
}

.bg-blue-b {
  background-color: #18a8da;
}

.bg-red-b {
  background-color: #d55555;
}

.bg-green-b {
  background-color: #1ac460;
}

.bg-orange-b {
  background-color: #eb8a32;
}

/*=== 16.5 DASHBOARD BLOCK SECTION ===*/
.blockSection {
  margin: 30px 0;
}

.blockSection .content-block {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 15px;
  color: #fff;
}

.blockSection .content-block .media {
  padding: 40px 20px;
}

.blockSection .content-block h4 {
  color: #fff;
  font-size: 26px;
}

.blockSection .content-block p {
  color: #fff;
}

.blockSection .content-block .icon {
  display: block;
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.blockSection .content-block .btn-content {
  display: block;
  text-align: left;
  padding: 13px 20px;
  color: #fff;
  text-transform: capitalize;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.blockSection .content-block .btn-content i {
  float: right;
}

/*=== 16.6 DASHBOARD RECENT ACTIVITY SECTION ===*/
.recentActivitySection {
  margin-bottom: 50px;
}

.recentActivitySection .recentActivityContent {
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .recentActivitySection .recentActivityContent {
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .recentActivitySection .recentActivityContent {
    padding: 30px;
  }
}

.recentActivitySection .dashboardPageTitle h2 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 15px;
}

.recentActivitySection .listingsTable {
  margin-bottom: 0px;
}

.recentActivitySection .listingsTable .rowItem {
  background-color: #fff;
}

.recentActivitySection .listingsTable .rowItem h4 {
  margin-bottom: 7px;
  color: #005fae;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem h4 {
    font-size: 16px;
  }
}

.recentActivitySection .listingsTable .rowItem h4 a {
  color: #222;
}

.recentActivitySection .listingsTable .rowItem p {
  text-transform: uppercase;
  font-size: 9px;
  line-height: 9px;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem p {
    font-size: 14px;
    line-height: 21px;
  }
}

.recentActivitySection .listingsTable .rowItem .dateTd {
  width: auto;
}

@media (min-width: 992px) {
  .recentActivitySection .listingsTable .rowItem .dateTd {
    width: 10%;
  }
}

.recentActivitySection .listingsTable .rowItem .dateTd .date {
  display: none;
  color: #fff;
  background-color: #005fae;
  width: 55px;
  height: 58px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin: auto;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .dateTd .date {
    display: block;
  }
}

.recentActivitySection .listingsTable .rowItem .dateTd .date span {
  display: block;
  font-size: 14px;
}

.recentActivitySection .listingsTable .rowItem .iconTd {
  width: auto;
}

@media (min-width: 992px) {
  .recentActivitySection .listingsTable .rowItem .iconTd {
    width: 5%;
  }
}

.recentActivitySection .listingsTable .rowItem .iconTd .icon {
  display: block;
  width: 45px;
  height: 45px;
  font-size: 20px;
  line-height: 40px;
  border: 1px solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  margin: 0 7px;
}

.recentActivitySection .listingsTable .rowItem .iconTd .icon.br-orange {
  border-color: #005fae;
  color: #005fae;
}

.recentActivitySection .listingsTable .rowItem .iconTd .icon.br-blue {
  border-color: #1ab3e8;
  color: #1ab3e8;
}

.recentActivitySection .listingsTable .rowItem .iconTd .icon.br-green {
  border-color: #1cd066;
  color: #1cd066;
}

.recentActivitySection .listingsTable .rowItem .iconTd .icon.br-red {
  border-color: #e25a5a;
  color: #e25a5a;
}

.recentActivitySection .listingsTable .rowItem .packageTd {
  width: auto;
}

@media (min-width: 992px) {
  .recentActivitySection .listingsTable .rowItem .packageTd {
    width: 35%;
    margin-left: 15px;
  }
}

.recentActivitySection .listingsTable .rowItem .packageTd h4 {
  margin-top: 15px;
}

.recentActivitySection .listingsTable .rowItem .priceTd {
  width: auto;
  position: relative;
  padding-right: 5px;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .priceTd {
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .recentActivitySection .listingsTable .rowItem .priceTd {
    width: 30%;
  }
}

.recentActivitySection .listingsTable .rowItem .priceTd h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 12px;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .priceTd h4 {
    font-size: 22px;
    font-weight: 600;
  }
}

.recentActivitySection .listingsTable .rowItem .priceTd p {
  font-size: 8px;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .priceTd p {
    font-size: 12px;
  }
}

.recentActivitySection .listingsTable .rowItem .priceTd:after {
  position: absolute;
  width: 1px;
  height: 50px;
  background-color: #e5e5e5;
  content: '';
  right: 0;
  top: 9%;
  display: none;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .priceTd:after {
    top: 12%;
    height: 70px;
    display: block;
  }
}

.recentActivitySection .listingsTable .rowItem .bookingTd {
  width: auto;
  padding-left: 5px;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .bookingTd {
    width: 20%;
    padding-left: 15px;
  }
}

.recentActivitySection .listingsTable .rowItem .bookingTd .list-inline {
  display: none;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .bookingTd .list-inline {
    display: block;
  }
}

.recentActivitySection .listingsTable .rowItem .bookingTd h4 {
  font-size: 9px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .bookingTd h4 {
    font-size: 11px;
  }
}

.recentActivitySection .listingsTable .rowItem .bookingTd p {
  margin-bottom: 3px;
  font-size: 9px;
}

@media (min-width: 768px) {
  .recentActivitySection .listingsTable .rowItem .bookingTd p {
    font-size: 11px;
  }
}

.recentActivitySection .listingsTable .rowItem:hover {
  background-color: #fff9f4;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
  padding: 10px 0;
}

/*=== 16.7 DASHBOARD RECENT OFFER SECTION ===*/
.recentOfferSection {
  margin-top: 30px;
  margin-bottom: 80px;
  /* Recent Offer Contetnt */
  /* Need Help Aria */
}

.recentOfferSection .recentOfferContent {
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  /* Benifit Aria*/
}

@media (min-width: 768px) {
  .recentOfferSection .recentOfferContent {
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .recentOfferSection .recentOfferContent {
    padding: 30px;
  }
}

.recentOfferSection .recentOfferContent .benefitsAria h4 {
  font-size: 15px;
  font-weight: 400;
  color: #222222;
  padding-top: 15px;
}

@media (min-width: 768px) {
  .recentOfferSection .recentOfferContent .benefitsAria h4 {
    padding-top: 0px;
  }
}

.recentOfferSection .recentOfferContent .benefitsAria .benifitsContent {
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #fff;
}

@media (min-width: 768px) {
  .recentOfferSection .recentOfferContent .benefitsAria .benifitsContent {
    padding: 15px;
  }
}

@media (min-width: 992px) {
  .recentOfferSection .recentOfferContent .benefitsAria .benifitsContent {
    padding: 30px;
  }
}

.recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li {
  margin-bottom: 9px;
}

.recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a {
  color: #5f5f5f;
}

@media (min-width: 768px) {
  .recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a {
    font-size: 14px;
  }
}

.recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a i {
  margin-right: 11px;
  color: #005fae;
}

.recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a:hover, .recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a:active, .recentOfferSection .recentOfferContent .benefitsAria .benifitsContent ul li a:focus {
  color: #005fae;
}

.recentOfferSection .recentOfferAria h4 {
  font-size: 15px;
  font-weight: 400;
  color: #222222;
  padding-top: 15px;
}

@media (min-width: 768px) {
  .recentOfferSection .recentOfferAria h4 {
    padding-top: 0px;
  }
}

.recentOfferSection .recentOfferAria .offerContent {
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #fff;
}

@media (min-width: 768px) {
  .recentOfferSection .recentOfferAria .offerContent {
    padding: 15px;
  }
}

@media (min-width: 992px) {
  .recentOfferSection .recentOfferAria .offerContent {
    padding: 30px;
  }
}

.recentOfferSection .recentOfferAria .offerContent .singleContent {
  position: relative;
}

.recentOfferSection .recentOfferAria .offerContent .singleContent .media .media-left {
  margin-right: 5px;
}

.recentOfferSection .recentOfferAria .offerContent .singleContent .media .media-left img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.recentOfferSection .recentOfferAria .offerContent .singleContent .media .media-body h4 {
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .recentOfferSection .recentOfferAria .offerContent .singleContent .media .media-body h4 {
    margin-top: 10px;
  }
}

.recentOfferSection .recentOfferAria .offerContent .singleContent .media .media-body p {
  font-size: 12px;
}

.recentOfferSection .recentOfferAria .offerContent .singleContent .media .media-body p span {
  color: #005fae;
  font-size: 14px;
}

.recentOfferSection .needhelpAria h4 {
  font-size: 15px;
  font-weight: 400;
  color: #222222;
  padding-top: 15px;
}

@media (min-width: 768px) {
  .recentOfferSection .needhelpAria h4 {
    padding-top: 0px;
  }
}

.recentOfferSection .needhelpAria .needHelpContent {
  background-color: #262a2e;
  padding: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.recentOfferSection .needhelpAria .needHelpContent p {
  color: #fff;
  line-height: 24px;
}

.recentOfferSection .needhelpAria .needHelpContent .btn-default {
  background-color: #005fae !important;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border: none;
  padding: 13px 27px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .recentOfferSection .needhelpAria .needHelpContent .btn-default {
    padding: 13px 12px;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .recentOfferSection .needhelpAria .needHelpContent .btn-default {
    padding: 13px 32px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .recentOfferSection .needhelpAria .needHelpContent .btn-default {
    padding: 13px 60px;
  }
}

.recentOfferSection .needhelpAria .needHelpContent .btn-default i {
  color: #fff;
  margin-right: 10px;
}

/* === 16.8 BOOKING SECTION === */
.bookingTypeSection {
  margin-top: 80px;
  margin-bottom: 30px;
}

.bookingTypeSection .bookingType {
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .bookingTypeSection .bookingType {
    padding: 20px;
  }
}

.bookingTypeSection .bookingType .bookingList li {
  display: block;
  padding: 10px;
}

@media (min-width: 768px) {
  .bookingTypeSection .bookingType .bookingList li {
    display: inline-block;
    padding: 0 18px;
  }
}

.bookingTypeSection .bookingType .bookingList li a {
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  text-transform: uppercase;
}

.bookingTypeSection .bookingType .bookingList li a.active, .bookingTypeSection .bookingType .bookingList li a:hover, .bookingTypeSection .bookingType .bookingList li a:active {
  color: #005fae;
}

/* === 16.9 PROFILE SECTION === */
.profileSection {
  margin-top: 80px;
  margin-bottom: 80px;
}

.profileSection .profileImg img {
  border-radius: 5px;
}

.profileSection .profileContent .editIconRight {
  float: right;
  display: none;
}

.profileSection .profileContent .editIconRight i {
  color: #005fae;
}

.profileSection .profileContent .profileTitle {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .profileSection .profileContent .profileTitle {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.profileSection .profileContent .profileTitle h2 {
  font-size: 26px;
  font-weight: 700;
  color: #343434;
}

.profileSection .profileContent .profileTitle span {
  font-size: 14px;
}

.profileSection .profileContent p {
  font-size: 12px;
}

@media (min-width: 768px) {
  .profileSection .profileContent p {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .profileSection .profileContent p {
    font-size: 14px;
    line-height: 26px;
  }
}

.profileSection .profileContent .profileInfo strong {
  color: #343434;
  margin-right: 5px;
}

.profileSection .profileContent .profileSocialIcon a {
  margin-right: 7px;
}

.profileSection .profileContent .profileSocialIcon a i {
  width: 37px;
  height: 37px;
  line-height: 37px;
  border-radius: 50%;
  text-align: center;
  color: #005fae;
  border: 1px solid #e7e7e7;
}

.profileSection .profileContent .profileSocialIcon a:hover i {
  border: 1px solid #005fae;
  background-color: #005fae;
  color: #fff;
}

.profileSection:hover .editIconRight {
  display: block;
}

/* === 16.10 ABOUT YOU SECTION === */
.aboutYouSection {
  margin-bottom: 70px;
}

.aboutYouSection h2 {
  font-size: 20px;
  color: #343434;
  font-weight: 700;
  margin-bottom: 20px;
}

.aboutYouSection p {
  font-size: 12px;
}

@media (min-width: 768px) {
  .aboutYouSection p {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .aboutYouSection p {
    font-size: 14px;
    line-height: 26px;
  }
}

/* === 16.11 ACCOUNT SETTING SECTION === */
.settingSection {
  margin-top: 80px;
  margin-bottom: 80px;
}

.settingSection .settingContent {
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .settingSection .settingContent {
    padding: 15px;
  }
}

@media (min-width: 992px) {
  .settingSection .settingContent {
    padding: 30px;
  }
}

.settingSection .settingContent h4 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.settingSection .settingContent hr {
  background-color: #e5e5e5;
  height: 1px;
}

.settingSection .settingContent p {
  color: #005fae;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.settingSection .settingContent .changePassword {
  margin-top: 15px;
  margin-bottom: 30px;
}

.settingSection .settingContent .changeEmail {
  margin-top: 30px;
  margin-bottom: 30px;
}

.settingSection .settingContent .form-control {
  padding: 20px;
  height: 50px;
  margin-bottom: 20px;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

@media (min-width: 768px) {
  .settingSection .settingContent .form-control {
    width: 50%;
  }
}

.settingSection .settingContent .changeSetting {
  margin-top: 30px;
  margin-bottom: 30px;
}

.settingSection .settingContent .changeSetting .form-check input {
  display: none;
}

.settingSection .settingContent .changeSetting .form-check .form-check-input {
  margin-left: 4px;
  margin-top: 6px;
  padding: 5px;
  opacity: 0;
}

.settingSection .settingContent .changeSetting .form-check .form-check-label {
  padding-left: 0px;
  margin-bottom: 10px;
  font-size: 13px;
  cursor: default;
  color: #5f5f5f;
  font-weight: 400;
}

.settingSection .settingContent .changeSetting .form-check .checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: #ffffff;
  border: 1px solid #dedede;
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  padding: 0;
  margin-right: 10px;
  text-align: center;
  line-height: 13px;
}

.settingSection .settingContent .changeSetting .form-check .checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #ffffff;
  background: #005fae;
  border: 0px solid #005fae;
  font-size: 10px;
  font-weight: normal;
}

.settingSection .settingContent .changeSetting .buttonTransparent, .settingSection .settingContent .changeSetting .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .settingSection .settingContent .changeSetting a, .settingSection .settingContent .changeSetting .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .settingSection .settingContent .changeSetting a, .settingSection .settingContent .changeSetting .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .settingSection .settingContent .changeSetting a {
  margin-top: 18px;
}

.settingSection .settingContent .buttonTransparent, .settingSection .settingContent .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .settingSection .settingContent a, .settingSection .settingContent .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .settingSection .settingContent a, .settingSection .settingContent .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .settingSection .settingContent a {
  width: 253px;
  height: 45px;
  line-height: 45px;
  background-color: #005fae;
  color: #fff;
  border: none;
  margin-top: 10px;
}

/* === 16.12 SIGNUP & LOGIN PAGE === */
@media (min-width: 768px) {
  .signupLoging .modal-dialog {
    width: 400px;
  }
}

.signupLoging .modal-header {
  border-top: 6px solid #005fae;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 0;
  padding: 10px 20px;
}

.signupLoging .modal-header .close {
  font-size: 30px;
  color: #222;
  opacity: 1;
  font-weight: 400;
}

.signupLoging .modal-header .modal-title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 3px;
}

.signupLoging .modal-body {
  padding: 10px 20px;
}

.signupLoging .modal-body .form-group .form-control {
  padding: 22px 18px;
  border-color: #e5e5e5;
  box-shadow: none;
}

.signupLoging .modal-body .checkbox {
  margin-bottom: 12px;
}

.signupLoging .modal-body .checkbox .forgotPass {
  float: right;
}

.signupLoging .modal-body .btn-default {
  width: 100%;
  background-color: #005fae;
  border: none;
  color: #fff;
  margin-top: 3px;
}

.signupLoging .modal-body .btn-default.btnSocial {
  background-color: #6684c4;
}

.signupLoging .modal-body .btn-default.btnSocial:hover {
  opacity: 1;
}

.signupLoging .modal-body .or {
  font-size: 15px;
  color: #222222;
  text-align: center;
  text-transform: uppercase;
  margin: 15px;
}

.signupLoging .modal-footer {
  padding: 10px 20px;
  border-top: 0;
  text-align: center;
}

.signupLoging .modal-footer .dontHaveAccount p a {
  color: #005fae;
}
