.thumbnail .discountInfo .duration {
    top: 15px;
}

.thumbnail.deals .discountInfo .duration li {
    color: #ebf3fb;
}

@media (min-width: 1200px) {
    .selectbox-sidebar .sbHolder {
        width: 250px;
    }
}

@media (min-width: 992px) {
    .selectbox-sidebar .sbHolder {
        width: 177px;
    }
}

@media (min-width: 768px) {
    .selectbox-sidebar .sbHolder {
        width: 217px;
    }
}

.photoGallerySection .photoGallery .slick-carousel .singlItem .singleImg img {
    width: 870px;
}


.thumbnail.deals .caption .detailsInfo h5 span {
    text-transform: none;
}

.static-content-wrapper {
    position: relative;
    display: block;
}

.static-content-info {
    position: absolute;
    width: 220px;
    top: 0;
    right: 0;
    background-color: #2F4050;
    padding: 10px;
    border-left: 4px solid #22a2e4;
    border-radius: 0px;
    display: none;
    color: #a7b1c2;
    transition: 0.5s;
}

.static-contect-info a {
    color: #a7b1c2;
    text-decoration: underline;
}

.static-contect-info a:hover {
    text-decoration: none;
}

.static-content-wrapper:hover .static-content-info {
    display: block;
    transition: 0.5s;
}

#text-container {
    transition: all 1s ease;
}

.bike-rental__terms-conditions {
    background-color: #e8e8e8;
    padding: 15px;
    margin-bottom: 15px;
    text-align: center;
    border-radius: 4px;
}

.bike-rental__terms-conditions h2 {
    font-size: 16px;
}

.singleCartSidebar .form-horizontal .form-group .time-label {
    text-align: left;
    padding-left: 20px;
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

.grecaptcha-badge {
    display: none;
}

.count-input .error {
    text-align: left;
    float: left;
    width: 100%;
    font-size: 12px;
    padding-top: 10px;
}

.table-blue-border {
    border: 2px solid #005fae;
    border-radius: 4px;
    border-collapse: separate;
}

.take-a-child {
    background: #0F5FAE url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABQCAIAAADN6l8RAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABN5JREFUeJztm2dP20AYx/umBoWAGImAgigpVJQiQKGF0Krrk+AsOyRBEWWW1fV1Eq+kUOj+aP0XSxUC350d+85WhPTIgsTjfr5n3+WOJJudIXdCH8EtyS1J9OWWJHoSJklX1uwvWEnVmihbM7XWwnbr6buvz46/v/zw8/Wn328+/8ERf+MTfI5vcQ7OxPm4CteGTxLLWYNF88GGld49zRxdYKwYtHvB+bgK1+IOuA/uJpoEb3FIsaZrreWDc09DpwvuhnviziJIevLWsGou7p4+P/kRIMNVwZ35kmAeRkpWeu/M1nuuwpEkWWpiHgQwcCSBFaaqFj9dEkSSUJtwmiIZgieBVUxUmnD/4jGCJOnOmjObrVcff4WCERhJb6E5t9USZty8SDAbCzunITIEQxLPW1HA8EuC2YBShc7gl6Tr0sTDtY1gSOBwQ/RUgZEg/PGOG5nD88c182FVx3HZRahthwTJCNcovnp0MVFqxNYadjmFY0zWxtQ62AImSVVNfhgopJKFhuNz8fmz42+BkSDDfX7CS6/gPybLGuXp42qd5GO8kWCikajzmxDoz0DBoCm2rJGmxRsJyiaubndxp3Wz1XBNcI5fEhSxqP74YUAWtlvMYcy9bfolQS3OOw6md790s+Zkac9Zvd2S8LYQW2ADg0WanfTm9BfvnUtRtyRDisXPZV2VRzWL8janKjrpQrck0zVBmSIUeGLduGn3/wrSdYOSH7kiQVAPtt1GFwTH2c3mYMHoyf17NI5wzZgrkl55IBkscgzqFJ4n+2eLO19wdNNxdUXyYMMST+JV2CRQ0DR/r0USuJnlg69uygc2SX/ByhxdCAaA3SNKJvL1LlmX1vRuWRsvNVapw2CTJFXL68KAT0FUmSwbsez1wDJc1ChGzya5X3HODjgJwnx/rk7S85kNo32SGVGRBDOPwBfP0wZzT2m0n9VDX8Vo1FhJZw5mVGmQrJ9NAtfBG+Pp/tkAQaOuxwM/2QrvzsPq0beBPHs2pMv22vwW0WjZJFxbQbj5qKL9H2iiqPflibkwEmFgt0/CtSaBlY8oejxnjKo6vBac7HSVSDJUaFBuxSbhqlpvLqP41TedqhA1LUU2kkiQXFM2hD/SSEgVvFsSkS3TzOFFP8H6oYH41heJyNU2Sm9lWNHpSVMk4sl/mSKbOzIx+rVRifG2JAh91C7ZYA4jQnkXXHBP1tlIYlmd0hF2SyIsF0Y8wbt3HANyGeblEapPpqrEMUyWtQBIxNSMeFkI86QxUNItDyRi6nhUtqSMC5+vuOhRRaW3AiMhqndRC6xLJKDf5SeSeCDhvbAISRLSrbtr7EjigUTi3BdGrOjLEcx9TXfZWY9Erx7pVizn/Nx4VrP32s5vtZB6wfpJ2VMk1k+mq0T/i1h5v2wmFcNeIcK/T/b9rQRJ3Na0UDWMFJ3TrZtvM1XWA1j75bTOiHQLKsR8OuZkcp3mjsNf+4Xew0HRnxuTtdkaQyPCX49f2julPzRR1NK7bM8Z/h4J5CndsrPFw77HlDozn2+TRAp634q9w+Nm0TuQ1z0Vee3tJTKDDfmY5HG1DmPAJEB6ZG1cbax4rLojtL8rc3g+t2lC6Cs+AZNIHbPnTuqkfZBSx+xNtSWetwTsZxFBYs9MFGBu99U7zUwn/NbBlnB/f/IXyCnOr9Ue3lMAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    color: #fff;
    background-position: bottom right;
    border-radius: 4px;
    padding: 10px 30px;
    margin-top: 30px;
    clear: both;
}

.take-a-child h2 {
    font-size: 30px;
    color: #fff;
    font-family: "Montserrat";
    font-weight: 500;
    padding-top: 20px;
    margin-top: 0px !important;
}

.take-a-child p {
    color: #fff;
    font-size: 17px;
    line-height: 2.75rem;
    margin-top: 16px;
    max-width: 94%;
    padding-bottom: 20px;
}

.footer-link-rate {
    float: left;
    max-width: 250px;
    margin-bottom: 25px;
    width: 100%;
}